import React from "react"
import { Col, Row } from "react-bootstrap"
import _ from "lodash"
import { ACCESS_PRIVILEGES } from "../constants"
import { EditColumn, SourceColumn, ViewColumn } from "./PermissionColumns"

export class PermissionManagement extends React.Component {
    state = { filter: "" }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.disableView && this.props.disableView) {
            this.substitutePrivileges(ACCESS_PRIVILEGES.READ, undefined)()
        }
    }

    itemCanSubstitute = (item, from, to) => {
        const canChangeFrom = item.privilege === from
        const canChangeTo = !(item.disabled || {})[to]
        const needsConfirmation =
            this.props.needsConfirmation &&
            this.props.needsConfirmation(item, from, to)
        return (
            canChangeFrom &&
            canChangeTo &&
            (!needsConfirmation || confirm(needsConfirmation))
            // TODO custom popup with site-appropriate styling, custom button names probably, and make it default to cancelling instead of removing the permission.
            // TODO replace the confirmation popup with a way to undo the change. That'll be kinda complicated though...
        )
    }

    substitutePrivileges = (from, to) => () => {
        const changedIds = []

        const afterSubstitution = _.map(this.props.input.value, item => {
            if (this.itemCanSubstitute(item, from, to)) {
                changedIds.push(item.id)
                return {
                    ...item,
                    privilege: to
                }
            }
            return item
        })

        if (_.isEmpty(changedIds)) {
            // nothing changed
            return
        }

        this.props.input.onChange(afterSubstitution)
        if (this.props.onSet) {
            this.props.onSet(to, from, changedIds)
        }
    }

    setPrivilege = privilege => id => {
        let changedItem = null

        const afterSubstitution = _.map(this.props.input.value, item => {
            if (
                item.id === id &&
                this.itemCanSubstitute(item, item.privilege, privilege)
            ) {
                changedItem = item
                return {
                    ...item,
                    privilege
                }
            }
            return item
        })

        if (_.isNil(changedItem)) {
            // nothing changed
            return
        }

        this.props.input.onChange(afterSubstitution)
        if (this.props.onSet) {
            this.props.onSet(privilege, changedItem.privilege, [id])
        }
    }

    setFilter = filter => {
        this.setState({ filter })
    }

    render() {
        const { disableView, loading, ownerId, input } = this.props

        const savingTagged = _.map(input.value, val => ({
            ...val,
            saving: _.includes(this.props.saving, val.id)
        }))
        const sorted = _.sortBy(savingTagged, item => item.label)
        const selected = _.groupBy(sorted, item => item.privilege)

        const columnProps = {
            loading,
            selected,
            disableView,
            ownerId,
            filter: this.state.filter,
            set: this.setPrivilege,
            setAll: this.substitutePrivileges
        }

        if (typeof selected !== "object") {
            return <div />
        }

        return (
            <Row className="permission_management">
                <Col md={disableView ? 6 : 4}>
                    <SourceColumn
                        {...columnProps}
                        onFilterChange={this.setFilter}
                    />
                </Col>
                {!disableView && (
                    <Col md={4}>
                        <ViewColumn {...columnProps} />
                    </Col>
                )}
                <Col md={disableView ? 6 : 4}>
                    <EditColumn {...columnProps} />
                </Col>
            </Row>
        )
    }
}
