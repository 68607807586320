import _ from "lodash"
import { getCompositeSubmeasureInputName } from "../../../../helpers"

const SUBMEASURE_IDS = {
    systolic: /HFSS\+ABP\+01\+\d+\/comp\/HFSS\+ABP\+02\+\d+/, // abbrevs are likely to change each year
    diastolic: /HFSS\+ABP\+01\+\d+\/comp\/HFSS\+ABP\+03\+\d+/
}

const allSubMeasuresEntered = submeasureIds => (value, allValues) => {
    const numEmpty = submeasureIds.filter(id => _.isNil(allValues[id])).length
    const invalid = 0 < numEmpty && numEmpty < submeasureIds.length // don't invalidate if they're all entered or all empty
    return invalid && "must have all submeasures filled in"
}

const getValueForKeyMatch = (obj, pattern) =>
    Number(_.find(obj, (value, key) => key.match(pattern)))

const bloodPressure = ({ subMeasures, abbrevName }) => {
    const subMeasureNames = subMeasures.map(
        getCompositeSubmeasureInputName(abbrevName)
    )

    return [
        allSubMeasuresEntered(subMeasureNames),
        (values, allValues) => {
            const subMeasures = _.pick(allValues, subMeasureNames)

            const invalid =
                getValueForKeyMatch(subMeasures, SUBMEASURE_IDS.systolic) <=
                getValueForKeyMatch(subMeasures, SUBMEASURE_IDS.diastolic)
            return invalid && "systolic must be greater than diastolic"
        }
    ]
}

export default { "HFSS\\+ABP\\+01\\+\\d+": bloodPressure }
