import React from "react"
import { ButtonGroup } from "../../pickers"
import * as C from "../constants"
import { PrintField } from "./PrintField"
import { CONDITIONS_OPTIONS } from "../constants"
import { ToggleField } from "./PrintLinkages"
import { Field } from "redux-form"

const ScopePicker = ({ input: { onChange, value } }) => (
    <ButtonGroup
        value={value}
        onClick={onChange}
        options={CONDITIONS_OPTIONS}
    />
)

export const PrintConditions = () => (
    <PrintField name={C.PRINT_FIELDS.CONDITIONS} className="print-conditions">
        <Field name={C.PRINT_FIELDS.COND_ABBREVIATED} component={ToggleField} />
        <PrintField
            name={C.PRINT_FIELDS.ONLY_UNDIAGNOSED}
            component={ScopePicker}
        />
    </PrintField>
)
