import React from "react"
import { Card, Modal } from "react-bootstrap"
import FormModal from "../../core/FormModal"
import * as C from "../constants"
import UserTagsTable from "./UserTagsTable"
import PatientTagsTable from "./PatientTagsTable"
import TagUserTable from "./TagUserTable"

const PlaceholderCard = ({ children }) => (
    <Card className="card-placeholder">{children}</Card>
)

const PatientTags = ({ tagId }) =>
    tagId === C.NEW_TAG_ID ? (
        <PlaceholderCard>
            Save your tag to start adding patients to it.
        </PlaceholderCard>
    ) : !tagId ? (
        <PlaceholderCard>
            Select a tag to view the patients in it.
        </PlaceholderCard>
    ) : (
        <PatientTagsTable />
    )

const TagUsers = ({ currentTag, userCanEditTag }) =>
    currentTag[C.TABLE_KEYS.TAG_ID] === C.NEW_TAG_ID ? (
        <PlaceholderCard>
            Save your tag to start granting users access to it.
        </PlaceholderCard>
    ) : !currentTag[C.TABLE_KEYS.TAG_ID] ? (
        <PlaceholderCard>
            Select a tag to view the users with access to it.
        </PlaceholderCard>
    ) : !currentTag[C.TABLE_KEYS.PUBLIC] ? (
        <PlaceholderCard>
            This tag is private. Only you can access it.
        </PlaceholderCard>
    ) : !userCanEditTag ? (
        <PlaceholderCard>
            You do not have permission to edit this tag.
        </PlaceholderCard>
    ) : (
        <TagUserTable />
    )

export const UserTags = ({
    hideUserTagsModal,
    open,
    currentTag,
    userIsExternal,
    userCanEditTag
}) => (
    <FormModal
        show={open}
        onHide={hideUserTagsModal}
        dialogClassName="user-tags large-modal"
    >
        <Modal.Header closeButton>
            <Modal.Title>Manage Tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <UserTagsTable />
            <PatientTags tagId={currentTag[C.TABLE_KEYS.TAG_ID]} />
            {userIsExternal || <TagUsers {...{ currentTag, userCanEditTag }} />}
        </Modal.Body>
    </FormModal>
)
