import React from "react"
import * as C from "../../../constants"

export const IncentivizedTooltip = ({
    incentiveStatus,
    incentivizedByPayer,
    incentivePrograms,
    suppElig,
    suppIds
}) => (
    <>
        {incentiveStatus?.[0] === C.COMPLIANCE.NOT_COMPLIANT && (
            <div className="compliance-warning">
                No compliant values <br />
                found in HealthFocus!
            </div>
        )}
        {incentiveStatus?.[1] === C.COMPLIANCE.NOT_COMPLIANT && (
            <div className="compliance-warning">
                Not reported as <br />
                compliant by any payers!
            </div>
        )}
        <div>
            <h5>Incentivized by</h5>
            <ul>
                {incentivizedByPayer.map((payer, index) => (
                    <li key={index}>
                        <span
                            className="fa fa-circle"
                            style={{
                                color: payer.color,
                                marginRight: 5
                            }}
                        />
                        {payer.payerName}
                    </li>
                ))}
            </ul>
        </div>
        {!_.isEmpty(incentivePrograms) && (
            <div>
                <h5>Incentive Programs</h5>
                <ul className="bulleted">
                    {incentivePrograms.map(program => (
                        <li key={program}>{program}</li>
                    ))}
                </ul>
            </div>
        )}
        {suppElig && (
            <div>
                <h5>Eligible for file submission?</h5>
                {suppElig ? "Yes" : "No"}
            </div>
        )}
        {suppIds && (
            <div>
                <h5>Supplemental IDs</h5>
                <ul>
                    {suppIds?.map((suppId, i) => (
                        <li key={i}>{suppId}</li>
                    ))}
                </ul>
            </div>
        )}
    </>
)
