import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { encodeFiltersInUrl, matchesSearch } from "../../utils"

const PhysicianLink = ({ getLinkFilters, physician }) => {
    const url = useMemo(
        () =>
            physician.canViewPanel
                ? encodeFiltersInUrl("/patients", getLinkFilters(physician))
                : undefined,
        [physician]
    )
    const children = (
        <>
            {physician.providerName}
            <span className="patient-count">({physician.numPatients})</span>
        </>
    )
    return physician.canViewPanel ? (
        <Link to={url} className="clinic-physician">
            {children}
        </Link>
    ) : (
        <div className="clinic-physician">{children}</div>
    )
}

const PracticeUnit = ({ filter, getLinkFilters, getClinicFilters, unit }) => {
    const clinicUrl = useMemo(
        () => encodeFiltersInUrl("/patients", getClinicFilters(unit)),
        [unit]
    )

    return (
        <li>
            <Link className="clinic-name" to={clinicUrl}>
                <span className="patient-count">({unit.numPatients})</span>
                {unit.practiceName}
            </Link>
            {unit.physicians
                .filter(item => matchesSearch(item.providerName, filter))
                .map(physician => (
                    <PhysicianLink
                        key={`${physician.providerId}-${physician.isSpecialist}`}
                        getLinkFilters={getLinkFilters}
                        physician={physician}
                    />
                ))}
        </li>
    )
}

export default PracticeUnit
