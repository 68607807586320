import {
    DollarsCents,
    DollarsCentsNegative,
    FractionalDollarsCentsCell,
    IndentedCell,
    LongNumber,
    RateCell
} from "../core/CellRenderers"
import * as CC from "../cost/constants"
import { ALIGNMENTS } from "../constants"
import { columnBuilder } from "../table"
import PerformanceChart from "./components/PerformanceChart"
import CostReportLink from "./components/CostReportLink"

const column = columnBuilder(CC.TABLE_TRANSLATION, {
    flexGrow: 1,
    align: ALIGNMENTS.RIGHT
})

const SHARED_COLUMNS = [
    column(CC.TABLE_KEYS.TOTAL_COST, {
        cellRenderer: DollarsCents,
        width: 135
    }),
    column(CC.TABLE_KEYS.TOTAL_ALLOCATION, {
        cellRenderer: DollarsCents,
        width: 135
    }),
    column(CC.TABLE_KEYS.TOTAL_REMAINING, {
        dataGetter: ({ rowData }) =>
            rowData[CC.TABLE_KEYS.TOTAL_ALLOCATION] -
            rowData[CC.TABLE_KEYS.TOTAL_COST],
        cellRenderer: DollarsCentsNegative,
        width: 130
    }),
    column(CC.TABLE_KEYS.PMPM_COST, {
        cellRenderer: FractionalDollarsCentsCell,
        hover: "Cost Per Member Per Month",
        width: 100
    }),
    column(CC.TABLE_KEYS.PMPM_ALLOCATION, {
        cellRenderer: FractionalDollarsCentsCell,
        hover: "Allocation Per Member Per Month",
        width: 140
    }),
    column(CC.TABLE_KEYS.PMPM_NETWORK, {
        cellRenderer: FractionalDollarsCentsCell,
        hover: "Network-wide Cost Per Member Per Month",
        width: 160
    }),
    column(CC.TABLE_KEYS.TARGET, {
        cellRenderer: FractionalDollarsCentsCell,
        hover: "Target Expenditure Per Member Per Month",
        width: 120
    }),
    column(CC.TABLE_KEYS.PATIENT_COUNT, {
        width: 130,
        cellRenderer: LongNumber
    }),
    column(CC.TABLE_KEYS.EPISODE_COUNT, {
        width: 90,
        cellRenderer: LongNumber
    }),
    column(CC.TABLE_KEYS.EPISODES_PER_1000, {
        width: 150,
        cellRenderer: RateCell
    }),
    column(CC.TABLE_KEYS.PERFORMANCE, {
        cellRenderer: PerformanceChart,
        disableSort: true,
        width: 300,
        align: "left"
    })
]
export const NETWORK_TABLE_COLUMNS = [
    column(CC.TABLE_KEYS.DISPLAY_NAME, {
        cellRenderer: IndentedCell,
        width: 230,
        align: "left",
        className: "indent-cell"
    }),
    ...SHARED_COLUMNS
]
export const PRACTICE_TABLE_COLUMNS = [
    column(CC.TABLE_KEYS.PRACTICE_NAME, {
        cellRenderer: CostReportLink,
        width: 150,
        align: "left"
    }),
    ...SHARED_COLUMNS
]
export const PROVIDER_TABLE_COLUMNS = [
    column(CC.TABLE_KEYS.PROVIDER_NAME, {
        cellRenderer: CostReportLink,
        width: 150,
        align: "left"
    }),
    ...SHARED_COLUMNS
]
