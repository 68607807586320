import React from "react"
import classNames from "classnames"
import { Hoverlay } from "../../../../core/Hoverlay"
import {
    BasicIcon,
    getComplianceAttributes,
    PayerIcon
} from "../../../../indicators"
import { IncentivizedTooltip } from "./IncentivizedTooltip"

import hfIcon from "../../../../../assets/icons/favicon-32x32.png"

export const IncentiveMarker = ({
    payersWithLogos,
    incentiveStatus,
    incentivizedByPayer,
    incentivePrograms,
    suppElig,
    suppIds
}) => {
    // see also modules/core/CellRenderers/DualStatusCell

    const hfCompliance = getComplianceAttributes(incentiveStatus?.[0])
    const payerCompliance = getComplianceAttributes(incentiveStatus?.[1])

    return (
        <Hoverlay
            className="incentivized-tooltip"
            tooltip={
                <IncentivizedTooltip
                    incentiveStatus={incentiveStatus}
                    incentivizedByPayer={incentivizedByPayer}
                    incentivePrograms={incentivePrograms}
                    suppElig={suppElig}
                    suppIds={suppIds}
                />
            }
        >
            <span className="dual-icon-group payer-logos">
                <span className="dual-icon">
                    <PayerIcon src={hfIcon} />
                    <BasicIcon
                        className={classNames(
                            "dual-icon-compliance",
                            hfCompliance?.className
                        )}
                    />
                </span>
                {payersWithLogos.map((payer, index) => (
                    <span className="dual-icon" key={index}>
                        <PayerIcon logo={payer.logo} alt={payer.payerName} />
                        <BasicIcon
                            className={classNames(
                                "dual-icon-compliance",
                                payerCompliance?.className
                            )}
                        />
                    </span>
                ))}
            </span>
        </Hoverlay>
    )
}
