import { isoToUsDate, usToIsoDate } from "../dates"
import * as C from "./constants"

export const formatInitialValues = careManager => ({
    ...careManager,
    [C.KEYS.EFF_DT]: isoToUsDate(careManager.effDt),
    [C.KEYS.TERM_DT]: isoToUsDate(careManager.termDt)
})

export const transformPayload = payload => ({
    userId: payload[C.KEYS.USER_ID],
    reasonIds: payload[C.KEYS.REASON_IDS],
    effDt: usToIsoDate(payload[C.KEYS.EFF_DT]),
    termDt: usToIsoDate(payload[C.KEYS.TERM_DT])
})
