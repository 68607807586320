import React from "react"
import { Modal } from "react-bootstrap"

// A modal that contains form elements. Without this, the Bootstrap modal doesn't properly unmount when
//  1. the modal contains redux-form fields,
//  2. you are focused on a field's Select input, and
//  3. you close the modal using a button in the footer
// You end up with an invisible modal blocking everything else on the page.

export const FormModal = props => {
    const reduceMotion = window.matchMedia("(prefers-reduced-motion)").matches

    return <Modal animation={!reduceMotion} {...props} />
}

export default FormModal
