import React from "react"
import { Field } from "redux-form"
import { Col, Row } from "react-bootstrap"
import moment from "moment"
import { LabelledDatePicker, SelectField } from "../../core/CustomFields"
import { required } from "../../core/validators"
import { MONTH_STRING } from "../../dates"
import * as C from "../constants"
import CategoryTree from "./CategoryTree"
import { branchesToLeaves, leavesToBranches } from "../helpers"

const commonFieldProps = {
    component: SelectField,
    placeholder: "All",
    multi: true
}

// TODO extract these validators to another file. Possibly core/validators?
// Could also redo them as a special case of isBeforeEndValidator/isAfterStartValidator

const parseMonth = value => moment(value, MONTH_STRING).startOf("month")

const beforeMonthField = (value, formValues) =>
    parseMonth(value).isSameOrBefore(parseMonth(formValues[C.FILTERS.END_DATE]))
        ? undefined
        : "Must not be after end date"
const afterMonthField = (value, formValues) =>
    parseMonth(value).isSameOrAfter(
        parseMonth(formValues[C.FILTERS.START_DATE])
    )
        ? undefined
        : "Must not be before start date"

export const CostForm = ({
    categoryNodes,
    categoryTree,
    filterOptions: { payers, productClasses, units, providers, regions, tags }
}) => (
    <div className="cost-report-filters">
        <Row>
            <Col sm={6}>
                <Field
                    name={C.FILTERS.START_DATE}
                    component={LabelledDatePicker}
                    dateFormat={MONTH_STRING}
                    required
                    validate={[required, beforeMonthField]}
                    validDates={beforeMonthField}
                    initialViewMode="months"
                />
            </Col>
            <Col sm={6}>
                <Field
                    name={C.FILTERS.END_DATE}
                    component={LabelledDatePicker}
                    dateFormat={MONTH_STRING}
                    required
                    validate={[required, afterMonthField]}
                    validDates={afterMonthField}
                    initialViewMode="months"
                />
            </Col>
            <Col sm={6}>
                <Field
                    name={C.FILTERS.PAYERS}
                    options={payers}
                    {...commonFieldProps}
                />
            </Col>
            <Col sm={6}>
                <Field
                    name={C.FILTERS.PRODUCTS}
                    options={productClasses}
                    {...commonFieldProps}
                />
            </Col>
            <Col sm={6}>
                <Field
                    name={C.FILTERS.PRACTICE_UNITS}
                    options={units}
                    {...commonFieldProps}
                />
                <Field
                    name={C.FILTERS.REGIONS}
                    options={regions}
                    {...commonFieldProps}
                />
            </Col>
            <Col sm={6}>
                <Field
                    name={C.FILTERS.PROVIDERS}
                    options={providers}
                    {...commonFieldProps}
                />
                {/*TODO uncomment this when tags filter is ready on the backend*/}
                {/*<Field
                        name={C.FILTERS.TAGS}
                        options={tags}
                        {...commonFieldProps}
                    />*/}
            </Col>

            <Col sm={12}>
                <Field
                    name={C.FILTERS.CATEGORIES}
                    component={CategoryTree}
                    normalize={value => leavesToBranches(categoryTree, value)}
                    format={value => branchesToLeaves(categoryTree, value)}
                    nodes={categoryNodes}
                />
            </Col>
        </Row>
    </div>
)
