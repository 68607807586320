import React, { useEffect, useMemo, useState } from "react"
import { Card, Tab, Tabs } from "react-bootstrap"
import LEHOC from "../../core/LoadingErrorHOC"
import SearchBar from "../../core/SearchBar"
import Select from "../../core/Select"
import * as PC from "../../patient_list/constants"
import { MODULE_IDS } from "../../constants"
import { filterPracticeUnits } from "../selectors"
import PracticeUnit from "./PracticeUnit"

const getPhysicianFilter = physician => ({
    [PC.FILTERS.PROVIDERS]: [physician.providerId],
    [PC.FILTERS.TYPE]: PC.FILTER_TYPES.PATIENT_SUMMARY
})
const getSpecialistFilter = physician => ({
    [PC.FILTERS.SPECIALISTS]: [physician.providerId],
    [PC.FILTERS.TYPE]: PC.FILTER_TYPES.PATIENT_SUMMARY
})
const getPhysicianClinicFilter = clinic => ({
    [PC.FILTERS.PRACTICE_UNITS]: [clinic.practiceUnitId],
    [PC.FILTERS.TYPE]: PC.FILTER_TYPES.PATIENT_SUMMARY
})
const getSpecialistClinicFilter = clinic => ({
    [PC.FILTERS.SPECIALISTS]: clinic.physicians.map(
        physician => physician.providerId
    ),
    [PC.FILTERS.TYPE]: PC.FILTER_TYPES.PATIENT_SUMMARY
})

const TABS = [
    {
        isSpecialist: false,
        field: "pcpUnits",
        title: "PCP",
        header: "Primary Care",
        module: MODULE_IDS.PROVIDER_DIRECTORY,
        getLinkFilters: getPhysicianFilter,
        getClinicFilters: getPhysicianClinicFilter
    },
    {
        isSpecialist: true,
        field: "specialistUnits",
        title: "SCP",
        header: "Specialists",
        module: MODULE_IDS.SPECIALIST_DIRECTORY,
        getLinkFilters: getSpecialistFilter,
        getClinicFilters: getSpecialistClinicFilter
    }
]

const DirectoryCard = ({
    error,
    list,
    loading,
    options,
    filter,
    header,
    getLinkFilters,
    getClinicFilters,
    setFilter,
    regionIds,
    setRegionIds
}) => {
    const units = useMemo(() => filterPracticeUnits(list, filter, regionIds), [
        list,
        filter,
        regionIds
    ])

    return (
        <Card id="directory" className="directory">
            <Card.Header>
                <Card.Title as="h2">
                    Practice Units &amp; Practitioners &ndash; {header}
                </Card.Title>
                <div className="form-group directory-filters">
                    <SearchBar
                        className="directory-filter-search"
                        placeholder="Filter"
                        value={filter}
                        onChange={setFilter}
                        cosmeticButton
                    />
                    <Select
                        options={options}
                        value={regionIds}
                        onChange={setRegionIds}
                        className="directory-filter-region"
                        placeholder="Select practice tags..."
                        multi
                    />
                </div>
            </Card.Header>
            <Card.Body id="physician-list">
                <LEHOC loading={loading} error={error} list={units}>
                    <ul className="physician-list dataTable table table-striped table-hover table-wrapper">
                        {units.map(unit => (
                            <PracticeUnit
                                key={unit.practiceUnitId}
                                getLinkFilters={getLinkFilters}
                                getClinicFilters={getClinicFilters}
                                unit={unit}
                                filter={filter}
                            />
                        ))}
                    </ul>
                </LEHOC>
            </Card.Body>
        </Card>
    )
}

export const Directory = ({
    data,
    error,
    loading,
    regionOptions,
    isModuleEnabled
}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [filter, setFilter] = useState("")
    const [regionIds, setRegionIds] = useState([])
    const [activeKey, setActiveKey] = useState(0)

    const tabs = TABS.filter(({ module }) => isModuleEnabled(module))

    useEffect(() => {
        const firstNonEmptyIndex = tabs.findIndex(
            tab => data[tab.field]?.length > 0
        )
        const activeKey = _.clamp(firstNonEmptyIndex, 0, tabs.length - 1)
        setActiveKey(activeKey)
    }, [data])

    if (tabs.length === 1) {
        const { field, getLinkFilters, getClinicFilters, header } = tabs[0]
        return (
            <DirectoryCard
                options={regionOptions}
                loading={loading}
                error={error}
                list={data[field]}
                filter={filter}
                setFilter={setFilter}
                regionIds={regionIds}
                setRegionIds={setRegionIds}
                getLinkFilters={getLinkFilters}
                getClinicFilters={getClinicFilters}
                header={header}
            />
        )
    }

    return (
        <Tabs
            defaultActiveKey={0}
            activeKey={activeKey}
            onSelect={setActiveKey}
            id="physician-list-tabs"
            justify
        >
            {tabs.map(
                (
                    { field, title, header, getLinkFilters, getClinicFilters },
                    index
                ) => (
                    <Tab key={index} eventKey={index} title={title}>
                        <DirectoryCard
                            options={regionOptions}
                            loading={loading}
                            error={error}
                            list={data[field]}
                            filter={filter}
                            setFilter={setFilter}
                            regionIds={regionIds}
                            setRegionIds={setRegionIds}
                            getLinkFilters={getLinkFilters}
                            getClinicFilters={getClinicFilters}
                            header={header}
                        />
                    </Tab>
                )
            )}
        </Tabs>
    )
}

export default Directory
