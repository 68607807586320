export const NAME = "PATIENT_COVERAGE"

export const TABLE_KEYS = {
    SOURCE_ID: "sourceId",
    SOURCE_NAME: "source",
    PAYER_ID: "payerId",
    PAYER_NAME: "name",
    PROVIDER_NAME: "provider",
    PRACTICE_NAME: "practice",
    PRODUCT_NAME: "product",
    START_DATE: "effDate",
    END_DATE: "endDate",
    TYPE: "type",
    PRIORITY: "priority",
    STATUS: "status",
    CM_INCENTIVE: "productCmElig",
    IDENTIFIERS: "identifiers",
    PRIMARY_IDENTIFIER: "primaryIdentifier",
    TERM_45: "term45",
    MED_COV: "medCov",
    RX_COV: "rxCov"
}

export const TRANSLATIONS = {
    [TABLE_KEYS.SOURCE_NAME]: "Source",
    [TABLE_KEYS.PAYER_NAME]: "Payer",
    [TABLE_KEYS.PROVIDER_NAME]: "Provider",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice",
    [TABLE_KEYS.PRODUCT_NAME]: "Product",
    [TABLE_KEYS.START_DATE]: "Eff. Date",
    [TABLE_KEYS.END_DATE]: "End Date",
    [TABLE_KEYS.TYPE]: "Type",
    [TABLE_KEYS.PRIORITY]: "Priority",
    [TABLE_KEYS.STATUS]: "Status",
    [TABLE_KEYS.CM_INCENTIVE]: "CM Incentive",
    [TABLE_KEYS.IDENTIFIERS]: "Identifiers",
    [TABLE_KEYS.MED_COV]: "Medical Coverage",
    [TABLE_KEYS.RX_COV]: "Pharmacy Coverage"
}
