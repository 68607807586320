import { connect } from "react-redux"
import { reset, change } from "redux-form"
import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import { moduleEnabledSelector } from "../../moduleUtils"
import { MODULE_IDS } from "../../constants"
import { showPocPrint } from "../../batch_print/actions"
import * as C from "../constants"
import Reminder from "./ReminderLetterDetails"

export default fetcher({
    name: C.NAME,
    endpoint: state => `/api/patients/${state.poc_id}/letters`,
    method: ApiTypes.GET
})(
    connect(
        state => ({
            allowPrint: moduleEnabledSelector(MODULE_IDS.EXTRACTS)(state)
        }),
        { showPocPrint, reset, change }
    )(Reminder)
)
