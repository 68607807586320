import { connect } from "react-redux"
import { reduxForm, reset } from "redux-form"

import fetcher from "../../../core/fetcher"
import { moduleEnabledSelector } from "../../../moduleUtils"
import { MODULE_IDS } from "../../../constants"
import { ApiTypes } from "../../../api"

import { expandedMeasureSelector, filteredMeasures } from "../../selectors"
import { getTreeCategoryKey, getTreeSubcategoryKey } from "../../helpers"
import * as reduxAction from "../../actions"
import * as C from "../../constants"
import { AddMeasureModalContainer } from "./AddMeasureModalContainer"

const acceptResponse = data => ({
    categories: data.categories.map((category, i) => ({
        label: category.name,
        value: getTreeCategoryKey(i),
        children: category.subCategories.map((subcategory, j) => ({
            label: subcategory.name,
            value: getTreeSubcategoryKey(i, j),
            children: subcategory.measures.map(measure => ({
                label: measure.name,
                value: measure.id,
                abbrevName: measure.abbrevName
            }))
        }))
    }))
})

const AddMeasureFetcher = fetcher({
    name: C.ALL_MEASURES_TREE,
    endpoint: "/api/measures/category",
    method: ApiTypes.GET,
    acceptResponse
})(AddMeasureModalContainer)

const AddMeasureForm = reduxForm({ form: C.ADD_MEASURE_TREE_FORM })(
    AddMeasureFetcher
)

export default connect(
    state => ({
        measures: filteredMeasures(state),
        initialExpanded: expandedMeasureSelector(state),
        disabled: !moduleEnabledSelector(MODULE_IDS.POC_MEASURES)(state),
        initialValues: { measures: [] }
    }),
    { ...reduxAction, reset }
)(AddMeasureForm)
