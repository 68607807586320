import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import {
    optionLookupsSelector,
    patientListCombiner
} from "../patient_list/selectors"
import {
    measureAbbrevMapSelector,
    measuresMapSelector
} from "../measures/selectors"
import * as PLC from "../patient_list/constants"
import { getStartOfYear } from "../dates"
import { compactFilters, encodeFiltersInUrl } from "../utils"
import * as CC from "../constants"
import * as C from "./constants"

export const patientListSelector = createSelector(
    getResourceData(C.PATIENTS, data => data.patients),
    measuresMapSelector,
    optionLookupsSelector,
    state => state.server_status.anchorDate,
    patientListCombiner
)

export const measureNameSelector = createSelector(
    measureAbbrevMapSelector,
    state => state[C.NAME].measureAbbrevName,
    (measureMap, measureAbbrevName) => measureMap?.[measureAbbrevName]?.name
)

export const filterOptionsSelector = createSelector(
    getResourceData(CC.FILTER_OPTIONS),
    getResourceData(C.NAME),
    (options, { measureAbbrevName, partialMeasureAbbrevName }) => ({
        ...options,
        measures: options.measures?.filter(measure =>
            [measureAbbrevName, partialMeasureAbbrevName].includes(
                measure.value
            )
        )
    })
)

export const isDefaultSelector = createSelector(
    state => state[C.NAME],
    ({ measureAbbrevName, filters }) =>
        _.isEqual(
            compactFilters(filters),
            compactFilters({
                ...C.DEFAULT_FILTERS,
                [PLC.FILTERS.MEASURES]: measureAbbrevName
            })
        )
)

export const cmPickerValueSelector = createSelector(
    state => state[C.NAME].filters,
    (filters = {}) =>
        _.find(C.OPTIONS, option =>
            _.isMatch(
                {
                    ...C.OPTION_FILTERS[C.OPTIONS.ELIGIBLE], // ELIGIBLE checks that certain filters are blank, so we have to add those explicitly blank filters as fallbacks
                    ...filters
                },
                C.OPTION_FILTERS[option]
            )
        )
)

const getOptionMaker = (measureAbbrev, filters) => (value, label) => ({
    value,
    label,
    href: encodeFiltersInUrl("/care-management/" + measureAbbrev, {
        ...filters,
        ...C.OPTION_FILTERS[value]
    })
})

export const cmPickerOptionsSelector = createSelector(
    getResourceData(C.NAME),
    state => state[C.NAME].measureAbbrevName,
    state => state[C.NAME].filters,
    state => state.server_status.anchorDate,
    (data, measureAbbrev = "", filters, anchorDate) => {
        const sharedFilters = {
            ...filters,
            [PLC.FILTERS.MEASURES]: measureAbbrev,
            [PLC.FILTERS.LAST_SERVICE_FROM]: getStartOfYear(anchorDate)
        }
        const makeOption = getOptionMaker(measureAbbrev, sharedFilters)

        const complianceOptions =
            data.numBillCdNeededForGoal === 2
                ? [
                      makeOption(
                          C.OPTIONS.PARTIAL,
                          `${data.numPat_1BillCd ?? 0} with one billing code`
                      ),
                      makeOption(
                          C.OPTIONS.COMPLIANT,
                          `${data.numPat_2plusBillCd ??
                              0} with 2+ billing codes`
                      )
                  ]
                : [
                      makeOption(
                          C.OPTIONS.COMPLIANT,
                          `${_.sum(
                              _.compact([
                                  data.numPat_1BillCd,
                                  data.numPat_2plusBillCd
                              ])
                          )} with 1+ billing codes`
                      )
                  ]

        return [
            makeOption(
                C.OPTIONS.ELIGIBLE,
                `${data.numPatElig ?? 0} eligible patients`
            ),
            ...complianceOptions
        ]
    }
)
