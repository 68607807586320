import React from "react"
import { Field } from "redux-form"
import { Col, Row } from "react-bootstrap"
import {
    LabelledDatePicker,
    SelectField,
    TextInput
} from "../../core/CustomFields"
import { afterDateField, beforeDateField } from "../../core/validators"
import { getFacilitiesByIds } from "../../transformFilter"
import { BOOLEAN_OPTIONS, YES_NO_OPTIONS } from "../../constants"
import * as TC from "../../tcm/constants"
import * as C from "../constants"

const sharedProps = {
    component: SelectField,
    multi: true
}

const formatFacilities = filterOptions => facilityIds =>
    getFacilitiesByIds(filterOptions, facilityIds).map(({ value }) => value)

export const FiltersForm = ({
    filterOptions,
    formValues,
    includeActiveFilter,
    includeStatusFilter
}) => (
    <>
        <Row>
            <Col sm={6} xl={{ span: 4, order: 0 }}>
                <Field name={C.FILTERS.PATIENT_NAME} component={TextInput} />
            </Col>
            <Col sm={6} xl={{ span: 4, order: 0 }}>
                <Field
                    name={C.FILTERS.DECEASED}
                    component={SelectField}
                    options={BOOLEAN_OPTIONS}
                />
            </Col>
            <Col sm={6} xl={{ span: 4, order: 1 }}>
                <Field
                    name={C.FILTERS.PROVIDERS}
                    options={filterOptions.providers}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={{ span: 4, order: 0 }}>
                <Field
                    name={C.FILTERS.TAGS}
                    options={filterOptions.tags}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={{ span: 4, order: 1 }}>
                <Field
                    name={C.FILTERS.PRACTICE_UNITS}
                    options={filterOptions.practiceUnits}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={{ span: 4, order: 1 }}>
                <Field
                    name={C.FILTERS.REGIONS}
                    options={filterOptions.regions}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={{ span: 4, order: 1 }}>
                <Field
                    name={C.FILTERS.PRODUCTS}
                    options={filterOptions.productClasses}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={{ span: 4, order: 1 }}>
                <Field
                    name={C.FILTERS.PAYERS}
                    options={filterOptions.payers}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={{ span: 4, order: 1 }}>
                <Field
                    name={C.FILTERS.SOURCES}
                    options={filterOptions.sources}
                    {...sharedProps}
                />
            </Col>
        </Row>
        <hr className="divider" />
        <Row>
            <Col sm={6} xl={4}>
                <Field
                    name={C.FILTERS.FACILITY}
                    options={filterOptions.facilities}
                    {...sharedProps}
                    // facility option values are each an array of numbers (because a single facility can have multiple ids), so they need to be flattened before storing and unflattened on retrieval
                    normalize={_.flatten}
                    format={formatFacilities(filterOptions)}
                />
            </Col>
            <Col sm={6} xl={4}>
                <Field
                    name={C.FILTERS.EVENT_TYPE}
                    options={C.EVENT_TYPES}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={4}>
                <Field
                    name={C.FILTERS.GEN_CONDITIONS}
                    options={filterOptions.genConditions}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={4}>
                <Field
                    name={C.FILTERS.SPECIALISTS}
                    options={filterOptions.specialists}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={4}>
                <Field
                    name={C.FILTERS.CARE_MANAGEMENT}
                    options={YES_NO_OPTIONS}
                    {...sharedProps}
                    multi={false}
                />
            </Col>
            <Col sm={6} xl={4}>
                <Field
                    name={C.FILTERS.CARE_MANAGERS}
                    options={filterOptions.careManagers}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={4}>
                <Field
                    name={C.FILTERS.RISK_RANK}
                    options={filterOptions.riskRank}
                    {...sharedProps}
                />
            </Col>
            <Col sm={6} xl={4}>
                <Field
                    name={C.FILTERS.RISK_TYPE}
                    options={filterOptions.riskType}
                    {...sharedProps}
                />
            </Col>
        </Row>
        <hr className="divider" />
        <Row>
            <Col xs={12} lg={6}>
                <Row>
                    <Col xs={6}>
                        <Field
                            name={C.FILTERS.ADMIT_DT_FROM}
                            validDates={beforeDateField(C.FILTERS.ADMIT_DT_TO)}
                            validate={beforeDateField(C.FILTERS.ADMIT_DT_TO)}
                            component={LabelledDatePicker}
                        />
                    </Col>
                    <Col xs={6}>
                        <Field
                            name={C.FILTERS.ADMIT_DT_TO}
                            validDates={afterDateField(C.FILTERS.ADMIT_DT_FROM)}
                            validate={afterDateField(C.FILTERS.ADMIT_DT_FROM)}
                            component={LabelledDatePicker}
                        />
                    </Col>
                    <Col>
                        <Field
                            name={C.FILTERS.ADMIT_DESCRIPTION}
                            component={TextInput}
                            placeholder="Enter search terms..."
                            parse={value => value?.split(" ")}
                            format={values => values?.join(" ")}
                        />
                    </Col>
                </Row>
            </Col>
            <hr className="divider inner hidden-lg" />
            <Col xs={12} lg={6} className="leftBorderBlue">
                <Row>
                    <Col xs={6}>
                        <Field
                            name={C.FILTERS.DISCHARGE_DT_FROM}
                            validDates={beforeDateField(
                                C.FILTERS.DISCHARGE_DT_TO
                            )}
                            validate={beforeDateField(
                                C.FILTERS.DISCHARGE_DT_TO
                            )}
                            component={LabelledDatePicker}
                            disabled={
                                formValues[C.FILTERS.DISCHARGED] === false
                            }
                        />
                    </Col>
                    <Col xs={6}>
                        <Field
                            name={C.FILTERS.DISCHARGE_DT_TO}
                            validDates={afterDateField(
                                C.FILTERS.DISCHARGE_DT_FROM
                            )}
                            validate={afterDateField(
                                C.FILTERS.DISCHARGE_DT_FROM
                            )}
                            component={LabelledDatePicker}
                            disabled={
                                formValues[C.FILTERS.DISCHARGED] === false
                            }
                        />
                    </Col>
                    {includeActiveFilter && (
                        <Col sm={4} lg={5} xl={4}>
                            <Field
                                name={C.FILTERS.DISCHARGED}
                                options={BOOLEAN_OPTIONS}
                                {...sharedProps}
                                multi={false}
                            />
                        </Col>
                    )}
                    <Col>
                        <Field
                            name={C.FILTERS.DISCHARGE_DESCRIPTION}
                            component={TextInput}
                            placeholder="Enter search terms..."
                            parse={value => value?.split(" ")}
                            format={values => values?.join(" ")}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
        {includeStatusFilter && (
            <React.Fragment>
                <hr className="divider" />
                <Row>
                    <Col lg={6}>
                        <Field
                            name={C.FILTERS.STATUS}
                            options={TC.STATUS_OPTIONS}
                            {...sharedProps}
                            multi={true}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )}
    </>
)
