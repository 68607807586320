import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Button, Modal } from "react-bootstrap"
import FormModal from "../../../core/FormModal"
import { FilterPanel } from "../../../patient_list/components"
import { GuideLink } from "../../../indicators"

export const EditCampaignFiltersModal = ({ url, ...props }) => {
    const [show, setShow] = useState(false)
    const hide = () => setShow(false)

    return (
        <>
            <button className="filter-button" onClick={() => setShow(true)}>
                <span className="fa fa-filter" /> Edit Filters
            </button>
            <FormModal dialogClassName="large-modal" show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Filters
                        <GuideLink
                            page="generate-patient-list"
                            className="white"
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{ padding: "15px", backgroundColor: "#e4eefa" }}
                >
                    <p>
                        <strong>
                            Any changes you make here will only apply until you
                            leave the page. They will not modify the saved
                            campaign.
                        </strong>
                    </p>
                    <FilterPanel fixedScope {...props} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hide}>
                        Close
                    </Button>
                    {/* TODO need a Reset Filters button, I think... but we need to add it to <GeneratePatientList> first */}
                    <Button
                        as={Link}
                        to={url}
                        variant="primary"
                        className="btn-modal-save"
                        onClick={hide}
                        replace
                    >
                        Update Filters
                    </Button>
                </Modal.Footer>
            </FormModal>
        </>
    )
}
