import React, { useMemo } from "react"
import { connect } from "react-redux"
import { change } from "redux-form"
import { easyFormValueSelector } from "../../../../../selectorUtils"
import * as C from "../../../../constants"
import { getCompositeSubmeasureInputName } from "../../../../helpers"
import { CompositeMeasures } from "./CompositeMeasures"

const CompositeMeasuresConnect = connect(
    (state, { valuesSelector }) => ({
        formValues: valuesSelector(state)
    }),
    { change }
)(CompositeMeasures)

const CompositeMeasuresWrapper = props => {
    const valuesSelector = useMemo(
        () =>
            easyFormValueSelector(
                C.POC_MEASURE_FORM,
                ...props.subMeasures.map(
                    getCompositeSubmeasureInputName(props.abbrevName)
                )
            ),
        [props.abbrevName, props.subMeasures]
    )

    return (
        <CompositeMeasuresConnect {...props} valuesSelector={valuesSelector} />
    )
}
export default CompositeMeasuresWrapper
