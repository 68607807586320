import React, { useState } from "react"
import { HistoryOverlay } from "../../../core/HistoryOverlay"
import HistoryList from "./HistoryList"

export const PatientHistory = ({ id }) => {
    const [show, setShow] = useState(false)

    return (
        <HistoryOverlay
            onClick={() => setShow(true)}
            onHide={e => setShow(false)}
            show={show}
            id={id}
        >
            <div id="community_linkage_history_popover">
                <HistoryList id={id} />
            </div>
        </HistoryOverlay>
    )
}
export default PatientHistory
