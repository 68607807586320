import { connect } from "react-redux"

import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import {
    careManagerSelector,
    metadataSelector,
    providersSelector
} from "../selectors"
import CareTeam from "./CareTeam"

export default fetcher({
    name: C.NAME,
    endpoint: state => `/api/patients/${state.poc_id}/care_team`,
    method: ApiTypes.GET
})(
    connect(state => ({
        careManagers: careManagerSelector(state),
        providers: providersSelector(state),
        metadata: metadataSelector(state)
    }))(CareTeam)
)
