import React from "react"
import { connect } from "react-redux"
import { Field } from "redux-form"
import { Col, FormGroup, Row } from "react-bootstrap"

import {
    CheckboxField,
    SelectField,
    PasswordField,
    TextInput
} from "../../../../core/CustomFields"
import LEHOC from "../../../../core/LoadingErrorHOC"
import { getResourceData } from "../../../../core/fetcher"
import UserDisclaimer from "../../../../core/UserDisclaimer"
import { FILTER_OPTIONS } from "../../../../constants"

import * as C from "../../../constants"
import { UserInfoDecoys } from "./UserInfoDecoys"
import { UserTypeInfo } from "./UserTypeInfo"

// Note that all validation is done in validateUser.js, because the validation includes checking that password and confirm password match, which is difficult to do inline

const UserInfoPage = ({
    userExists,
    selectedUser,
    isSuperUser,
    newUser,
    canEditUserInfo = true,
    change,
    loading,
    error,
    roleOptions
}) => (
    <LEHOC loading={loading} error={error}>
        <UserInfoDecoys />
        {canEditUserInfo || (
            <p className="text-danger">
                You cannot edit the user's info because you do not have access
                to their primary practice.
            </p>
        )}
        <Row>
            <Col lg={6}>
                <Field
                    name={C.USER_FIELDS.FIRST_NAME}
                    component={TextInput}
                    disabled={!canEditUserInfo}
                    required
                    autoComplete="chrome-off" // this special value is supposedly enough to prevent autocomplete on Chrome.
                    data-lpignore="true" // important for keeping LastPass out of our hair
                />
            </Col>
            <Col lg={6}>
                <Field
                    name={C.USER_FIELDS.LAST_NAME}
                    component={TextInput}
                    disabled={!canEditUserInfo}
                    required
                    autoComplete="chrome-off"
                    data-lpignore="true"
                />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <Field
                    name={C.USER_FIELDS.LOGIN_ID}
                    component={TextInput}
                    disabled={userExists || !canEditUserInfo}
                    required
                    autoComplete="chrome-off"
                    data-lpignore="true"
                />
            </Col>
            <Col lg={6}>
                <Field
                    name={C.USER_FIELDS.NPI}
                    component={TextInput}
                    disabled={!canEditUserInfo}
                    autoComplete="chrome-off"
                    data-lpignore="true"
                    onChange={(event, value) => {
                        if (!value) {
                            change(C.USER_FIELDS.IS_DEFAULT, false)
                        }
                    }}
                />
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <Field
                    name={C.USER_FIELDS.EMAIL}
                    component={TextInput}
                    disabled={!canEditUserInfo}
                    required
                    autoComplete="chrome-off"
                    data-lpignore="true"
                />
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <Field
                    name={C.USER_FIELDS.USER_STATUS}
                    component={SelectField}
                    disabled={!canEditUserInfo}
                    required
                    notClearable
                    options={
                        userExists
                            ? C.EDIT_USER_STATUS_OPTIONS
                            : C.ADD_USER_STATUS_OPTIONS
                    }
                    onChange={(event, value) => {
                        if (value !== C.DELETED) {
                            change(C.USER_FIELDS.CONFIRM_DELETE, false)
                            // so that if you hit confirm but then change your mind, confirmDelete won't be still selected
                        }
                    }}
                />
            </Col>

            <Col lg={6}>
                <Field
                    name={C.USER_FIELDS.ROLE}
                    component={SelectField}
                    disabled={!isSuperUser || !canEditUserInfo}
                    required
                    notClearable
                    options={roleOptions}
                    label={<UserTypeInfo isSuperuser={isSuperUser} />}
                />
            </Col>
        </Row>
        <Row
            style={{
                display:
                    selectedUser?.[C.USER_FIELDS.USER_STATUS] === C.DELETED
                        ? "block"
                        : "none"
            }}
        >
            <Col sm={12}>
                <FormGroup className="user-deletion-warning">
                    <strong className="warning_label">
                        Are you sure you want to delete this user?
                        <br />
                        This cannot be undone.
                    </strong>
                    <Field
                        name={C.USER_FIELDS.CONFIRM_DELETE}
                        component={CheckboxField}
                        disabled={!canEditUserInfo}
                        label="Confirm delete"
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col lg={6}>
                <Field
                    name={C.USER_FIELDS.NEW_PASSWORD}
                    component={PasswordField}
                    required={newUser}
                    disabled={!canEditUserInfo}
                    label={newUser && "Password"}
                    data-lpignore="true"
                />
            </Col>
            <Col lg={6}>
                <Field
                    name={C.USER_FIELDS.CONFIRM_PASSWORD}
                    component={PasswordField}
                    disabled={!canEditUserInfo}
                    required={newUser}
                    data-lpignore="true"
                />
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <UserDisclaimer />
            </Col>
        </Row>
    </LEHOC>
)

export default connect(state => ({
    roleOptions: getResourceData(FILTER_OPTIONS, data => data.roles)(state)
}))(UserInfoPage)
