import React, { useMemo } from "react"
import { Field } from "redux-form"
import _ from "lodash"
import RFCheckBoxTree from "../../core/RFCheckBoxTree"
import * as C from "../constants"
import { getCheckAllProps } from "../helpers"
import { PrintField } from "./PrintField"
import { ToggleField } from "./PrintLinkages"
import { CheckAllButton } from "./CheckAllButton"

export const PrintLetter = ({ change, dueServices, formValues }) => {
    const buttonProps = useMemo(
        getCheckAllProps(
            change,
            formValues,
            C.PRINT_FIELDS.DUE_SERVICES,
            dueServices
        ),
        [formValues[C.PRINT_FIELDS.DUE_SERVICES], dueServices]
    )

    return _.isEmpty(dueServices) ? (
        <PrintField name={C.PRINT_FIELDS.LETTER} className="print-field-error">
            <h5>This patient has no services due.</h5>
        </PrintField>
    ) : (
        <PrintField name={C.PRINT_FIELDS.LETTER} className="print-letter">
            <Field
                name={C.PRINT_FIELDS.ONLY_INCENTIVIZED}
                className="only-incentivized"
                component={ToggleField}
            />
            <div className="print-checkboxes print-letter-services">
                <h5>Due Services to include:</h5>
                <CheckAllButton {...buttonProps} />
                <RFCheckBoxTree
                    nodes={dueServices}
                    name={C.PRINT_FIELDS.DUE_SERVICES}
                />
            </div>
        </PrintField>
    )
}
