import React, { useCallback } from "react"
import { connect } from "react-redux"
import { Field } from "redux-form"
import classNames from "classnames"
import { ValidatedDatePicker } from "../../../../core/DatePicker"
import {
    after1900,
    todayOrBefore,
    validDate
} from "../../../../core/validators"
import { dateFormat, isBlankDate } from "../../../../dates"
import { MeasureCompliance } from "../../../../indicators"
import { getInputDateName, getMeasureInputName } from "../../../helpers"
import * as C from "../../../constants"
import MeasureName from "./MeasureName"
import MeasureInput from "./MeasureInput"
import History from "./History"
import ChartPopover from "./ChartPopover"
import AutoCalcDate from "./AutoCalculated/date"

const DateInput = ({ measure, disabled, onChange }) => {
    if (measure.dataType !== C.COMPOSITE) {
        if (measure.autoCalc) {
            return (
                <Field
                    name={getInputDateName(measure.abbrevName)}
                    component={AutoCalcDate}
                    measure={measure}
                    disabled={disabled}
                />
            )
        }
        if (!measure.allowEntry) {
            return null
        }
    }
    return (
        <Field
            className="form-group"
            name={getInputDateName(measure.abbrevName)}
            component={ValidatedDatePicker}
            validate={[validDate, after1900, todayOrBefore]}
            onChange={onChange}
            notClearable
            noHighlighting
            validDates={todayOrBefore}
            disabled={disabled}
        />
    )
}

export const Measure = ({
    actions,
    disabled,
    measure,
    savingMeasures,
    style
}) => {
    const loading = measure && savingMeasures?.has(measure.id)
    const hasHistory = !isBlankDate(measure.serviceDt)

    const onChangeDate = useCallback(
        (event, date) => {
            if (measure.dataType === C.CHECKBOX) {
                const inputName = getMeasureInputName(measure)
                if (date) {
                    actions.change(inputName, true)
                } else {
                    actions.clearFields(false, false, inputName)
                }
            }
        },
        [measure, actions]
    )

    return (
        <tr style={style} className="measure_row_tr">
            <td className="measures-name">
                <MeasureName {...measure} loading={loading} />
            </td>
            <td className="measures-compliance">
                <div className="measure-text form-control-static">
                    <MeasureCompliance
                        cellData={measure.compliant}
                        rowData={measure}
                        loading={loading}
                    />
                </div>
            </td>
            <td className="measures-value-last">
                <div
                    className={classNames("measure-text form-control-static", {
                        disabled: loading
                    })}
                >
                    {measure.hasRecentValue ? measure.displayValue : <span />}
                </div>
            </td>
            <td className="measures-date-last">
                <div
                    className={classNames("measure-text form-control-static", {
                        disabled: loading
                    })}
                >
                    <span>
                        {measure.hasRecentValue &&
                            dateFormat(measure.serviceDt)}
                    </span>
                </div>
            </td>
            {disabled || (
                <>
                    <td className="measures-value-new">
                        <MeasureInput
                            {...measure}
                            measure={measure}
                            disabled={loading}
                        />
                    </td>
                    <td className="measures-date-new">
                        <DateInput
                            measure={measure}
                            disabled={loading}
                            onChange={onChangeDate}
                        />
                    </td>
                </>
            )}
            <td className="measures-history popup-history">
                <History {...measure} hasHistory={hasHistory} />
                <ChartPopover
                    {...measure}
                    hasHistory={hasHistory}
                    measureId={measure.id}
                    offerGraph={measure.offerGraph}
                />
            </td>
        </tr>
    )
}

export default connect(state => state[C.MEASURES])(Measure)
