import React, { Component, useState } from "react"
import { Card, Collapse } from "react-bootstrap"
import { ErrorBoundary } from "react-error-boundary"
import CardTitle from "../../../core/CardTitle"
import { MeasureSubcategory } from "./MeasureSubcategory"

const MeasureCategory = ({
    actions,
    category: { name, incentivized, subCategories },
    disabled
}) => {
    const [open, setOpen] = useState(true)

    return (
        <Card>
            <ErrorBoundary
                fallback={<Card.Body>Error loading results</Card.Body>}
            >
                <Card.Header role="tab">
                    <CardTitle>
                        <button
                            onClick={() => setOpen(!open)}
                            aria-expanded={open}
                        >
                            {name}
                            {incentivized !== 0 && <mark>{incentivized}</mark>}
                            <span className="toggle pull-right" />
                        </button>
                    </CardTitle>
                </Card.Header>
                <Collapse
                    className="card-collapse"
                    in={open}
                    aria-expanded={open}
                >
                    <Card.Body>
                        {subCategories.map(subcategory => (
                            <MeasureSubcategory
                                key={subcategory.name}
                                subcategory={subcategory}
                                actions={actions}
                                disabled={disabled}
                            />
                        ))}
                    </Card.Body>
                </Collapse>
            </ErrorBoundary>
        </Card>
    )
}
export default MeasureCategory
