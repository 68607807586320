import React from "react"
import classNames from "classnames"

export const BasicIcon = ({ className, color, ...props }, ref) => (
    <span
        className={classNames("fa", className)}
        style={{ color }}
        ref={ref}
        {...props}
    />
)

export default React.forwardRef(BasicIcon)
