import React, { Component, useCallback, useRef, useState } from "react"
import { createPortal } from "react-dom"
import Cleave from "cleave.js/react"
import moment from "moment"
import DateTime from "react-datetime"
import classNames from "classnames"
import _ from "lodash"
import {
    DATE_STRING,
    TIME_STRING,
    TIME_STRING_NO_AMPM,
    TIMESTAMP_STRING_FIELD
} from "../dates"
import { getStateClass, getValidProps } from "./helpers"
import {
    DatePickerBreakoutOverlay,
    DatePickerButton,
    formatDate,
    isValidDate
} from "./DatePicker"
import { defaultOrBlank } from "../utils"

const DIGITS = "1234567890"

const parseAmPmValue = (value, prevValue = "") => {
    if (!value || value.length < prevValue.length) return ""

    const charEntered = _.lowerCase(
        _.head(_.difference([...value], [...prevValue]))
    ) // onChange event doesn't tell us what key we pressed, so we get it by diffing the new and old values
    if (charEntered === "a") return "am"
    if (charEntered === "p") return "pm"
    return prevValue
}

const moveRight = (event, nextElement, inputLength) => {
    const cursorIndex = event.target.selectionStart // position the cursor was at when you pressed the key

    const movedRightFromEnd =
        ["ArrowRight", " "].includes(event.key) && cursorIndex === inputLength
    const typedLastDigit =
        DIGITS.includes(event.key) && cursorIndex >= inputLength - 1

    if (movedRightFromEnd || typedLastDigit) {
        setTimeout(() => {
            // setTimeout because we need this to trigger after the existing handler, instead of before
            nextElement?.focus()
            nextElement?.setSelectionRange(0, 0)
        })
    }
}

const moveLeft = (event, prevElement, prevInputLength) => {
    const cursorIndex = event.target.selectionStart // position the cursor was at when you pressed the key

    if (event.key === "ArrowLeft" && cursorIndex === 0) {
        setTimeout(() => {
            // setTimeout because we need this to trigger after the existing handler, instead of before
            prevElement?.focus()
            prevElement?.setSelectionRange(prevInputLength, prevInputLength)
        })
    }
}

const DateTimePickerInput = ({
    className,
    id,
    inputRef,
    onChange,
    value,
    ...props
}) => {
    const dateRef = inputRef ?? useRef(0) // XXX I'm not sure if this is ok to do. I think refs created by createRef might be different in some way from useRef ones, because in DatePicker Cleave can only work with refs in htmlRef={ref => inputRef.current=ref} form.
    const timeRef = useRef(0)
    const amPmRef = useRef(0)

    const [dateValue = "", timeValue = "", amPmValue = ""] = value.split(" ")

    return (
        <div className={classNames(className, "datepicker-inputs")}>
            <Cleave
                {...props}
                ref={dateRef}
                value={dateValue}
                className="datepicker-input-date"
                id={`${id}-date`}
                onChange={event =>
                    onChange(
                        _.join([event.target.value, timeValue, amPmValue], " ")
                    )
                }
                onKeyDown={event =>
                    moveRight(event, timeRef.current?.element, 10)
                }
                autoComplete="off"
                placeholder={DATE_STRING}
                options={{
                    date: true,
                    datePattern: ["m", "d", "Y"]
                }}
            />
            <Cleave
                {...props}
                ref={timeRef}
                value={timeValue}
                className="datepicker-input-time"
                id={`${id}-time`}
                onChange={event =>
                    onChange(
                        _.join([dateValue, event.target.value, amPmValue], " ")
                    )
                }
                onKeyDown={event => {
                    moveRight(event, amPmRef.current, 5)
                    moveLeft(event, dateRef.current?.element, 10)
                }}
                autoComplete="off"
                placeholder={TIME_STRING_NO_AMPM}
                options={{
                    time: true,
                    timePattern: ["h", "m"],
                    timeFormat: "12"
                }}
            />
            <input
                {...props}
                ref={amPmRef}
                value={amPmValue}
                type="text"
                className="datepicker-input-ampm"
                id={`${id}-ampm`}
                autoComplete="off"
                placeholder="am"
                onChange={event =>
                    onChange(
                        _.join(
                            [
                                dateValue,
                                timeValue,
                                parseAmPmValue(event.target.value, amPmValue)
                            ],
                            " "
                        )
                    )
                }
                onKeyDown={event =>
                    moveLeft(event, timeRef.current?.element, 5)
                }
            />
        </div>
    )
}

class DateTimePickerComponent extends Component {
    menuRef = this.props.menuRef || React.createRef()

    closeCalendar = () => null // will be redefined as soon as the input is rendered though

    renderInput = (inputProps, openCalendar, closeCalendar) => {
        this.closeCalendar = closeCalendar // it's pretty dumb that I had to do it like this, but this closeCalendar function is the only way I could find to imperatively close the picker. So we have to pass it along to the other places that need it.

        return (
            <DateTimePickerInput
                {...inputProps}
                name={this.props.name}
                inputRef={this.props.inputRef}
                disabled={this.props.disabled}
                value={this.props.value || "  "} // DateTime doesn't always seem to update in time, so set the displayed value manually
                className={this.props.inputClassName}
            />
        )
    }

    renderView = (viewMode, renderDefault) => {
        return (
            this.props.open &&
            createPortal(
                <DatePickerBreakoutOverlay
                    closeCalendar={this.closeCalendar}
                    containerRef={this.props.containerRef}
                    menuRef={this.menuRef}
                >
                    {renderDefault()}
                </DatePickerBreakoutOverlay>,
                document.body
            )
        )
    }

    render() {
        return (
            <DateTime
                {...this.props}
                dateFormat={DATE_STRING}
                timeFormat={TIME_STRING}
                strictParsing
                // no closeOnSelect because we also need to select the time
                closeOnTab
                closeOnClickOutside={false}
                renderInput={this.renderInput}
                renderView={this.renderView}
            />
        )
    }
}

/**
 * Important note: this component only accepts dates as Moment objects, or as strings in MM/DD/YYYY
 * format (or whichever format is specified in the dateFormat prop). Which is dumb, but unfortunately
 * it's necessary because we need to be able to have partial strings stored and converting a YYYY-MM-DD
 * formatted datestring to MM/DD/YYYY (or vice versa) would lose that
 */
export const DateTimePicker = (props, ref) => {
    const {
        inputProps = {},
        disabled = inputProps.disabled,
        name,
        value,
        defaultValue,
        warning,
        error,
        style,
        className,
        menuRef,
        onBlur,
        onChange,
        onFocus,
        initialViewDate
    } = props

    const inputRef = ref ?? useRef()
    const containerRef = useRef()

    const [open, setOpen] = useState(false)

    const handleChange = inputValue => {
        onChange?.(formatDate(inputValue, TIMESTAMP_STRING_FIELD))
    }
    const handleBlur = () => {
        onBlur?.(value) // don't use the value that DateTime passes into its onBlur, because that value is actually outdated
        setOpen(false)
    }
    const handleFocus = event => {
        onFocus?.(event)
        setOpen(true)
    }

    const setDate = date => {
        handleChange(date)
        onBlur?.(date) // we can't just call this.handleBlur(), because we actually do want to use  `date` instead of `this.props.date`

        // setShow(false)
    }

    const setDateToday = useCallback(
        () => setDate(moment().format(TIMESTAMP_STRING_FIELD)),
        [setDate]
    )
    const clearDate = useCallback(
        () => setDate(defaultOrBlank(value, defaultValue)),
        [setDate, value, defaultValue]
    )

    const isValidDateCallback = useCallback(isValidDate(props), [props])

    return (
        <div
            ref={containerRef}
            style={style}
            className={classNames(
                "datepicker rdtBreakoutContainer",
                className,
                getStateClass(disabled, error, warning, value)
            )}
        >
            <DateTimePickerComponent
                name={name}
                value={value}
                open={open}
                onChange={handleChange}
                onClose={handleBlur}
                onOpen={handleFocus}
                inputProps={inputProps}
                initialViewDate={initialViewDate}
                isValidDate={isValidDateCallback}
                inputClassName={classNames(
                    "form-control",
                    inputProps?.className
                )}
                disabled={disabled}
                inputRef={inputRef}
                containerRef={containerRef}
                menuRef={menuRef}
            />
            <DatePickerButton
                {...props}
                disabled={disabled}
                clearDate={clearDate}
                setDateToday={setDateToday}
                todayTooltip="Use current date and time"
            />
        </div>
    )
}

const FwdDateTimePicker = React.forwardRef(DateTimePicker)
export default FwdDateTimePicker

export const ValidatedDateTimePicker = props => {
    const { input, meta, ...otherProps } = props
    const validProps = getValidProps(input, meta)
    return <FwdDateTimePicker {...otherProps} {...validProps} />
}
