import React from "react"
import Select from "../../../../../core/Select"
import * as C from "../../../../constants"
import { itemsToLabelValue } from "../../../../../utils"

const InputComponent = ({
    dataType,
    errors,
    values,
    onChange,
    onCompositeChange,
    dropListOptions,
    subMeasureValues,
    measureId,
    disabled
}) => {
    switch (dataType) {
        case C.COMPOSITE:
            return (
                <div>
                    {subMeasureValues?.map((item, index) => {
                        let id = item.id ?? item.measureId
                        return (
                            <input
                                key={index}
                                disabled={disabled}
                                className={!!errors[id] ? "error" : ""}
                                value={values[id] || ""}
                                onChange={e => onCompositeChange(e, id)}
                                placeholder={item.name}
                                style={{ marginBottom: "5px" }}
                            />
                        )
                    })}
                </div>
            )
        case C.CHECKBOX:
            return (
                <span>
                    {dropListOptions.reduce((acc, cur) => cur.name, "")}
                </span>
            )
        case C.ENUMERATION:
            return (
                <select
                    disabled={disabled}
                    value={values[measureId] || ""}
                    onChange={onChange}
                    style={{ height: "23px", maxWidth: "100px" }}
                >
                    {dropListOptions.map((item, index) => (
                        <option key={index} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </select>
            )
        case C.MULTISELECT:
            return (
                <Select
                    className="edit-measure-multiselect"
                    value={values[measureId]?.split?.(";")?.map?.(Number)}
                    onChange={value =>
                        onChange({ target: { value: value.join(";") } })
                    }
                    options={itemsToLabelValue(
                        dropListOptions,
                        item => item.name,
                        item => item.id
                    )}
                    multi
                />
            )
        case C.ALPHANUMERIC:
        case C.NUMERIC:
            return (
                <input
                    disabled={disabled}
                    className={!!errors[measureId] ? "error" : ""}
                    type="text"
                    value={values[measureId] || ""}
                    onChange={onChange}
                />
            )
        default:
            return <div />
    }
}

export default InputComponent
