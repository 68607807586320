import _ from "lodash"

import { conditionalParens } from "../../../utils"
import * as PC from "../../../permission_managment/constants"
import * as C from "../../constants"

export const getUserName = user =>
    `${user[C.USER_TABLE_KEYS.FIRST_NAME]} ${user[C.USER_TABLE_KEYS.LAST_NAME]}`

export const getUserNameWithLoginId = row =>
    `${row[C.USER_TABLE_KEYS.LAST_NAME].trim()}, ${
        row[C.USER_TABLE_KEYS.FIRST_NAME]
    } (${row[C.USER_TABLE_KEYS.LOGIN_ID]})`

export const getUserTitle = user =>
    `${user[C.USER_FIELDS.FIRST_NAME] || ""} ${user[C.USER_FIELDS.LAST_NAME] ||
        ""} ${conditionalParens(user[C.USER_FIELDS.LOGIN_ID] || "")}`

export const getUserById = (users, userId) =>
    _.find(users, { [C.USER_TABLE_KEYS.USER_ID]: userId }) || {}

export const getPrimaryPractice = (user = {}, field) => {
    const primaryPractice = _.find(
        user[C.USER_TABLE_KEYS.PRACTICES],
        practice => practice[C.USER_PRACTICE_KEYS.IS_PRIMARY_PRACTICE]
    )
    return field ? _.get(primaryPractice, field) : primaryPractice
}

export const practiceIsValid = practice =>
    practice.practiceUnitId !== 0 &&
    [C.ACTIVE, undefined].includes(practice[C.PRACTICE_TABLE_KEYS.STATUS])

export const canReadTag = tag =>
    [PC.ACCESS_PRIVILEGES.EDIT, PC.ACCESS_PRIVILEGES.READ].includes(
        tag[C.TAG_COLUMNS.PRIVILEGE]
    )
export const canEditTag = tag =>
    tag[C.TAG_COLUMNS.PRIVILEGE] === PC.ACCESS_PRIVILEGES.EDIT

export const isSuperuser = user =>
    user.isSuperuser ||
    user[C.USER_TABLE_KEYS.ACCESS_LEVEL] === C.SUPERUSER_VALUE

class ProviderLock {
    constructor(message, value) {
        this.message = message
        this.value = value
    }
}

const getProviderLock = ({
    provider,
    handler,
    isSelf,
    isPracticeAdmin,
    inPrimaryPractice,
    adminPracticeName
}) => {
    const handlerName = getUserName(handler)
    const providerName = getUserName(provider)

    if (!provider[C.USER_TABLE_KEYS.NPI]) {
        return new ProviderLock(
            `${providerName} has no panel to access.`,
            false
        )
    } else if (isSelf) {
        return new ProviderLock(
            "Users can always access their own panel.",
            true
        )
    } else if (isSuperuser(handler)) {
        return new ProviderLock(
            `As a superuser, ${handlerName} can access every panel.`,
            true
        )
    } else if (isPracticeAdmin && adminPracticeName) {
        return new ProviderLock(
            `As a practice admin for ${adminPracticeName}, ${handlerName} can access every panel in that practice.`,
            true
        )
    } else if (isPracticeAdmin) {
        return new ProviderLock(
            `As a practice admin, ${handlerName} can access every panel in this practice.`,
            true
        )
    } else if (!inPrimaryPractice) {
        return new ProviderLock(
            `${handlerName} is not in ${providerName}'s primary practice.`,
            false
        )
    }
    return null
}

export const getProviderLocks = (
    user,
    currentPracticeId,
    selectedUser,
    selectedUserId
) => {
    const isSelf = user[C.USER_TABLE_KEYS.USER_ID] === selectedUserId
    const selectedUserPrimaryPractice =
        selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE]

    const selectedUserIsAdmin = _.includes(
        selectedUser[C.USER_FIELDS.ADMIN_UNITS],
        currentPracticeId
    )

    const thisIsSelectedUserPrimaryPractice =
        currentPracticeId === selectedUserPrimaryPractice

    const isInSelectedUserPrimaryPractice = _.some(
        user[C.USER_TABLE_KEYS.PRACTICES],
        {
            [C.USER_PRACTICE_KEYS.PRACTICE_ID]: selectedUserPrimaryPractice
        }
    )
    const isFromSelectedUserPrimaryPractice = _.some(
        user[C.USER_TABLE_KEYS.PRACTICES],
        {
            [C.USER_PRACTICE_KEYS.PRACTICE_ID]: selectedUserPrimaryPractice,
            [C.USER_PRACTICE_KEYS.IS_PRIMARY_PRACTICE]: true
        }
    )
    const adminInSelectedUserPrimaryPractice = _.some(
        user[C.USER_TABLE_KEYS.PRACTICES],
        {
            [C.USER_PRACTICE_KEYS.PRACTICE_ID]: selectedUserPrimaryPractice,
            [C.USER_PRACTICE_KEYS.IS_PRACTICE_ADMIN]: true
        }
    )

    return {
        providerLock: getProviderLock({
            provider: user,
            handler: selectedUser,
            isSelf,
            isPracticeAdmin: selectedUserIsAdmin,
            inPrimaryPractice: isFromSelectedUserPrimaryPractice
        }),
        handlerLock: getProviderLock({
            provider: selectedUser,
            handler: user,
            isSelf,
            isPracticeAdmin: adminInSelectedUserPrimaryPractice,
            adminPracticeName:
                !thisIsSelectedUserPrimaryPractice &&
                adminInSelectedUserPrimaryPractice &&
                selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE_NAME],
            inPrimaryPractice: isInSelectedUserPrimaryPractice
        })
    }
}
