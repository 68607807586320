import moment from "moment"
import { createSelector } from "reselect"
import _ from "lodash"

import { getResourceData, getResourceDataAsObject } from "../core/fetcher"
import {
    ALLOW_GLOBAL_SEARCH,
    FUZZY_SEARCH_FLAGS
} from "../site_settings/constants"
import { tableFiltersSelector } from "../site_settings/selectors"
import { measuresMapSelector } from "../measures/selectors"
import auth from "../authentication"
import { FILTER_OPTIONS, SETTINGS } from "../constants"
import {
    compactFilters,
    joinTruthy,
    omitEmpty,
    valueLabelToObj
} from "../utils"
import * as C from "./constants"

export const optionLookupsSelector = createSelector(
    getResourceData(FILTER_OPTIONS),
    filterOptions =>
        filterOptions && {
            tags: valueLabelToObj(filterOptions[C.FILTERS.TAGS]),
            hccConditions: valueLabelToObj(
                filterOptions[C.FILTERS.HCC_CONDITIONS]
            ),
            genConditions: valueLabelToObj(
                filterOptions[C.FILTERS.GEN_CONDITIONS]
            ),
            races: valueLabelToObj(filterOptions[C.FILTERS.RACE]),
            ethnicities: valueLabelToObj(filterOptions[C.FILTERS.ETHNICITY])
        }
)

export const patientListCombiner = (
    patients = [],
    measuresMap,
    { tags, hccConditions, genConditions, races, ethnicities },
    anchorDate
) => {
    const anchorMoment = moment(anchorDate)
    return patients.map(patient => ({
        ...patient,
        [C.TABLE_KEYS.PY_AGE]: anchorMoment.diff(
            moment(patient[C.TABLE_KEYS.PATIENT_DOB]),
            "years"
        ),
        [C.TABLE_KEYS.PATIENT_RACE]: _.isEmpty(races)
            ? ""
            : races[patient[C.TABLE_KEYS.PATIENT_RACE]],
        [C.TABLE_KEYS.PATIENT_ETHNICITY]: _.isEmpty(ethnicities)
            ? ""
            : ethnicities[patient[C.TABLE_KEYS.PATIENT_ETHNICITY]],
        [C.TABLE_KEYS.MEASURE_LINK]: _.get(measuresMap, [
            patient[C.TABLE_KEYS.MEASURE_ID],
            C.TABLE_KEYS.MEASURE_LINK
        ]),
        [C.TABLE_KEYS.TAG_NAMES]: _.isEmpty(tags)
            ? []
            : (patient[C.TABLE_KEYS.TAG_IDS] || [])
                  .map(tagId => tags[tagId])
                  .sort(),
        [C.TABLE_KEYS.HCC_CONDITION_NAMES]: _.isEmpty(hccConditions)
            ? []
            : (patient[C.TABLE_KEYS.HCC_CONDITIONS] || [])
                  .map(conditionId => hccConditions[conditionId])
                  .sort(),
        [C.TABLE_KEYS.GEN_CONDITION_NAMES]: _.isEmpty(genConditions)
            ? []
            : (patient[C.TABLE_KEYS.GEN_CONDITIONS] || [])
                  .map(conditionId => genConditions[conditionId])
                  .sort(),
        [C.TABLE_KEYS.INCENTIVE_PAYERS]: _.isEmpty(
            patient[C.TABLE_KEYS.INCENTIVE_PAYER_IDS]
        )
            ? {}
            : {
                  [C.TABLE_KEYS.PAYER_IDS]:
                      patient[C.TABLE_KEYS.INCENTIVE_PAYER_IDS],
                  [C.TABLE_KEYS.PAYER_NAMES]:
                      patient[C.TABLE_KEYS.INCENTIVE_PAYER_NAMES],
                  [C.TABLE_KEYS.PAYER_COLORS]:
                      patient[C.TABLE_KEYS.INCENTIVE_PAYER_COLORS]
              }
    }))
}

export const patientListSelector = createSelector(
    getResourceData(C.NAME, data => data.patients),
    measuresMapSelector,
    optionLookupsSelector,
    state => state.server_status.anchorDate,
    patientListCombiner
)

export const searchFlagsSelector = getResourceDataAsObject(
    SETTINGS,
    data => data[FUZZY_SEARCH_FLAGS]
)

export const defaultInitialValuesSelector = createSelector(
    tableFiltersSelector(C.PATIENT_LIST_FILTER_FORM),
    searchFlagsSelector,
    state => state[C.NAME]?.type,
    (storedFilters, searchFlags, type) => ({
        ...C.initialValues,
        ...searchFlags,
        ...omitEmpty({ type }),
        ...storedFilters
    })
)

export const isDefaultSelector = createSelector(
    state => state.applied_filters,
    defaultInitialValuesSelector,
    (filters, defaults) =>
        _.isEqual(compactFilters(filters), compactFilters(defaults))
)

export const fixedScopeSelector = state =>
    !getResourceData(SETTINGS, data => data[ALLOW_GLOBAL_SEARCH])(state)
        ? "Global patient search is disabled for non-superusers."
        : auth.selectors.isExternal(state)
        ? "External users are limited to their own panels."
        : false

const PAYER_SOURCE_PRIORITIES = {
    // if a payer doesn't have a logo, we use the logo for that coverage's source instead. And vice versa.
    [C.TABLE_KEYS.PAYER_ID]: (payerItem, sourceItem) => payerItem ?? sourceItem,
    [C.TABLE_KEYS.SOURCE_ID]: (payerItem, sourceItem) => sourceItem ?? payerItem
}

export const logoGetterSelector = createSelector(
    getResourceDataAsObject(FILTER_OPTIONS),
    ({ payerLogos, sourceLogos }) => ({
        getLogo: ({ payerId, sourceId, idField }) =>
            PAYER_SOURCE_PRIORITIES[idField]?.(
                payerLogos?.[payerId],
                sourceLogos?.[sourceId]
            ),
        getName: ({ payerName, sourceName, idField }) =>
            PAYER_SOURCE_PRIORITIES[idField]?.(payerName, sourceName)
    })
)

export const tableDataSelector = createSelector(
    state => state.applied_filters,
    applied_filters => ({
        useMeasureServiceDate: !_.isEmpty(applied_filters[C.FILTERS.MEASURES])
    })
)

export const translationSelector = createSelector(
    state => state.applied_filters,
    filters => ({
        ...C.FILTER_TRANSLATION,
        [C.FILTERS.FUZZY_SEARCH]: joinTruthy(
            [
                C.FILTERS.FUZZY_SEARCH,
                C.FILTERS.SEARCH_PATIENT_ID,
                C.FILTERS.SEARCH_CONTRACT_NUMBER
            ].map(field => filters[field] && C.FILTER_TRANSLATION[field])
        )
    })
)
