import React, { useEffect, useRef, useState } from "react"
import BaseSelect from "react-select"
import Creatable from "react-select/creatable"
import classNames from "classnames"
import { findByValue, getValues, isNilOrEmpty } from "../utils"

const closeMenuOnScroll = event =>
    !event.target.classList?.contains("select__menu-list") // always close the menu on any kind of scroll, except scrolling inside the menu itself. Otherwise, if you have a dropdown inside a scrollable container, you can scroll the select out of visibility, but the menu will still be visible.

const getValue = item => (item ? item.value : null)

const customOption = value => ({
    label: value,
    value
})

const findOption = (value, options, custom) =>
    findByValue(options, value) || (custom ? customOption(value) : null)

const getSelectedOptions = (
    value,
    { clearWhenDisabled, disabled, options, multi },
    custom
) => {
    if (isNilOrEmpty(value) || (clearWhenDisabled && disabled)) {
        return null // returning 'null' because passing 'undefined' to the Select keeps the previous value. Only 'null' clears it.
    }
    if (multi) {
        return (value || [])
            .map(val => findOption(val, options, custom))
            .filter(x => !!x)
    }
    return findOption(value, options, custom)
}

const getOnChange = (onChange, multi) => newSelected =>
    onChange?.(multi ? getValues(newSelected) : getValue(newSelected))

// react-select only uses its defaultValue prop when value is undefined, but setting value to undefined makes the Select component uncontrolled, so the value doesn't change. Instead we have to roll our own system for tracking the default value.
const useDefaultValue = (value, defaultValue) => {
    const [defVal, setDefVal] = useState(defaultValue)
    useEffect(() => {
        if (defVal !== null && value !== undefined) {
            setDefVal(null)
        }
    }, [value])
    return value ?? defVal
}

export const Select = (props, forwardedRef) => {
    const {
        className,
        disabled,
        multi,
        loading,
        defaultValue, // only used when not in a SelectField; the form defines its own defaults
        value,
        onChange
    } = props

    const ref = forwardedRef ?? useRef()
    const val = useDefaultValue(value, defaultValue)

    const inModal = !!document.querySelector(".modal")

    return (
        <BaseSelect
            ref={ref}
            menuPortalTarget={document.body}
            menuPosition={inModal ? "fixed" : "absolute"} // need fixed position in modals or else the menu sometimes doesn't show, but fixed-position menus interact poorly with scrolling
            classNamePrefix="select"
            menuPlacement="auto"
            tabSelectsValue={false}
            menuShouldScrollIntoView={true}
            captureMenuScroll={true}
            onBlur={event => event.preventDefault()} // gets overridden in SelectField
            {...props}
            isMulti={multi}
            isDisabled={disabled}
            isLoading={loading}
            className={classNames("select", className)}
            defaultValue={undefined} // react-select can't be trusted to use this responsibly
            value={getSelectedOptions(val, props, false)}
            onChange={getOnChange(onChange, multi)}
            closeMenuOnScroll={closeMenuOnScroll}
        />
    )
}

export default React.forwardRef(Select)

export const CreatableSelect = props => {
    const {
        className,
        disabled,
        loading,
        multi,
        onChange,
        options: defaultOptions,
        defaultValue,
        value
    } = props

    const ref = useRef()

    const [options, setOptions] = useState([]) // store options in state because users can add new options
    useEffect(() => {
        setOptions(defaultOptions || [])
    }, [defaultOptions]) // reset options if default options change

    const val = useDefaultValue(value, defaultValue)

    return (
        <Creatable
            ref={ref}
            menuPortalTarget={document.body}
            allowCreateWhileLoading
            classNamePrefix="select"
            createOptionPosition="first"
            placeholder="Select, or enter new value..."
            {...props}
            isMulti={multi}
            isDisabled={disabled}
            isLoading={loading}
            className={classNames("select select-creatable", className)}
            defaultValue={undefined} // react-select can't be trusted to use this responsibly
            value={getSelectedOptions(val, props, true)}
            onChange={getOnChange(onChange, multi)}
            onCreateOption={newValue => {
                setOptions([...options, customOption(newValue)])
                onChange(multi ? [...value, newValue] : newValue)
            }}
            closeMenuOnScroll={closeMenuOnScroll}
        />
    )
}
