import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { push } from "connected-react-router"
import moment from "moment"

import { modifyResource } from "../core/fetcher"
import { getSelectedColumnKeys } from "../table/helpers"
import { setPatientFilter } from "../patient_list/actions"
import transformFilter from "../transformFilter"
import { ApiTypes, downloadFile, tryFetch, warnAboutPdfColumns } from "../api"
import { findAndUpdate, urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"
import { TIMESTAMP_STRING_COMPACT } from "../dates"
import { notifySimpleError } from "../notifications"

import * as C from "./constants"
import {
    getCampaignTableName,
    pinCampaignInList,
    removeCampaignFromList,
    subscribeToCampaign,
    unpinCampaignFromTopCampaigns,
    unpinCampaignInList,
    unsubscribeToCampaign,
    updateCampaignList,
    updateCampaignPage
} from "./helpers"
import { hideEdit, pinUnpinCampaignComplete } from "./actions"
import { endpointParamsSelector } from "./selectors"

function* getParams(campaignId) {
    const tableName = getCampaignTableName(campaignId)
    const filters = yield select(endpointParamsSelector)
    const columns = yield select(getSortedColumns(tableName))
    return { ...filters, ...columns }
}

function* requestReport(extension) {
    const campaignId = yield select(state => state[C.NAME][C.CURRENT_CAMPAIGN])
    const params = yield getParams(campaignId)
    const endpoint = urlWithParams(
        `/api/campaigns/${campaignId}/patients/${extension}`,
        params
    )
    const filename = `campaign_${moment().format(
        TIMESTAMP_STRING_COMPACT
    )}.${extension}`

    yield* warnAboutPdfColumns(extension, params)
    yield* downloadFile(
        `Campaign ${extension.toUpperCase()}`,
        endpoint,
        filename
    )
}

export function* exportCampaignPatientList() {
    yield requestReport("csv")
}

export function* printCampaignPatientList() {
    yield requestReport("pdf")
}

export const getCampaignInfo = campaign => ({
    campaignInfo: {
        [C.TABLE_KEYS.CAMPAIGN_NAME]: campaign[C.TABLE_KEYS.CAMPAIGN_NAME],
        [C.TABLE_KEYS.CAMPAIGN_DESCRIPTION]:
            campaign[C.TABLE_KEYS.CAMPAIGN_DESCRIPTION],
        [C.TABLE_KEYS.CAMPAIGN_FILTER]: JSON.stringify(
            transformFilter(campaign[C.TABLE_KEYS.CAMPAIGN_FILTER])
        ),
        [C.TABLE_KEYS.ALL_HVPA_VISIBILITY]:
            campaign[C.TABLE_KEYS.ALL_HVPA_VISIBILITY],
        [C.TABLE_KEYS.CAMPAIGN_TYPE]: campaign[C.TABLE_KEYS.CAMPAIGN_TYPE],
        [C.TABLE_KEYS.PIN_TYPE]: campaign[C.TABLE_KEYS.PIN_TYPE]
    },
    [C.TABLE_KEYS.UNIT_PRIVILEGES]: campaign[
        C.TABLE_KEYS.UNIT_PRIVILEGES
    ].filter(unit => unit.privilege),
    [C.TABLE_KEYS.USER_PRIVILEGES]: campaign[
        C.TABLE_KEYS.USER_PRIVILEGES
    ].filter(unit => unit.privilege),
    [C.TABLE_KEYS.EXTRA_PATIENTS]: campaign[C.TABLE_KEYS.EXTRA_PATIENTS].map(
        item => item.value
    ),
    [C.TABLE_KEYS.COLUMNS]: getSelectedColumnKeys(
        campaign[C.TABLE_KEYS.COLUMNS].columns
    ),
    [C.TABLE_KEYS.FROZEN_COLUMNS]: getSelectedColumnKeys(
        campaign[C.TABLE_KEYS.COLUMNS].frozenColumns
    )
})

export function* createCampaign(action) {
    yield* tryFetch(
        {
            url: "/api/campaigns",
            method: ApiTypes.POST,
            body: getCampaignInfo(action.payload)
        },
        function*(body) {
            if (action.payload.navigateToCampaign) {
                yield put(push(`/campaigns/${body.campaign.campaignId}`))
            } else {
                yield put(
                    modifyResource({
                        name: C.CAMPAIGN_LIST,
                        dataTransform: updateCampaignList(body)
                    })
                )
            }
        },
        notifySimpleError(
            `Failed to create campaign "${action.payload.name || ""}"`
        )
    )
}

export function* saveCampaignEdits(action) {
    yield* tryFetch(
        {
            url: "/api/campaigns",
            method: ApiTypes.PUT,
            body: {
                campaignId: action.payload.campaignId,
                ...getCampaignInfo(action.payload)
            }
        },
        function*(updatedCampaign) {
            yield put(
                modifyResource({
                    name: C.CAMPAIGN_PAGE,
                    dataTransform: updateCampaignPage(updatedCampaign)
                })
            )
            yield put(
                modifyResource({
                    name: C.CAMPAIGN_LIST,
                    dataTransform: updateCampaignList(updatedCampaign)
                })
            )
            yield put({ type: C.HIDE_EDIT })
        },
        notifySimpleError(
            `Failed to save edits to campaign "${action.payload.name || ""}"`
        )
    )
}

export function* deleteCampaign(action) {
    const id = action.payload
    yield* tryFetch(
        {
            url: `/api/campaigns/${id}`,
            method: ApiTypes.DELETE
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.CAMPAIGN_LIST,
                    dataTransform: removeCampaignFromList(id)
                })
            )
        },
        notifySimpleError("Failed to delete campaign")
    )
}

export function* unsubscribeCampaign(action) {
    const { campaignId, name } = action.payload
    yield* tryFetch(
        {
            url: `/api/campaigns/${campaignId}/unsubscribe`,
            method: ApiTypes.POST
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.CAMPAIGN_LIST,
                    dataTransform: unsubscribeToCampaign(campaignId)
                })
            )
        },
        notifySimpleError(`Failed to unsubscribe from campaign "${name}"`)
    )
}

export function* resubscribeToCampaign(action) {
    const { campaignId, name } = action.payload
    yield* tryFetch(
        {
            url: `/api/campaigns/${campaignId}/subscribe`,
            method: ApiTypes.POST
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.CAMPAIGN_LIST,
                    dataTransform: subscribeToCampaign(campaignId)
                })
            )
        },
        notifySimpleError(`Failed to resubscribe to campaign "${name}"`)
    )
}

export function* pinCampaign(action) {
    const { campaignId, name } = action.payload
    yield* tryFetch(
        {
            url: `/api/campaigns/${campaignId}/pin`,
            method: ApiTypes.POST
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.CAMPAIGN_LIST,
                    dataTransform: pinCampaignInList(campaignId)
                })
            )
        },
        notifySimpleError(`Failed to pin campaign "${name}"`)
    )
    yield put(pinUnpinCampaignComplete(campaignId))
}

export function* unpinCampaign(action) {
    const { campaignId, name } = action.payload
    yield* tryFetch(
        {
            url: `/api/campaigns/${campaignId}/unpin`,
            method: ApiTypes.POST
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.FAVORITE_CAMPAIGNS_NAME,
                    dataTransform: unpinCampaignFromTopCampaigns(campaignId)
                })
            )
            yield put(
                modifyResource({
                    name: C.CAMPAIGN_LIST,
                    dataTransform: unpinCampaignInList(campaignId)
                })
            )
        },
        notifySimpleError(`Failed to unpin campaign "${name}"`)
    )
    yield put(pinUnpinCampaignComplete(campaignId))
}

function* setCampaignPatient({ campaignId, patientId }, isMember) {
    yield* tryFetch(
        {
            url: `/api/campaigns/${campaignId}/patient`,
            method: ApiTypes.PUT,
            body: { patientId, member: isMember }
        },
        function*() {
            yield put(
                modifyResource({
                    name: C.PATIENT_CAMPAIGNS_NAME,
                    dataTransform: data => ({
                        campaigns: findAndUpdate(
                            data.campaigns,
                            {
                                [C.TABLE_KEYS.MEMBERSHIP]: isMember,
                                [C.TABLE_KEYS.MANUALLY_ADDED]: isMember
                            },
                            C.TABLE_KEYS.CAMPAIGN_ID,
                            campaignId
                        )
                    })
                })
            )
        },
        notifySimpleError(
            isMember
                ? "Could not add patient to campaign."
                : "Could not remove add patient from campaign."
        )
    )
}

export function* addCampaignPatient(action) {
    yield* setCampaignPatient(action.payload, true)
}
export function* removeCampaignPatient(action) {
    yield* setCampaignPatient(action.payload, false)
}

export function* setCampaignFilters({ payload }) {
    yield put(setPatientFilter(payload))
    yield put(hideEdit())
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.CREATE_NEW_CAMPAIGN, createCampaign)
        }),
        call(function*() {
            yield takeEvery(C.SAVE_CAMPAIGN_EDITS, saveCampaignEdits)
        }),
        call(function*() {
            yield takeEvery(
                C.EXPORT_CAMPAIGN_PATIENT_LIST,
                exportCampaignPatientList
            )
        }),
        call(function*() {
            yield takeEvery(
                C.PRINT_CAMPAIGN_PATIENT_LIST,
                printCampaignPatientList
            )
        }),
        call(function*() {
            yield takeEvery(C.DELETE_CAMPAIGN, deleteCampaign)
        }),
        call(function*() {
            yield takeEvery(C.UNSUBSCRIBE_FROM_CAMPAIGN, unsubscribeCampaign)
        }),
        call(function*() {
            yield takeEvery(C.RESUBSCRIBE_TO_CAMPAIGN, resubscribeToCampaign)
        }),
        call(function*() {
            yield takeEvery(C.PIN_CAMPAIGN, pinCampaign)
        }),
        call(function*() {
            yield takeEvery(C.UNPIN_CAMPAIGN, unpinCampaign)
        }),
        call(function*() {
            yield takeEvery(C.ADD_CAMPAIGN_PATIENT, addCampaignPatient)
        }),
        call(function*() {
            yield takeEvery(C.REMOVE_CAMPAIGN_PATIENT, removeCampaignPatient)
        }),
        call(function*() {
            yield takeEvery(C.SET_CAMPAIGN_FILTERS, setCampaignFilters)
        })
    ])
}
