import React from "react"
import { Button, Modal } from "react-bootstrap"
import _ from "lodash"
import FormModal from "../../core/FormModal"

export const FiltersModal = ({
    children,
    className,
    closeModal,
    openNameModal,
    resetForm,
    errors,
    saving,
    show,
    size,
    submit,
    title,
    isFavorite
}) => {
    const disabled = saving || !_.isEmpty(errors)
    return (
        <FormModal
            show={show}
            onHide={closeModal}
            className={className}
            size={size}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body as="form" onSubmit={submit}>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    variant="outline-danger"
                    onClick={resetForm}
                    style={{ marginRight: "auto" }}
                >
                    Clear Filters
                </Button>
                {isFavorite ? (
                    <Button
                        variant="primary"
                        disabled={disabled}
                        onClick={submit}
                    >
                        Save Filters
                    </Button>
                ) : (
                    <>
                        <Button
                            variant="outline-primary"
                            disabled={disabled}
                            onClick={openNameModal}
                        >
                            Save Favorite
                        </Button>
                        <Button
                            variant="primary"
                            disabled={disabled}
                            onClick={submit}
                        >
                            Update Filters
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </FormModal>
    )
}
