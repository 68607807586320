import React, { PureComponent } from "react"
import ReactTable from "react-table"
import { Field } from "redux-form"
import _ from "lodash"

import { ValidationError } from "../../../../core/ValidationError"
import { CheckboxField } from "../../../../core/CustomFields"
import LEHOC from "../../../../core/LoadingErrorHOC"
import { TableDescription } from "../../../../table"
import { addOrRemove } from "../../../../utils"
import { EDIT_USER_PRACTICE_COLUMNS } from "../../../columns"
import { isUserInPractice } from "../../../helpers"
import * as C from "../../../constants"
import SearchBar from "../../../../core/SearchBar"

class UserPracticePage extends PureComponent {
    state = { search: undefined }

    toggleVisible = practiceUnitId => {
        const { selectedUser = {}, touch, change } = this.props

        const userUnits = _.uniq(selectedUser[C.USER_FIELDS.USER_UNITS])
        const adminUnits = _.uniq(selectedUser[C.USER_FIELDS.ADMIN_UNITS]) // not really expecting duplicates, but _.uniq() also makes us a new array to work with
        let primaryPractice = selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE]

        if (userUnits.includes(practiceUnitId)) {
            _.pull(userUnits, practiceUnitId)
            _.pull(adminUnits, practiceUnitId)

            if (primaryPractice === practiceUnitId) {
                primaryPractice = 0
            }
        } else {
            userUnits.push(practiceUnitId)

            if (!primaryPractice) {
                // no primary practice was set, so this can be the one
                primaryPractice = practiceUnitId
            }
        }

        change(C.USER_FIELDS.ADMIN_UNITS, adminUnits)
        change(C.USER_FIELDS.USER_UNITS, userUnits)
        change(C.USER_FIELDS.PRIMARY_PRACTICE, primaryPractice)
        touch(C.USER_FIELDS.ADMIN_UNITS)
        touch(C.USER_FIELDS.USER_UNITS)
        touch(C.USER_FIELDS.PRIMARY_PRACTICE)
    }

    toggleAdmin = practiceUnitId => {
        const { selectedUser = {}, touch, change } = this.props
        const adminUnits = selectedUser[C.USER_FIELDS.ADMIN_UNITS]
        const newUnits = addOrRemove(adminUnits, practiceUnitId)
        change(C.USER_FIELDS.ADMIN_UNITS, newUnits)
        touch(C.USER_FIELDS.ADMIN_UNITS)
    }

    setPrimary = primaryPractice => {
        this.props.change(C.USER_FIELDS.PRIMARY_PRACTICE, primaryPractice)
        this.props.touch(C.USER_FIELDS.PRIMARY_PRACTICE)
    }

    // resolve data //
    getExtraRowData = () => {
        const {
            toggleVisible,
            toggleAdmin,
            setPrimary,
            props: { editingSuperuser, selectedUser = {}, initialValues = {} }
        } = this

        return {
            toggleVisible,
            toggleAdmin,
            setPrimary,
            editingSuperuser,
            [C.USER_FIELDS.USER_UNITS]:
                selectedUser[C.USER_FIELDS.USER_UNITS] || [],
            [C.USER_FIELDS.ADMIN_UNITS]:
                selectedUser[C.USER_FIELDS.ADMIN_UNITS] || [],
            [C.USER_FIELDS.PRIMARY_PRACTICE]:
                selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE],

            // we include these for sorting purposes
            originalUserUnits: initialValues[C.USER_FIELDS.USER_UNITS] || [],
            originalAdminUnits: initialValues[C.USER_FIELDS.ADMIN_UNITS] || [],
            originalPrimaryPractice:
                initialValues[C.USER_FIELDS.PRIMARY_PRACTICE]
        }
    }

    getActivePractices = () => {
        const search = _.lowerCase(this.state.search)
        const filtered = _.filter(
            this.props.practices,
            practice =>
                !search ||
                _.lowerCase(
                    practice[C.USER_PRACTICE_KEYS.PRACTICE_NAME]
                ).includes(search)
        )

        const extraRowData = this.getExtraRowData()
        return _.map(filtered, practice => ({
            ...practice,
            ...extraRowData,

            // locks that can't be applied in advance so easily, because they depend on form values
            // TODO well actually we totally could put this in a selector, but we haven't yet
            lockPrimaryPractice:
                practice.lockPrimaryPractice ??
                (isUserInPractice(this.props.selectedUser, practice)
                    ? undefined
                    : "The user is not in this practice."),
            lockAdmin: this.props.editingSuperuser
                ? "Superusers can always administrate all practices."
                : isUserInPractice(this.props.selectedUser, practice)
                ? undefined
                : "The user is not in this practice."
        }))
    }

    onSearch = search => {
        this.setState({ search })
    }

    render() {
        const { practices, loading, initialValues = {} } = this.props
        const isLoading =
            loading ||
            practices.length === 0 ||
            initialValues[C.USER_FIELDS.USER_UNITS] === null
        const disabled = !this.props.selectedUser[C.USER_FIELDS.NPI]

        // we can't add the extra fields in the selector because sorting happens before data resolution
        const data = this.getActivePractices()

        return (
            <div className="admin-edit-practices">
                <TableDescription
                    tableName={C.EDIT_USER_PRACTICES}
                    Component="p"
                />
                <SearchBar onChange={this.onSearch} cosmeticButton />
                <LEHOC loading={isLoading}>
                    <ReactTable
                        data={data}
                        noDataText="No practice units found"
                        columns={EDIT_USER_PRACTICE_COLUMNS}
                        defaultSorted={[
                            {
                                id: C.EDIT_USER_PRACTICE_KEYS.ADMIN,
                                desc: true
                            },
                            {
                                id: C.EDIT_USER_PRACTICE_KEYS.VISIBLE,
                                desc: true
                            },
                            {
                                id: C.EDIT_USER_PRACTICE_KEYS.PRACTICE_NAME,
                                desc: false
                            }
                        ]}
                        pageSize={data.length}
                        showPagination={false}
                    />
                </LEHOC>
                {disabled || (
                    <Field
                        name={C.USER_FIELDS.IS_DEFAULT}
                        component={CheckboxField}
                        label="Make user's panel visible by default in their primary practice"
                        labelRight
                        className="admin-set_panel_default"
                        /*onChange={() => {
                            this.props.touch(C.USER_FIELDS.HANDLERS)
                        }}*/
                    />
                )}
                <Field
                    name={C.USER_FIELDS.USER_UNITS}
                    component={ValidationError}
                />
                <Field
                    name={C.USER_FIELDS.PRIMARY_PRACTICE}
                    component={ValidationError}
                />
            </div>
        )
    }
}

export default UserPracticePage
