import React from "react"
import _ from "lodash"

const GUIDE_MEASURE_DESCRIPTIONS =
    "https://guide.healthfocus.io/measure_descriptions.html"

export const MeasureInfoLink = ({ link }) => {
    if (!link) return null

    const href = _.startsWith(link, "http")
        ? link
        : `${GUIDE_MEASURE_DESCRIPTIONS}#${_.trimStart(link, "#")}`

    return (
        <a className="guide-link-measure" href={href} target="_blank">
            <span className="fa indicator-info" />
        </a>
    )
}
