import {
    FILTER_TYPES,
    FILTERS,
    POPULATION_VALUES
} from "../patient_list/constants"
import { LOCAL } from "../constants"
import * as PLC from "../patient_list/constants"
import * as CC from "../constants"

export const NAME = "CARE_MANAGEMENT"
export const PATIENTS = "CARE_MANAGEMENT_PATIENTS"
export const CM_PATIENT_COUNT = "CARE_MANAGEMENT_PATIENT_COUNT"
export const CM_PRODUCTS_CHART = "CM_PRODUCTS_CHART"

export const SET_CM_MEASURE = "SET_CM_MEASURE"
export const SET_CM_PATIENT_FILTERS = "SET_CM_PATIENT_FILTERS"
export const EXPORT_CM_PATIENT_LIST = "EXPORT_CM_PATIENT_LIST"
export const PRINT_CM_PATIENT_LIST = "PRINT_CM_PATIENT_LIST"

export const OPTIONS = {
    ELIGIBLE: "ELIGIBLE",
    PARTIAL: "PARTIAL",
    COMPLIANT: "COMPLIANT"
}

export const OPTION_FILTERS = {
    [OPTIONS.ELIGIBLE]: {
        [PLC.FILTERS.COMPLIANT]: undefined,
        [PLC.FILTERS.LAST_SERVICE_FROM]: undefined
    },
    [OPTIONS.PARTIAL]: {
        [PLC.FILTERS.COMPLIANT]: CC.COMPLIANCE.NOT_COMPLIANT
    },
    [OPTIONS.COMPLIANT]: {
        [PLC.FILTERS.COMPLIANT]: CC.COMPLIANCE.COMPLIANT
    }
}

export const DEFAULT_FILTERS = {
    [FILTERS.TYPE]: FILTER_TYPES.PATIENT_MEASURES,
    [FILTERS.POPULATION]: POPULATION_VALUES.ALL,
    [FILTERS.SCOPE]: LOCAL
}
