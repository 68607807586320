import React from "react"
import { Col, Row } from "react-bootstrap"
import { Field, reduxForm } from "redux-form"
import {
    CreatableSelectField,
    SelectField,
    LabelledDatePicker,
    PhoneNumber,
    TextInput
} from "../../core/CustomFields"
import {
    fixedDigits,
    localizeValidator,
    maxLength,
    mustBeANumber,
    required,
    todayOrBefore,
    validDate,
    validEmail,
    validPhoneNumber
} from "../../core/validators"
import * as C from "../constants"
import { AddPatientFieldset } from "./AddPatientFieldset"

const validateCareManager = localizeValidator((value, allValues) =>
    !value &&
    (allValues[C.ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_EFF_DT] ||
        allValues[C.ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_TERM_DT])
        ? "is required if a date is specified"
        : undefined
)

const AddPatientForm = ({
    options: {
        races,
        ethnicities,
        providers,
        sexes,
        genders,
        orientations,
        users
    }
}) => (
    <form className="add_patient">
        <Row>
            <Col md={12} lg={9} className="add_patient-group">
                <AddPatientFieldset
                    name="Basic Info"
                    colCount={3}
                    className="add_patient-valign"
                >
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.FIRST_NAME}
                        component={TextInput}
                        validate={required}
                        required
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.MIDDLE_NAME}
                        component={TextInput}
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.LAST_NAME}
                        component={TextInput}
                        validate={required}
                        required
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.DATE_OF_BIRTH}
                        component={LabelledDatePicker}
                        validDates={todayOrBefore}
                        validate={[required, validDate, todayOrBefore]}
                        // validDates is for the datepicker; validate is for the form.
                        required
                    />

                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.RACE}
                        component={SelectField}
                        options={races}
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.ETHNICITY}
                        component={SelectField}
                        options={ethnicities}
                    />
                </AddPatientFieldset>

                <AddPatientFieldset
                    name="Gender and Sexuality"
                    colCount={3}
                    className="add_patient-valign"
                >
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.SEX}
                        component={SelectField}
                        options={sexes}
                        validate={required}
                        required
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.GENDER}
                        component={CreatableSelectField}
                        options={genders}
                        validate={required}
                        required
                        multi
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.ORIENTATION}
                        component={CreatableSelectField}
                        options={orientations}
                        validate={required}
                        required
                        multi
                    />
                </AddPatientFieldset>
            </Col>

            <Col md={12} lg={3}>
                <AddPatientFieldset name="Providers" colCount={1}>
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.PCP}
                        component={SelectField}
                        options={providers}
                        validate={required}
                        required
                        notClearable
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_ID}
                        component={SelectField}
                        options={users}
                        validate={validateCareManager}
                        autoUpdate
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_EFF_DT}
                        component={LabelledDatePicker}
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.CARE_MANAGER_TERM_DT}
                        component={LabelledDatePicker}
                    />
                </AddPatientFieldset>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <AddPatientFieldset name="Contact" colCount={4}>
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.ADDRESS_1}
                        component={TextInput}
                        validate={[required, maxLength(128)]}
                        required
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.ADDRESS_2}
                        component={TextInput}
                        validate={maxLength(128)}
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.CITY}
                        component={TextInput}
                        validate={[required, maxLength(64)]}
                        required
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.STATE}
                        component={SelectField}
                        options={C.STATE_OPTIONS}
                        validate={required}
                        required
                        notClearable
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.COUNTRY}
                        component={SelectField}
                        options={C.COUNTRY_OPTIONS}
                        validate={required}
                        required
                        notClearable
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.ZIP}
                        component={TextInput}
                        validate={[required, fixedDigits(5), mustBeANumber]}
                        required
                    />

                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.PHONE}
                        component={PhoneNumber}
                        validate={validPhoneNumber}
                    />
                    <Field
                        name={C.ADD_NEW_PATIENT_FIELDS.EMAIL}
                        component={TextInput}
                        validate={validEmail}
                    />
                </AddPatientFieldset>
            </Col>
        </Row>
    </form>
)

export default reduxForm({
    form: C.ADD_NEW_PATIENT_FORM,
    initialValues: {
        [C.ADD_NEW_PATIENT_FIELDS.COUNTRY]: C.COUNTRY_OPTIONS[0].value
    },
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false
})(AddPatientForm)
