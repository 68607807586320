import React, { Component } from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import _ from "lodash"
import { CategoryFilter } from "../cost/components/CategoryFilter"
import { PERIOD_DISPLAY } from "../pickers/constants"
import * as PLC from "../patient_list/constants"
import * as AC from "../awv/constants"
import * as CC from "../cost/constants"
import * as C from "../constants"
import TRANSLATION from "../translation"
import { getFacilitiesByIds } from "../transformFilter"
import { displayCosts, formatDollars } from "../numbers"
import { callOrLookup, valueLabelToObj } from "../utils"
import { displayMonths, MONTH_STRING } from "../dates"
import { getMetaData, getResourceData } from "./fetcher"

const SEPARATOR = "; "

// using an HOC instead of a regular component because the regular component ends up very repetitive to define
const FilterListItemHOC = (key, children, translation) => (
    <div key={key}>
        <dt>{callOrLookup(key, translation) || key}:</dt>
        <dd>{children}</dd>
    </div>
)

const printObject = objectLabel =>
    _.entries(objectLabel)
        .map(([k, v]) => k + " " + v)
        .join(", ")

const inactiveFilter = filter =>
    _.isNil(filter) || (_.isArrayLike(filter) && _.isEmpty(filter))

export class FilterHeader extends Component {
    getFilters = () =>
        _.keys(this.props.filters)
            .filter(key => !inactiveFilter(this.props.filters[key]))
            .sort(
                key => (key === CC.FILTERS.CATEGORIES ? 1 : -1) // the categories filter has to go last
            )
            .map(this.getFilter)

    getFilter = key => {
        const { lookup, filters, filterOptions, form } = this.props
        const filterValue = filters[key]
        const translation =
            this.props.translation ||
            TRANSLATION[form] ||
            PLC.FILTER_TRANSLATION

        if (
            [
                PLC.FILTERS.SEARCH_CONTRACT_NUMBER,
                PLC.FILTERS.SEARCH_PATIENT_ID
            ].includes(key)
        ) {
            // these are displayed as part of the fuzzy search
            return undefined
        }

        if (C.BOOLEAN_VALUES.includes(filterValue)) {
            return FilterListItemHOC(
                key,
                C.BOOLEAN_LABELS[filterValue],
                translation
            )
        }

        switch (key) {
            case PLC.FILTERS.SPECIALISTS: // specialists are selected from the list of providers
                return FilterListItemHOC(
                    key,
                    filterValue
                        .map(item => lookup(PLC.FILTERS.PROVIDERS)[item])
                        .join(SEPARATOR),
                    translation
                )
            case CC.FILTERS.PRACTICE_UNITS: // some forms use "practices" instead of "units" for some reason
                return FilterListItemHOC(
                    key,
                    filterValue
                        .map(item => lookup(PLC.FILTERS.PRACTICE_UNITS)[item])
                        .join(SEPARATOR),
                    translation
                )

            case CC.FILTERS.FACILITY:
                return FilterListItemHOC(
                    key,
                    getFacilitiesByIds(filterOptions, filterValue)
                        .map(({ label }) => label)
                        .join(SEPARATOR),
                    translation
                )

            case PLC.FILTERS.COMPLIANT:
            case PLC.FILTERS.HCC_CONDITIONS_MET:
                return FilterListItemHOC(
                    key,
                    lookup(PLC.FILTERS.COMPLIANT)[filterValue],
                    translation
                )

            case PLC.FILTERS.ACUTE_ENCOUNTERS:
                return FilterListItemHOC(
                    key,
                    filterValue
                        .map(item => item.slice(0, 2).toUpperCase())
                        .join(SEPARATOR),
                    translation
                )

            case PLC.FILTERS.FUZZY_SEARCH:
                return FilterListItemHOC(key, `"${filterValue}"`, translation)

            case PLC.FILTERS.NEXT_APPT_DATE_DYNAMIC:
                return FilterListItemHOC(
                    key,
                    PLC.DYNAMIC_DATES[filterValue],
                    translation
                )

            case PLC.FILTERS.POPULATION:
                return FilterListItemHOC(
                    key,
                    C.POPULATION_LABELS_SHORT[filterValue],
                    translation
                )

            case PLC.FILTERS.SCOPE:
                return FilterListItemHOC(
                    key,
                    C.SCOPE_LABELS[filters[PLC.FILTERS.SCOPE]] ||
                        "Unknown Scope",
                    translation
                )

            case PLC.FILTERS.CARE_MANAGEMENT:
            case PLC.FILTERS.CM_INCENTIVE:
            case PLC.FILTERS.DECEASED:
                return FilterListItemHOC(
                    key,
                    C.YES_NO_LABELS[filterValue],
                    translation
                )

            case CC.FILTERS.CATEGORIES:
                return (
                    <CategoryFilter
                        key={key}
                        filter={filterValue}
                        label={translation[key]}
                        lookup={lookup}
                    />
                )

            case CC.FILTERS.PERIOD:
                if (
                    filters[CC.FILTERS.START_DATE] ||
                    filters[CC.FILTERS.END_DATE]
                ) {
                    return undefined // they'll display on their own
                }
                return FilterListItemHOC(
                    CC.FILTERS.PERIOD,
                    PERIOD_DISPLAY[filterValue],
                    translation
                )

            case PLC.FILTERS.COST_SPAN:
                if (
                    filters[PLC.FILTERS.COST_MIN] ||
                    filters[PLC.FILTERS.COST_MAX]
                ) {
                    return FilterListItemHOC(
                        PLC.FILTERS.COST_SPAN,
                        PERIOD_DISPLAY[filterValue],
                        translation
                    )
                }
                return undefined

            case PLC.FILTERS.COST_MIN:
                return filters[PLC.FILTERS.COST_MAX]
                    ? FilterListItemHOC(
                          PLC.FILTERS.COST,
                          displayCosts(
                              filters[PLC.FILTERS.COST_MIN],
                              filters[PLC.FILTERS.COST_MAX]
                          ),
                          translation
                      )
                    : FilterListItemHOC(
                          PLC.FILTERS.COST,
                          `${formatDollars(filterValue)} or more`,
                          translation
                      )

            case PLC.FILTERS.COST_MAX:
                return filters[PLC.FILTERS.COST_MIN]
                    ? undefined // already displayed by COST_MIN
                    : FilterListItemHOC(
                          PLC.FILTERS.COST,
                          `${formatDollars(filterValue)} or less`,
                          translation
                      )

            case CC.FILTERS.START_DATE:
                if (filters[CC.FILTERS.END_DATE]) {
                    return FilterListItemHOC(
                        CC.FILTERS.PERIOD,
                        displayMonths(
                            filters[CC.FILTERS.START_DATE],
                            filters[CC.FILTERS.END_DATE]
                        ),
                        translation
                    )
                }
                return FilterListItemHOC(
                    CC.FILTERS.START_DATE,
                    filters[CC.FILTERS.START_DATE].format(MONTH_STRING),
                    translation
                )

            case CC.FILTERS.END_DATE:
                return filters[CC.FILTERS.START_DATE]
                    ? undefined // already displayed by START_DATE
                    : FilterListItemHOC(
                          CC.FILTERS.END_DATE,
                          filters[CC.FILTERS.END_DATE].format(MONTH_STRING),
                          translation
                      )

            case PLC.FILTERS.INCENTIVE_STATUS:
                return FilterListItemHOC(
                    PLC.FILTERS.INCENTIVE_STATUS,
                    filterValue
                        .map(item => PLC.INCENTIVE_STATUS_LABELS[item])
                        .join(SEPARATOR),
                    translation
                )

            case PLC.FILTERS.APPOINTMENT_PROVIDERS:
                return FilterListItemHOC(
                    key,
                    this.props.lookup(PLC.FILTERS.PROVIDERS)[filterValue],
                    translation
                )

            case AC.FILTERS.FLAGS:
                return FilterListItemHOC(
                    key,
                    filterValue
                        .map(item => AC.FLAG_LABELS[item])
                        .join(SEPARATOR),
                    translation
                )

            case PLC.FILTERS.TYPE:
                return undefined

            default:
                return FilterListItemHOC(
                    key,
                    this.getFilterLabel(key),
                    translation
                )
        }
    }

    getFilterLabel(key) {
        const filterLabel = this.getDefaultFilterDisplay(key)

        if (_.isArray(filterLabel)) {
            // multivalue field
            return filterLabel.join(SEPARATOR)
        }
        if (_.isObjectLike(filterLabel)) {
            // some kind of nested field
            return printObject(filterLabel)
        }
        return filterLabel.toString()
    }

    getDefaultFilterDisplay = key => {
        const filterValue = this.props.filters[key]
        const filterOptionsMap = this.props.lookup(key)

        if (_.isEmpty(filterOptionsMap)) {
            return filterValue
        }
        if (_.isArray(filterValue)) {
            return filterValue.map(fv => filterOptionsMap[fv])
        }
        return filterOptionsMap[filterValue]
    }

    noActiveFilters = () =>
        _.isEmpty(this.props.filters) ||
        _.values(this.props.filters).every(inactiveFilter)

    render() {
        const { style, meta, className } = this.props
        const filters = meta.loading ? (
            "Loading..."
        ) : (
            <dl>{this.getFilters()}</dl>
        )
        return (
            <div
                className={classNames("filter-status", className, {
                    "filters-none-active": this.noActiveFilters()
                })}
                style={style}
            >
                {filters}
            </div>
        )
    }
}

export default connect((state, { lookup, lookupKeys, filters }) => ({
    filters: filters ?? {},
    meta: getMetaData(C.FILTER_OPTIONS)(state),
    filterOptions: getResourceData(C.FILTER_OPTIONS)(state),
    lookup: key =>
        (lookup && callOrLookup(key, lookup)) ||
        valueLabelToObj(
            getResourceData(C.FILTER_OPTIONS)(state)[lookupKeys?.[key] ?? key]
        )
    // If a 'lookup' prop was passed into this, it should be either
    // a function of the form (key) => ({[value]: "label", ...}), or
    // an object of the form {[key]: {[value]: "label", ...), ...}
}))(FilterHeader)
