import React, { useMemo, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { Field, reduxForm } from "redux-form"
import { connect } from "react-redux"
import moment from "moment"
import {
    ColorField,
    LabelledDateTimePicker,
    SelectField,
    TextInput
} from "../../core/CustomFields"
import { afterDate, required, validDateInFormat } from "../../core/validators"
import FormModal from "../../core/FormModal"
import { TIMESTAMP_STRING_FIELD } from "../../dates"
import { easyFormValueSelector } from "../../selectorUtils"
import * as C from "../constants"

export const AddAlertModal = ({
    invalid,
    show,
    onHide,
    handleSubmit,
    startDate
}) => {
    // set the current time when we mount the modal, or else a timepicker set to "now" will show as in the past
    const [today] = useState(moment().startOf("minute"))

    const startDateValidator = useMemo(
        () => [
            required,
            validDateInFormat(TIMESTAMP_STRING_FIELD),
            afterDate(today, {
                dateFormat: TIMESTAMP_STRING_FIELD,
                exactTime: true
            })
        ],
        []
    )
    const endDateValidator = useMemo(
        () => [
            required,
            validDateInFormat(TIMESTAMP_STRING_FIELD),
            afterDate(today, {
                dateFormat: TIMESTAMP_STRING_FIELD,
                exactTime: true
            }),
            afterDate(startDate, {
                dateFormat: TIMESTAMP_STRING_FIELD,
                exactTime: true,
                errorMessage: `must not be before ${
                    C.TRANSLATION[C.FILTERS.START_DATE]
                }`
            })
        ],
        [startDate]
    )

    return (
        <FormModal show={show} onHide={onHide}>
            <Modal.Header closeButton>Create New Alert</Modal.Header>
            <Modal.Body as="form">
                <Row>
                    <Col md={6}>
                        <Field
                            name={C.FILTERS.TYPE}
                            component={SelectField}
                            options={C.ALERT_OPTIONS}
                            required
                            notClearable
                        />
                    </Col>
                    <Col md={6}>
                        <Field
                            name={C.FILTERS.COLOR}
                            component={ColorField}
                            colorOptions={C.ALERT_COLORS}
                            required
                            validate={required}
                        />
                    </Col>
                    <Col xs={12}>
                        <Field
                            name={C.FILTERS.MESSAGE}
                            component={TextInput}
                            required
                            validate={required}
                        />
                    </Col>
                    <Col md={6}>
                        <Field
                            name={C.FILTERS.START_DATE}
                            component={LabelledDateTimePicker}
                            required
                            validDates={startDateValidator}
                            validate={startDateValidator}
                        />
                    </Col>
                    <Col md={6}>
                        <Field
                            name={C.FILTERS.END_DATE}
                            component={LabelledDateTimePicker}
                            required
                            validDates={endDateValidator}
                            validate={endDateValidator}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="pull-left"
                    onClick={onHide}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    className="pull-right"
                    disabled={invalid}
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </FormModal>
    )
}

const startDateSelector = easyFormValueSelector(
    C.ADD_ALERT_FORM,
    C.FILTERS.START_DATE
)

export default reduxForm({
    form: C.ADD_ALERT_FORM
})(
    connect(state => ({
        startDate: startDateSelector(state)
    }))(AddAlertModal)
)
