import React from "react"
import { SortDirection } from "react-virtualized"
import { Card } from "react-bootstrap"
import _ from "lodash"
import CardTitle from "../../core/CardTitle"
import LEHOC from "../../core/LoadingErrorHOC"
import { PrintPocButton } from "../../batch_print/components"
import * as BC from "../../batch_print/constants"
import { tableFactory } from "../../table"
import { TABLE_COLUMNS } from "../columns"
import * as C from "../constants"
import { POC_PRINT_MODAL_FORM } from "../../batch_print/constants"

const { Table, Settings, Description } = tableFactory({
    name: C.NAME,
    columns: TABLE_COLUMNS,
    sortBy: [C.TABLE_KEYS.PRINT_DATE],
    sortDirection: [SortDirection.DESC]
})

export default ({
    loading,
    error,
    allowPrint,
    showPocPrint,
    reset,
    change,
    data: { letters }
}) => (
    <Card className="card-table poc-letters">
        <Card.Header className="card-header-group">
            <CardTitle>
                Reminder Letter Details
                {_.isEmpty(letters) ? " - No Data" : ""}
            </CardTitle>
            {allowPrint && (
                <PrintPocButton
                    title="Print New Letter"
                    onClick={() => {
                        reset(BC.POC_PRINT_MODAL_FORM)
                        change(
                            BC.POC_PRINT_MODAL_FORM,
                            BC.PRINT_FIELDS.LETTER,
                            true
                        )
                        showPocPrint()
                    }}
                />
            )}
            <Settings className="poc-letter-settings" />
        </Card.Header>
        <Description />
        <Card.Body>
            <LEHOC loading={loading} error={error} list={letters}>
                <Table list={letters} />
            </LEHOC>
        </Card.Body>
    </Card>
)
