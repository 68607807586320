import React, { useCallback, useMemo, useState } from "react"
import { Modal, Button, Card } from "react-bootstrap"
import classNames from "classnames"
import _ from "lodash"
import FormModal from "../../core/FormModal"
import { tableFactory } from "../../table"
import * as C from "../constants"
import { FAVORITE_FILTER_COLUMNS } from "../columns"

const isSelectable = fave =>
    !fave[C.TABLE_KEYS.DELETED] && !fave[C.TABLE_KEYS.LOADING]

export const FavoriteFiltersModal = ({
    list,
    onAddNew,
    onHide,
    show,
    form,
    selectFavorite,
    tableData
}) => {
    const [selectedId, setSelectedId] = useState(null)

    const selected = _.find(list, { [C.TABLE_KEYS.ID]: selectedId }) || {}

    if (!isSelectable(selected)) {
        setSelectedId(null)
    }

    const { Table, Description } = useMemo(
        () =>
            tableFactory({
                name: `FAVORITE_FILTERS_${form}`,
                columns: FAVORITE_FILTER_COLUMNS,
                sortBy: [C.TABLE_KEYS.NAME]
            }),
        [form]
    )

    const rowRenderer = useCallback(
        ({ rowData, className }) => {
            const selectable = isSelectable(rowData)
            return {
                onClick: () =>
                    selectable && setSelectedId(rowData[C.TABLE_KEYS.ID]),
                className: classNames(className, {
                    selectable,
                    selected:
                        selectable && rowData[C.TABLE_KEYS.ID] === selectedId,
                    removed_row: rowData[C.TABLE_KEYS.DELETED]
                })
            }
        },
        [selectedId]
    )

    return (
        <FormModal
            show={show}
            size="lg"
            className="filter-favorites"
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>Favorite Filter Sets</Modal.Title>
            </Modal.Header>
            <Description />
            <Modal.Body>
                {_.isEmpty(list) || (
                    <Card className="card-table">
                        <Card.Body>
                            <Table
                                list={list}
                                rowRenderer={rowRenderer}
                                tableData={tableData}
                            />
                        </Card.Body>
                    </Card>
                )}
                <Button variant="outline-primary" onClick={onAddNew}>
                    <span className="fa fa-plus-circle" /> Create New Filter Set
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={onHide}
                    style={{ marginRight: "auto" }}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        selectFavorite(selected)
                        onHide()
                    }}
                    disabled={_.isEmpty(selected)}
                >
                    Use Filter Set
                </Button>
            </Modal.Footer>
        </FormModal>
    )
}
