import moment from "moment"
import _ from "lodash"
import { findAndUpdate, joinTruthy } from "../utils"
import * as C from "./constants"

const getDefaultSubcategory = measure => ({
    name: measure.subCategoryName,
    seqNo: measure.subCategorySeqNo,
    measures: [measure]
})

const subcategoryMapper = newMeasure => subCategory =>
    subCategory.name === newMeasure.subCategoryName
        ? {
              ...subCategory,
              measures: subCategory.measures.some(
                  measure => measure.id === newMeasure.id
              )
                  ? subCategory.measures // measure exists
                  : [...subCategory.measures, newMeasure] // does not exist yet
          }
        : subCategory // this subcategory didn't match

const categoryMapper = newMeasure => category =>
    category.name === newMeasure.categoryName
        ? {
              ...category,
              subCategories: category.subCategories.some(
                  subcategory => subcategory.name === newMeasure.subCategoryName
              )
                  ? // subcategory does exist
                    category.subCategories.map(subcategoryMapper(newMeasure))
                  : // subcategory does not already exist
                    [
                        ...category.subCategories,
                        getDefaultSubcategory(newMeasure)
                    ]
          }
        : category // this category didn't match

export const insertNewMeasure = (data, newMeasure) => {
    return {
        ...data,
        categories: data.categories.some(
            category => category.name === newMeasure.categoryName
        )
            ? // category does exist
              data.categories.map(categoryMapper(newMeasure))
            : // category does not already exist
              [
                  ...data.categories,
                  {
                      name: newMeasure.categoryName,
                      seqNo: newMeasure.categorySeqNo,
                      subCategories: [getDefaultSubcategory(newMeasure)]
                  }
              ]
    }
}

const measureNeedsUpdate = (measure, newMeasure) => {
    const hasNewServiceDate = moment
        .utc(measure.serviceDt)
        .isBefore(newMeasure.serviceDt)
    const hasNewLogDate =
        newMeasure.logDt > measure.logDt &&
        moment
            .utc(measure.serviceDt)
            .isSame(moment.utc(newMeasure.serviceDt), "day")
    return (
        measure.id.toString() === newMeasure.measureId.toString() &&
        (newMeasure.forceReplace ||
            hasNewServiceDate ||
            !measure.serviceDt ||
            hasNewLogDate)
    )
}

const findSubmeasureMatchIn = subMeasures => item =>
    subMeasures.find(subMeasure => subMeasure.id === Number(item.measureId)) ||
    {} // TODO this seems a little wrong. Shouldn't it be finding submeasure values rather than just submeasures?

const getCodeDesc = (measure, codeDescId) =>
    _.find(measure.dropListOptions, { id: Number(codeDescId) })?.name

const getDisplayValue = (oldItem, newItem) => {
    if (newItem.displayValue) {
        return newItem.displayValue
    }

    if (oldItem.dataType === C.MULTISELECT) {
        return (newItem.textVal || "")
            .split(";")
            .map(codeDescId => getCodeDesc(oldItem, codeDescId))
            .join("; ")
    }

    if (newItem.codeDescId) {
        // this is an enumeration
        return getCodeDesc(oldItem, newItem.codeDescId)
    }

    return joinTruthy(
        [newItem.numericVal || newItem.textVal, oldItem.unitLabel],
        " "
    )
}

const measureMapper = newMeasure => measure =>
    measureNeedsUpdate(measure, newMeasure)
        ? {
              ...measure,
              ...newMeasure,
              displayValue: getDisplayValue(measure, newMeasure),
              subMeasures: measure.subMeasures,
              subMeasureValues: newMeasure.subMeasures
                  ? newMeasure.subMeasures.map(
                        findSubmeasureMatchIn(measure.subMeasures)
                    )
                  : measure.subMeasureValues
          }
        : measure
export const updateLastEntry = (data, newMeasure) => {
    return {
        ...data,
        categories: data.categories.map(category => ({
            ...category,
            subCategories: category.subCategories.map(subCategory => ({
                ...subCategory,
                measures: subCategory.measures.map(measureMapper(newMeasure))
            }))
        }))
    }
}

export const updateMeasureHistory = (
    newHistoricalItem,
    dropListOptions,
    dataType
) => data => ({
    ...data,
    values: findAndUpdate(
        data.values,
        item => ({
            ...item,
            measureValueLogId: newHistoricalItem.measureValueLogId,
            numericVal: newHistoricalItem.numericVal,
            textVal: newHistoricalItem.textVal,
            displayValue: getDisplayValue(
                { dropListOptions, dataType, ...item }, // measure history doesn't include its own dropListOptions or dataType, so we have to add them here
                newHistoricalItem
            ),
            codeDescId: newHistoricalItem.codeDescId,
            logDt: newHistoricalItem.logDt,
            subMeasureValues: newHistoricalItem.subMeasureValues
        }),
        { measureValueId: newHistoricalItem.measureValueId }
    )
})
export const removeMeasureHistoricalItem = measureValueLogId => data => ({
    ...data,
    values: data.values.filter(
        item => item.measureValueLogId !== measureValueLogId
    )
})
export const updateMetrics = (data, metrics) => {
    const metricLookup = _.keyBy(metrics, metric => metric.measureId)

    const newCategories = data.categories.map(category => ({
        ...category,
        subCategories: category.subCategories.map(subCategory => ({
            ...subCategory,
            measures: subCategory.measures.map(measure => ({
                ...measure,
                compliant: (metricLookup[measure.id] || measure).compliant,
                nextDueDt: (metricLookup[measure.id] || measure).nextDueDt,
                serviceDt: (metricLookup[measure.id] || measure).serviceDt,
                displayValue: (metricLookup[measure.id] || measure).displayValue
            }))
        }))
    }))
    return {
        ...data,
        categories: newCategories
    }
}
