import React from "react"
import { Hoverlay } from "../../core/Hoverlay"
import { formatPercent } from "../../numbers"

export const RateBreakdown = ({
    rowData,
    columnData: { currentRateKey, goalRateKey, neededKey, metKey, eligibleKey }
}) => {
    const currentRate = rowData[currentRateKey]
    const targetRate = rowData[goalRateKey]
    const currentRateFormatted = formatPercent(rowData[currentRateKey])
    const targetRateFormatted = formatPercent(rowData[goalRateKey])

    const goal = rowData[neededKey] + rowData[metKey]

    return (
        <Hoverlay
            tooltip={
                <>
                    <span>{rowData[eligibleKey]} eligible patients</span>
                    <br />
                    <span>{goal} patients goal</span>
                    <br />
                    <span>{rowData[metKey]} patients met</span>
                    <br />
                    <span>{rowData[neededKey]} more patients to meet goal</span>
                </>
            }
        >
            <div className="bar-indicator">
                <div
                    className={`current_rate ${
                        currentRate >= targetRate ? "success" : ""
                    }`}
                    style={{ width: `${currentRate}%` }}
                >
                    <span>{currentRateFormatted}</span>
                </div>
                {targetRate > currentRate && (
                    <div
                        className="target_rate"
                        style={{
                            width: `calc(${targetRate - currentRate}% + 4px)`,
                            marginLeft: "-4px"
                        }}
                    >
                        <span>{targetRateFormatted}</span>
                    </div>
                )}
                <div
                    className="deadspace"
                    style={{ width: `${100 - targetRate}%` }}
                >
                    .
                </div>
            </div>
        </Hoverlay>
    )
}
