import React, { useEffect } from "react"
import { Card } from "react-bootstrap"
import { SortDirection } from "react-virtualized"

import LEHOC from "../../core/LoadingErrorHOC"
import { ExportIcon, PrintIcon } from "../../core/Icons"
import FilterHeader from "../../core/FilterHeader"
import CardTitle from "../../core/CardTitle"
import DashboardFilters from "../../dashboard_filters/components"
import { FiltersForm } from "../../qsr_dashboard/components/FiltersForm"
import { tableFactory } from "../../table"
import { EMPTY_OBJECT } from "../../utils"
import { ButtonGroup, constants as PC } from "../../pickers"
import { NETWORK_COLUMNS, PRACTICE_COLUMNS, PROVIDER_COLUMNS } from "../columns"
import { getTableName } from "../helpers"
import * as C from "../constants"

const createTable = (aggregation, columns) =>
    tableFactory({
        name: getTableName(aggregation),
        columns,
        sortBy: [C.AGGREGATION_MEASURES[aggregation]],
        sortDirection: [SortDirection.ASC]
    })

const VIEW_TABLES = {
    [PC.VIEWS.NETWORK]: createTable(PC.VIEWS.NETWORK, NETWORK_COLUMNS),
    [PC.VIEWS.PRACTICE]: createTable(PC.VIEWS.PRACTICE, PRACTICE_COLUMNS),
    [PC.VIEWS.PROVIDER]: createTable(PC.VIEWS.PROVIDER, PROVIDER_COLUMNS)
}
// they all have to be separate because if they shared one set of column settings, then saving any column structure in Network view would hide the Practice Unit column in Practice view.

const RiskDashboard = props => {
    const { Settings, Table, Description } =
        VIEW_TABLES[props.aggregation] ?? EMPTY_OBJECT

    return (
        <Card className="risk-dashboard dashboard-table card-table">
            <Card.Header>
                <div className="dashboard-table-controls">
                    <div className="dashboard-table-title">
                        <CardTitle>Risk Adjustment</CardTitle>
                        <DashboardFilters
                            {...props}
                            title="Edit Risk Filters"
                            form={C.RISK_FILTERS_FORM}
                            formComponent={FiltersForm}
                            initialValues={props.filters}
                            emptyValues={C.initialState.filters}
                            useConditions
                        />
                    </div>

                    <ButtonGroup
                        value={props.aggregation}
                        onClick={props.setAggregation}
                        options={PC.VIEW_OPTIONS}
                    />
                    <ExportIcon onClick={props.exportRisks} />
                    <PrintIcon onClick={props.printRisks} />
                    <Settings />
                </div>

                <FilterHeader filters={props.filters} />
            </Card.Header>
            <Description />
            <Card.Body>
                <LEHOC
                    loading={props.loading}
                    error={props.error}
                    list={props.data.risks}
                >
                    <Table list={props.data?.risks || []} />
                </LEHOC>
            </Card.Body>
        </Card>
    )
}

export default RiskDashboard
