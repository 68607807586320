import React from "react"
import { Form, FormGroup } from "react-bootstrap"
import { getValidProps } from "../../core/helpers"
import { SCOPE_OPTIONS } from "../../constants"

export const SearchScope = ({ input, meta }) => {
    const { onChange, value, name } = getValidProps(input, meta)
    return (
        <fieldset>
            <FormGroup className="nav-search-scope">
                <Form.Label as="legend">Scope:</Form.Label>
                {SCOPE_OPTIONS.map(({ value: scope, label }) => (
                    <Form.Check
                        inline
                        key={scope}
                        id={`nav-search-scope-${scope}`}
                    >
                        <Form.Check.Input
                            type="radio"
                            name={name}
                            onChange={() => onChange(scope)}
                            checked={value === scope}
                        />
                        <Form.Check.Label className="btn btn-default">
                            {label}
                        </Form.Check.Label>
                    </Form.Check>
                ))}
            </FormGroup>
        </fieldset>
    )
}
