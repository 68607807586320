import React from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import { Button, Modal } from "react-bootstrap"
import FormModal from "../../core/FormModal"
import fetcher from "../../core/fetcher"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import LocationUpdate from "./LocationUpdate"
import LocationList from "./LocationList"

const LocationsModal = ({
    communityLocations,
    mode,
    show,
    closeLocationModal,
    ...props
}) => (
    <FormModal
        dialogClassName="locations-modal"
        show={show}
        onHide={closeLocationModal}
        size="lg"
    >
        <Modal.Header closeButton>
            <Modal.Title>
                {mode === C.SELECT_MODE
                    ? "Select Location"
                    : mode === C.ADD_MODE
                    ? "Add Location"
                    : "Edit Location"}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {mode === C.SELECT_MODE ? (
                <LocationList
                    communityLocations={communityLocations}
                    {...props.communityLocationOptions}
                />
            ) : (
                <LocationUpdate {...props} />
            )}
        </Modal.Body>
        {mode === C.SELECT_MODE && (
            <Modal.Footer style={{ padding: 10 }}>
                <Button variant="secondary" onClick={closeLocationModal}>
                    Close
                </Button>
            </Modal.Footer>
        )}
    </FormModal>
)

export default fetcher({
    name: C.COMMUNITY_LOCATIONS,
    endpoint: "/api/locations",
    method: ApiTypes.GET
})(
    connect(
        (state, { data }) => ({
            ...state[C.LOCATION_MODAL],
            communityLocations: (data.communityLocations || []).map(loc => ({
                ...loc,
                allowSelect: state[C.LOCATION_MODAL].allowSelect
            })),
            communityLocationOptions: data.communityLocationOptions,
            locationFormValues: getFormValues(C.ADD_COMMUNITY_LOCATION_FORM)(
                state
            )
        }),
        actions
    )(LocationsModal)
)
