import React, { Component } from "react"
import _ from "lodash"
import ReactTable from "react-table"
import * as C from "../../../constants"
import { USER_PROVIDER_COLUMNS } from "../../../columns"

class PracticeUnit extends Component {
    allUserIds = () =>
        _.map(
            _.filter(this.props.users, user => !user.handlerLock),
            C.USER_TABLE_KEYS.USER_ID
        )
    allProviderIds = () =>
        _.map(
            _.filter(
                this.props.users,
                user => user[C.USER_TABLE_KEYS.NPI] && !user.providerLock
            ),
            C.USER_TABLE_KEYS.USER_ID
        )

    resolveData = data => {
        const {
            toggleProvider,
            toggleHandler,
            props: { selectedUser, selectedUserId, editingSuperuser }
        } = this
        return this.filterUsers(data).map(user => ({
            ...user,
            toggleProvider,
            toggleHandler,
            selectedUser,
            selectedUserId,
            editingSuperuser,
            isPracticeAdmin: this.userIsPracticeAdmin(user)
        }))
    }

    // only include non-providers if you have an NPI; otherwise they're useless.
    filterUsers = users =>
        this.props.selectedUser[C.USER_FIELDS.NPI]
            ? users || []
            : _.filter(users, user => user[C.USER_TABLE_KEYS.NPI])

    toggleProvider = userId => event =>
        event.target.checked
            ? this.props.onAdd(C.USER_FIELDS.PROVIDERS, [userId])
            : this.props.onRemove(C.USER_FIELDS.PROVIDERS, [userId])

    toggleHandler = userId => event => {
        if (event.target.checked) {
            this.props.onAdd(C.USER_FIELDS.HANDLERS, [userId])
            this.props.onRemove(C.USER_FIELDS.NOT_HANDLERS, [userId])
        } else {
            this.props.onRemove(C.USER_FIELDS.HANDLERS, [userId])
            this.props.onAdd(C.USER_FIELDS.NOT_HANDLERS, [userId])
        }
    }

    selectAllProviders = () => {
        this.props.onAdd(C.USER_FIELDS.PROVIDERS, this.allProviderIds())
    }
    unselectAllProviders = () => {
        this.props.onRemove(C.USER_FIELDS.PROVIDERS, this.allProviderIds())
    }
    selectAllHandlers = () => {
        this.props.onAdd(C.USER_FIELDS.HANDLERS, this.allUserIds())
    }
    unselectAllHandlers = () => {
        this.props.onRemove(C.USER_FIELDS.HANDLERS, this.allUserIds())
    }

    isPracticeAdmin = () =>
        _.includes(
            this.props.selectedUser[C.USER_FIELDS.ADMIN_UNITS],
            this.props.practiceUnit[C.PRACTICE_TABLE_KEYS.PRACTICE_ID]
        )

    isPrimaryPractice = () =>
        this.props.practiceUnit[C.PRACTICE_TABLE_KEYS.PRACTICE_ID] ===
        this.props.selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE]

    userIsPracticeAdmin = user =>
        _.find(user[C.USER_TABLE_KEYS.PRACTICES], {
            [C.USER_PRACTICE_KEYS.PRACTICE_ID]: this.props.practiceUnit[
                C.PRACTICE_TABLE_KEYS.PRACTICE_ID
            ]
        })?.[C.USER_PRACTICE_KEYS.IS_PRACTICE_ADMIN] ?? false

    getColumns = canManageUser =>
        USER_PROVIDER_COLUMNS(
            this.props.selectedUser,
            canManageUser,
            this.props.selectedUserId,
            this.props.practiceUnit[C.PRACTICE_TABLE_KEYS.PRACTICE_ID]
        )

    render() {
        const users = this.resolveData(this.props.users)
        const canManageUser = !!(
            this.props.selectedUser[C.USER_FIELDS.NPI] &&
            this.props.selectedUser[C.USER_FIELDS.PRIMARY_PRACTICE] ===
                this.props.practiceUnit[C.PRACTICE_TABLE_KEYS.PRACTICE_ID]
        )

        return (
            <div className="providers-practice_unit">
                <h4>
                    {
                        this.props.practiceUnit[
                            C.PRACTICE_TABLE_KEYS.PRACTICE_NAME
                        ]
                    }
                    <span className="primary-practice-flag">
                        {this.isPrimaryPractice() && ` (Primary Practice)`}
                    </span>
                </h4>
                {users.length > 0 && !this.props.editingSuperuser && (
                    <div className="providers-practice_unit-buttons">
                        <div>
                            <button
                                type="button"
                                onClick={this.selectAllProviders}
                            >
                                Select All Providers
                            </button>
                            |
                            <button
                                type="button"
                                onClick={this.unselectAllProviders}
                            >
                                Unselect All Providers
                            </button>
                        </div>
                        {canManageUser && (
                            <div>
                                <button
                                    type="button"
                                    onClick={this.selectAllHandlers}
                                >
                                    Select All Managers
                                </button>
                                |
                                <button
                                    type="button"
                                    onClick={this.unselectAllHandlers}
                                >
                                    Unselect All Managers
                                </button>
                            </div>
                        )}
                    </div>
                )}
                {users.length > 0 ? (
                    <ReactTable
                        data={users}
                        columns={this.getColumns(canManageUser)}
                        defaultSorted={
                            this.props.editingSuperuser ||
                            this.isPracticeAdmin()
                                ? C.PROVIDER_DEFAULT_SORT_ADMIN // XXX not sure I actually like changing the sort order like this each time admin status changes, but if I don't it's easy to miss a checkable user that's offscreen
                                : C.PROVIDER_DEFAULT_SORT
                        }
                        pageSize={users.length}
                        minRows={1}
                        showPagination={false}
                        style={{ marginBottom: 10 }}
                    />
                ) : (
                    <p>This practice has no providers.</p>
                )}
            </div>
        )
    }
}

export default PracticeUnit
