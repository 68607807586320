import React from "react"
import { Button, Modal } from "react-bootstrap"
import { reduxForm } from "redux-form"
import defaultShouldAsyncValidate from "redux-form/es/defaultShouldAsyncValidate"
import FormModal from "../../../core/FormModal"
import { GuideLink } from "../../../indicators"
import { asyncValidate } from "../../helpers"
import * as C from "../../constants"
import CampaignSettings from "../campaign_settings"

const NewCampaignSettings = reduxForm({
    form: C.NEW_CAMPAIGN_FORM,
    asyncChangeFields: [C.TABLE_KEYS.CAMPAIGN_NAME],
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true,
    new_campaign: true,
    shouldAsyncValidate: params => {
        // workaround for https://github.com/redux-form/redux-form/issues/3944
        // async validation doesn't trigger on first change
        return defaultShouldAsyncValidate({
            ...params,
            syncValidationPasses: true
        })
    },
    asyncValidate,
    ...C.FORM_ASYNC_PROPS
})(CampaignSettings)

export const CreateCampaignModal = ({
    formValues,
    initialValues,
    invalid,
    onHide,
    onSaveAndClose,
    onSaveAndView,
    show,
    authentication
}) => (
    <FormModal dialogClassName="large-modal" show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>
                Create Campaign
                <GuideLink page="create-a-campaign" className="white" />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <NewCampaignSettings
                initialValues={initialValues}
                formValues={formValues}
                authentication={authentication}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="secondary"
                className="btn-modal-close"
                onClick={onHide}
            >
                Close
            </Button>
            {invalid ? (
                <Button variant="primary" className="btn-modal-save" disabled>
                    Create
                </Button>
            ) : (
                <React.Fragment>
                    <Button
                        variant="primary"
                        className="btn-modal-save"
                        onClick={onSaveAndClose}
                    >
                        Create & Close
                    </Button>
                    <Button
                        variant="primary"
                        className="btn-modal-save"
                        onClick={onSaveAndView}
                    >
                        Create & Run
                    </Button>
                </React.Fragment>
            )}
        </Modal.Footer>
    </FormModal>
)
