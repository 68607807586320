import React, { useLayoutEffect, useRef } from "react"
import { Overlay, Popover } from "react-bootstrap"

// this lets the popover update its position once it's finished loading, since it will probably change size
const UpdatingPopover = React.forwardRef(
    ({ popper, children, ...props }, ref) => {
        useLayoutEffect(() => {
            // needs to be useLayoutEffect or we get a flicker from the popover resizing in one frame and repositioning in the next
            popper.scheduleUpdate()
        }, [children, popper])

        return (
            <Popover ref={ref} {...props}>
                {children}
            </Popover>
        )
    }
)

export const HistoryOverlay = ({
    children,
    disabled,
    id,
    onClick,
    onHide,
    show
}) => {
    const target = useRef(null)

    return (
        <>
            <button
                className="icon-button"
                onClick={onClick}
                disabled={disabled}
            >
                <i className="fa fa-list-ul" ref={target} />
            </button>
            <Overlay // not using Hoverlay because this may get passed some custom show/hide behaviour
                onHide={onHide}
                show={show}
                rootClose={true}
                target={target.current}
            >
                <UpdatingPopover id={`popover-trigger-click-root-close-${id}`}>
                    {children}
                </UpdatingPopover>
            </Overlay>
        </>
    )
}
