import { createSelector } from "reselect"
import * as C from "./constants"
import _ from "lodash"
import { getMetaData, getResourceData } from "../core/fetcher"
import { FILTER_OPTIONS } from "../constants"

const getCareManagersWithReasons = (careManagers, reasons) =>
    (careManagers || []).map(careManager => ({
        ...careManager,
        [C.KEYS.REASONS]: _.intersectionBy(
            reasons || [],
            careManager[C.KEYS.REASON_IDS],
            reason => reason.value || reason // one is a list of ids, the other is a list of label/value objects
        )
            .map(reason => reason.label)
            .join("; ")
    }))

export const careManagerSelector = createSelector(
    getResourceData(C.NAME, data => data.careManagers),
    getResourceData(FILTER_OPTIONS, data => data.reasons),
    getCareManagersWithReasons
)

export const initialValuesSelector = createSelector(
    getResourceData(C.NAME, data => data.careManagers),
    careManagers => careManagers
)

export const providersSelector = createSelector(
    getResourceData(C.NAME, data => data.careTeam),
    careTeam =>
        (careTeam || []).filter(item =>
            C.PROVIDER_RELATIONSHIPS.includes(item[C.KEYS.PAT_RELATIONSHIP])
        )
)

export const metadataSelector = createSelector(
    getMetaData(C.NAME),
    getMetaData(FILTER_OPTIONS),
    (careTeam, filterOptions) => ({
        loading:
            (careTeam && careTeam.loading) ||
            (filterOptions && filterOptions.loading),
        error:
            (careTeam && careTeam.error) ||
            (filterOptions && filterOptions.error)
    }) // don't want to finish loading until both the careTeam fetcher and the filterOptions fetcher are ready. We need the latter to put names to care manager ids.
)
