import moment from "moment"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import classNames from "classnames"
import _ from "lodash"
import patient_risk_rank_info from "../patient_risk_rank_info"
import { logoGetterSelector } from "../patient_list/selectors"
import * as PLC from "../patient_list/constants"
import * as C from "../patient_list/constants"
import * as TC from "../toc/constants"
import {
    dateFormat,
    denumerateMonth,
    MONTH_STRING,
    START_OF_TIME,
    timestampFormat
} from "../dates"
import {
    Deceased,
    GracePeriod,
    PayerIcon,
    YesNoIndicator,
    getComplianceAttributes,
    BasicIcon
} from "../indicators"
import { getNextTieredColumn } from "../table"
import { fallbackIfNil, phoneFormat } from "../utils"
import {
    formatDollarsCents,
    formatFractionalDollarsCents,
    formatFractionalNumber,
    formatLongNumber,
    formatPercent,
    formatPercentOffset,
    MAX_PRECISION
} from "../numbers"
import { Hoverlay } from "./Hoverlay"

import hfIcon from "../../assets/icons/favicon-32x32.png"

// an HOC. Not to be confused with utils.blankIfNil(), though it's related
export const BlankIfNil = func =>
    fallbackIfNil(func, "", ({ cellData }) => cellData)

// DATES

// TODO these two renderers can be replaced by a cellFormatter, as can several more below
export const FormattedDate = ({ cellData }) => dateFormat(cellData)
export const TimestampDate = ({ cellData }) => timestampFormat(cellData)

export const DischargeTimestamp = dodField => ({ cellData, rowData }) => {
    const cellMoment = moment.utc(cellData) // UTC here because we need to compare it to exactly START_OF_TIME, without a time zone offset
    if (cellMoment.isSame(START_OF_TIME, "day")) {
        // a discharge date equal to START_OF_TIME means the patient is undischarged
        return ""
    }
    if (cellMoment.isValid()) {
        return <span>{timestampFormat(cellData)}</span>
    }
    if (rowData[dodField]) {
        return <span className="deceased-cell">Deceased</span>
    }
    return <span>{cellData}</span>
}

export const formatYearMonth = cellData =>
    denumerateMonth(cellData).format(MONTH_STRING)

// BOOLEANS
export const formatYesNo = cellData => (cellData ? "Yes" : "No")
export const YesNoIndicatorCell = ({ cellData }) => (
    <YesNoIndicator value={cellData} />
)

export const Check = ({ cellData }) =>
    cellData ? <span className="fa fa-check" /> : null

// TEXT DATA

export const LongCell = ({ cellData }) => <Hoverlay>{cellData}</Hoverlay>

export const HoverlayCell = tooltipKey => ({ cellData, rowData }) => (
    <Hoverlay tooltip={rowData[tooltipKey] ?? null}>{cellData}</Hoverlay>
)

export const ListCell = ({ cellData, connector = "; ", ...props }) => (
    <Hoverlay {...props}>{_.join(cellData, connector)}</Hoverlay>
)
export const MultilineList = ({ cellData, ...props }) => (
    <ListCell
        cellData={cellData}
        className="tooltip-list"
        placement="auto-start"
        tooltip={
            <ul>
                {_.map(cellData, (item, i) => (
                    <li key={i}>{item}</li>
                ))}
            </ul>
        }
        {...props}
    />
)

// NUMBERS

export const LongNumber = ({ cellData }) => formatLongNumber(cellData)
export const FractionalNumberCell = ({ cellData }) => (
    <Hoverlay tooltip={formatLongNumber(cellData)}>
        {formatFractionalNumber(cellData)}
    </Hoverlay>
)
export const RateCell = BlankIfNil(({ cellData }) => (
    <Hoverlay tooltip={_.round(cellData, MAX_PRECISION)}>
        {String(_.round(cellData, 2))}
        {/* The cast to String ensures that it doesn't just go blank if the value is 0, which can happen */}
    </Hoverlay>
))

// MONEY

export const DollarsCents = ({ cellData }) => formatDollarsCents(cellData)

export const DollarsCentsNegative = ({ cellData }) => (
    <span
        className={classNames({
            "text-danger": cellData < 0
        })}
    >
        {formatDollarsCents(cellData)}
    </span>
)

export const DollarsCentsZeroBlank = ({ cellData }) =>
    cellData === 0 ? "" : formatDollarsCents(cellData)

export const FractionalDollarsCentsCell = ({ cellData }) => (
    <Hoverlay tooltip={formatFractionalDollarsCents(cellData)}>
        {formatDollarsCents(cellData)}
    </Hoverlay>
)

// PERCENTAGES

export const TrendCell = BlankIfNil(({ cellData }) => (
    <Hoverlay tooltip={formatPercentOffset(cellData, MAX_PRECISION)}>
        {formatPercentOffset(cellData)}
    </Hoverlay>
))
export const Percent = ({ cellData }) => formatPercent(cellData)

// PAYER

const _Payer = ({
    getLogo,
    rowData,
    cellData,
    label,
    columnData,
    idField: specifiedIdField
}) => {
    const idField = specifiedIdField ?? columnData?.column?.idField

    const id = rowData[idField]
    if (idField && !id) return null // show a blank cell if ID is 0

    const payerName = label ?? cellData
    const [payerId, sourceId, term45] = _.at(rowData, [
        PLC.TABLE_KEYS.PAYER_ID,
        PLC.TABLE_KEYS.SOURCE_ID,
        PLC.TABLE_KEYS.GRACE_PERIOD
    ])

    const logo = getLogo({
        payerId,
        sourceId,
        idField
    })

    return (
        <>
            <PayerIcon
                logo={logo}
                color={_.head(rowData[C.TABLE_KEYS.PAYER_COLORS])}
                term45={term45}
            />
            {payerName && <span>{payerName}</span>}
            {term45 && <GracePeriod />}
        </>
    )
}
export const Payer = connect(logoGetterSelector)(_Payer)

const _Coverage = ({ getLogo, getName, rowData = {} }) => {
    const [
        payerIds,
        payerNames,
        payerColors,
        sourceIds,
        sourceNames,
        productClassNames,
        term45
    ] = _.at(rowData, [
        PLC.TABLE_KEYS.PAYER_IDS,
        PLC.TABLE_KEYS.PAYER_NAMES,
        PLC.TABLE_KEYS.PAYER_COLORS,
        PLC.TABLE_KEYS.SOURCE_IDS,
        PLC.TABLE_KEYS.SOURCE_NAMES,
        PLC.TABLE_KEYS.PRODUCT_NAMES,
        PLC.TABLE_KEYS.GRACE_PERIOD
    ])
    const names = _.zip(
        payerNames,
        sourceNames
    ).map(([payerName, sourceName]) =>
        getName({ payerName, sourceName, idField: C.TABLE_KEYS.PAYER_ID })
    )

    const icons = _.zip(payerIds, sourceIds, names, payerColors).map(
        ([payerId, sourceId, name, payerColor]) => (
            <PayerIcon
                logo={getLogo({
                    payerId,
                    sourceId,
                    idField: C.TABLE_KEYS.PAYER_ID
                })}
                alt={name}
                color={payerColor}
                term45={payerId === term45}
            />
        )
    )

    const tooltipRows = _.zip(icons, names, productClassNames).map(
        ([icon, name, productClassName], i) => (
            <li key={i}>
                {icon}
                <span className="payer-name">{name}</span>
                {productClassName && (
                    <span className="product_class-name">
                        ({productClassName})
                    </span>
                )}
            </li>
        )
    )
    return (
        <>
            <Hoverlay className="tooltip-list" tooltip={<ul>{tooltipRows}</ul>}>
                {icons}
            </Hoverlay>
            {term45 && <GracePeriod />}
        </>
    )
}
export const Coverage = connect(logoGetterSelector)(_Coverage)

// TIERED TABLES

export const PivotCell = ({
    dataKey,
    cellData,
    rowData,
    tableData,
    className,
    hoverlayProps
}) => {
    const nextColumn = getNextTieredColumn(dataKey, tableData)

    if (!cellData || rowData[nextColumn?.key]) {
        // if there's a value in the next pivot cell to the right, don't print anything in this cell
        return null
    }

    return (
        <div
            className={classNames(className, "cell-inner-ellipsis", {
                "arrow-cell": nextColumn
            })}
        >
            <Hoverlay {...hoverlayProps}>{cellData}</Hoverlay>
        </div>
    )
}

export const IndentedCell = ({ cellData, className, onClick }) => (
    <div
        onClick={onClick}
        className={classNames("indent-cell", "cell-inner-ellipsis", className)}
    >
        {cellData && <Hoverlay>{cellData}</Hoverlay>}
    </div>
)

// UNSORTED

export const PatientName = (patientKey, billingKey) => ({
    // TODO rework to use columnData or idField
    // XXX actually... is columnData really better than this pattern? This functional pattern requires less boilerplate, and it's clearer what data corresponds to what. ColumnData is still useful when the renderer needs to see the actual column definition, but maybe we should revert some columnData renderers back to this approach.
    rowData,
    cellData,
    tableData
}) => (
    <span>
        <Link
            to={`/poc/${rowData[patientKey]}`}
            onClick={tableData?.onClickPatientName}
        >
            {cellData}
            {rowData[billingKey] && <span className="fa indicator-billable" />}
            {rowData[TC.TABLE_KEYS.GRACE_PERIOD] && <GracePeriod />}
            {rowData[PLC.TABLE_KEYS.PATIENT_DOD] && <Deceased />}
        </Link>
    </span>
)
export const RiskRank = ({ cellData, rowData, columnData }) => (
    <patient_risk_rank_info.components.default
        noData={cellData === null}
        patientId={rowData[columnData.patientKey]}
        riskRank={rowData[columnData.rankKey]}
    />
)

export const DualStatusCell = ({ cellData, rowData, columnData }) => {
    // see also modules/measures/components/MeasureCategory/Measure/IncentiveMarker

    if (!cellData || cellData.length !== 2) {
        return null
    }

    const hfCompliance = getComplianceAttributes(cellData?.[0])
    const payerCompliance = getComplianceAttributes(cellData?.[1])

    return (
        <Hoverlay
            isPopover
            className="dual-icon-group-tooltip"
            tooltip={
                <dl>
                    <dt>Health Focus:</dt>
                    <dd>
                        <BasicIcon {...hfCompliance} />
                    </dd>
                    <dt>
                        <Payer
                            rowData={rowData}
                            columnData={columnData}
                            label=""
                        />
                        {rowData[PLC.TABLE_KEYS.PATIENT_PAYER]}:
                    </dt>
                    <dd>
                        <BasicIcon {...payerCompliance} />
                    </dd>
                </dl>
            }
        >
            <div className="dual-icon-group">
                <span className="dual-icon">
                    <PayerIcon src={hfIcon} />
                    <BasicIcon
                        className={classNames(
                            "dual-icon-compliance",
                            hfCompliance?.className
                        )}
                    />
                </span>
                <span className="dual-icon">
                    <Payer rowData={rowData} columnData={columnData} label="" />
                    <BasicIcon
                        className={classNames(
                            "dual-icon-compliance",
                            payerCompliance?.className
                        )}
                    />
                </span>
            </div>
        </Hoverlay>
    )
}

export const TelephoneCell = ({ cellData }) => phoneFormat(cellData)
