import { GLOBAL_HEALTH_FOCUS_SOURCE_NAME } from "../../configuration"
export const HEALTH_FOCUS_SOURCE_NAME = GLOBAL_HEALTH_FOCUS_SOURCE_NAME

export { COMPLIANCE } from "../constants"

export const NAME = "measures"
export const ADD_MEASURE_TREE_FORM = "ADD_MEASURE_TREE_FORM"
export const POC_MEASURE_FORM = "POC_MEASURE_FORM"

export const RELEVANT_MEASURES_TREE = "RELEVANT_MEASURES_TREE"
export const ALL_MEASURES_TREE = "ALL_MEASURES_TREE"
export const CHART_DATA = "CHART_DATA"
export const MEASURE_HISTORY = "MEASURE_HISTORY"
export const MEASURE_SNACKBAR = "MEASURE_SNACKBAR"

export const BMI_PERCENTILE_ABBREV_PATTERN = /HFSS\.APV\.04\.*/

export const MEASURES = "MEASURES"
export const ADD_NEW_MEASURES = "ADD_NEW_MEASURES"
export const SAVE_MEASURES = "SAVE_MEASURES"
export const START_EDITING = "START_EDITING"
export const STOP_EDITING = "STOP_EDITING"
export const SAVE_EDITS = "SAVE_EDITS"
export const UPDATE_HISTORICAL_ITEM = "UPDATE_HISTORICAL_ITEM"
export const REMOVE_HISTORICAL_ITEM = "REMOVE_HISTORICAL_ITEM"
export const UPDATE_ADD_MEASURE_FILTER = "UPDATE_ADD_MEASURE_FILTER"
export const MARK_MEASURE_SAVING = "MARK_MEASURE_SAVING"
export const MARK_MEASURE_DONE = "MARK_MEASURE_DONE"

export const HIDE_SNACKBAR = "HIDE_SNACKBAR"
export const SHOW_SNACKBAR = "SHOW_SNACKBAR"

export const DEFAULT_CODE_DESC_ID = 0

export const ADD_MEASURE_FIELDS = {
    MEASURES: "measures"
}

export const measuresInitialState = {
    savingMeasures: new Set()
}
export const snackbarInitialState = {
    show: true
}
export const addMeasureInitialState = {
    filter: ""
}

export const CHECKBOX = "CHECKBOX"
export const ENUMERATION = "ENUMERATION"
export const MULTISELECT = "MULTISELECT"
export const NUMERIC = "NUMERIC"
export const ALPHANUMERIC = "ALPHANUMERIC"
export const COMPOSITE = "COMPOSITE"

// FIXME why do we not have all of the measure objects' keys in a dict here? Are we really referring to all of them directly?
//  ok well here are some at least

export const TABLE_KEYS = {
    MEASURE_ID: "measureId",
    ABBREV: "abbrevName",
    LINK: "link"
}
