import { createSelector } from "reselect"
import { getResourceData } from "../core/fetcher"
import { isNilOrEmpty, itemsToObject } from "../utils"
import { parseDate, SERVER_DATE_STRING, usToIsoDate } from "../dates"
import { MEASURES } from "../constants"
import * as C from "./constants"

const getFlags = filters =>
    itemsToObject(
        C.FLAG_OPTIONS,
        ({ value }) => value,
        ({ value }) => filters[C.FILTERS.FLAGS]?.includes(value)
    )

export const filtersSelector = createSelector(
    state => state[C.NAME]?.filters,
    filters =>
        _.pickBy(
            {
                ...filters,
                ..._.mapValues(_.pick(filters, C.DATE_FILTERS), usToIsoDate),
                [C.FILTERS.FLAGS]: undefined, // replaced by individual flag fields
                ...getFlags(filters)
            },
            _.negate(isNilOrEmpty)
        )
)
export const filtersAndCategoriesSelector = createSelector(
    filtersSelector,
    state => state[C.NAME]?.categories,
    (filters, categories) => ({
        ...filters,
        categories: _.compact(
            categories.map(category => C.AWV_NAME_LOOKUP[category]?.code)
        )
    })
)

export const eligibleDateFallbackSelector = createSelector(
    state => state.server_status.anchorDate,
    anchorDate => parseDate(anchorDate, SERVER_DATE_STRING)?.startOf("year")
)

export const awvMeasureSelector = createSelector(
    getResourceData(MEASURES, data => data.measures),
    measures =>
        measures.find(measure => measure.abbrevName?.match(C.AWV_MEASURE_CODE))
            ?.measureId
)
