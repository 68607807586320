import React, { Component } from "react"
import { Button, FormControl, InputGroup } from "react-bootstrap"
import classNames from "classnames"

export default class SearchBar extends Component {
    state = { focused: false }

    handleFocus = () => this.setState({ focused: true })
    handleBlur = () => this.setState({ focused: false })

    handleChange = e => {
        e.stopPropagation()
        const { onChange } = this.props
        return onChange && onChange(e.target.value, e)
        // generally we'll pass in onChange(value), but you can pass a function that accepts the event too if you really wanna. Or no function at all if you prefer.
    }

    render() {
        const {
            buttonType,
            containerStyle,
            className,
            disabled,
            cosmeticButton,
            ...props
        } = this.props

        return (
            <InputGroup
                className={classNames(
                    "search-bar",
                    className,
                    this.state.focused && "input-group-focus"
                )}
                style={containerStyle}
            >
                <FormControl
                    type="search"
                    maxLength={50}
                    {...props}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                />
                <InputGroup.Append className="input-group-btn">
                    {cosmeticButton ? (
                        <div className="btn btn-spacer">
                            <span className="fa fa-search" />
                        </div>
                    ) : (
                        <Button
                            type={buttonType || "button"}
                            disabled={disabled}
                            variant="default"
                        >
                            <span className="fa fa-search" />
                        </Button>
                    )}
                </InputGroup.Append>
            </InputGroup>
        )
    }
}

export const SearchBarField = ({ input, meta, ...props }) => (
    <SearchBar
        {...input}
        disabled={!input.value || meta.error}
        cosmeticButton
        {...props}
    />
)
