import React from "react"
import { connect } from "react-redux"
import { reduxForm, reset } from "redux-form"
import LEHOC from "../../core/LoadingErrorHOC"
import fetcher from "../../core/fetcher"
import { moduleEnabledSelector } from "../../moduleUtils"
import { ApiTypes } from "../../api"
import { MODULE_IDS } from "../../constants"
import {
    measureAbbrevMapSelector,
    measuresMapSelector,
    relevantCategoriesSelector,
    validate
} from "../selectors"
import * as reduxActions from "../actions"
import * as C from "../constants"
import AddMeasureModal from "./add_measure"
import MeasureCategory from "./MeasureCategory"
import SnackBar from "./SnackBar"

export { AddMeasureModal }

export const MeasureSection = ({
    categories,
    change,
    clearFields,
    disabled,
    error,
    handleSubmit,
    loading
}) => (
    <div
        id="segment-measures"
        role="tablist"
        className="widget compress-font card card-default card-group card-table"
        style={{ boxShadow: "none" }}
    >
        <LEHOC loading={loading} error={error} list={categories}>
            <div className="poc_measures">
                {categories.map(category => (
                    <MeasureCategory
                        key={category.name}
                        category={category}
                        actions={{ change, clearFields }}
                        disabled={disabled}
                    />
                ))}
            </div>
        </LEHOC>
        <SnackBar handleSubmit={handleSubmit} />
    </div>
)

const ReduxMeasureForm = reduxForm({
    form: C.POC_MEASURE_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
})(MeasureSection)

export default connect(
    state => ({
        measures: measureAbbrevMapSelector(state),
        categories: relevantCategoriesSelector(state),
        initialValues: {},
        disabled: !moduleEnabledSelector(MODULE_IDS.POC_MEASURES)(state)
    }),
    { ...reduxActions, onSubmit: reduxActions.saveMeasures, reset }
)(
    fetcher({
        name: C.RELEVANT_MEASURES_TREE,
        endpoint: state => `/api/patients/${state.poc_id}/measures`,
        method: ApiTypes.GET
    })(ReduxMeasureForm)
)
