import React, { useEffect, useMemo, useRef } from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import { FormGroup } from "react-bootstrap"
import _ from "lodash"
import { getResourceData } from "../../../../../core/fetcher"
import { NAME as INFO_NAME } from "../../../../../info/constants"
import { authTokensSelector } from "../../../../../authentication/selectors"
import { InfoIndicator } from "../../../../../indicators"
import { EMPTY_OBJECT, isNilOrBlank } from "../../../../../utils"
import { inputLookupSelector } from "../../../../selectors"
import * as C from "../../../../constants"
import {
    encodeAbbrevToInputName,
    formatPercentile,
    getAllDetails,
    getAutocalc,
    getInputDateName,
    getValuesAndMeta
} from "../../../../helpers"
import AutocalcErrorFallback from "../AutocalcErrorFallback"

const AutocalcCheckbox = ({ checked }) => (
    <label className="checkbox">
        <input type="checkbox" checked={checked} disabled readOnly />
        {!checked && "Not"} Completed (Auto Calculated)
    </label>
)

const handleUpdate = (prevValues, nextProps) => {
    if (!nextProps.measure.autoCalc) {
        return
    }

    const details = getAllDetails(
        prevValues,
        nextProps.formValues,
        nextProps.measure,
        nextProps.inputLookup
    )
    const { update, clear, values } = getValuesAndMeta(details)

    const observationDate =
        nextProps.formValues[getInputDateName(nextProps.measure.abbrevName)]

    if (update) {
        const autocalcPromise = getAutocalc(
            nextProps.patientInfo.patientId,
            nextProps.measure.abbrevName,
            values,
            nextProps.authentication,
            observationDate
        )

        // in the form, an undefined or missing value means there's no data entered about the measure; a null or "" value means that a value was calculated by the formula, and it determined that nothing should be displayed in these circumstances.
        Promise.resolve(autocalcPromise).then(({ value }) => {
            if (value === nextProps.input.value) {
                // no change needed
                return
            }
            if (isNilOrBlank(value) && isNilOrBlank(nextProps.input.value)) {
                // those both count as null, so effectively unchanged
                return
            }

            return nextProps.input.onChange(value)
        })
    } else if (clear && nextProps.input.value !== undefined) {
        // specifically checking against undefined because the value could also be null or blank, which has a different meaning
        nextProps.input.onChange(undefined)
    }
}

const getDisplayValue = (value, measure, props) => {
    if (measure.abbrevName?.match(C.BMI_PERCENTILE_ABBREV_PATTERN)) {
        // BMI percentile hardcoded

        if (value.error) {
            return (
                <span>
                    Unknown <InfoIndicator tooltip={value.error} />
                </span>
            )
        } else if (!isNilOrBlank(value)) {
            return formatPercentile(value)
        }
    } else if (props.dataType === C.CHECKBOX) {
        if (value || value === false) {
            // actually false, not just falsey
            return <AutocalcCheckbox checked={value} />
        }
    } else if (props.dataType === C.ENUMERATION) {
        const resultOption = _.find(
            props.dropListOptions,
            option => value === (option.id ?? option.value)
        )
        return resultOption?.name ?? null
    } else if (value) {
        return (
            <span>
                {value} {props.unitLabel}
            </span>
        )
    }

    return "Auto Calculated" // placeholder for when there are no values
}

const AutocalcTooltip = ({ terms }) => (
    <>
        <div className="tooltip-header">Calculated using:</div>
        <ul>
            {terms.map((term, i) => (
                <li key={i}>{term}</li>
            ))}
        </ul>
    </>
)

const getTermNames = (measure, inputLookup) =>
    measure.requiredMeasures.map(
        abbrev => inputLookup[encodeAbbrevToInputName(abbrev)]?.name
    )

export const AutoCalculated = props => {
    const input = props.input
    const measure = props.inputLookup[input.name]

    const prevValues = useRef({})

    const onUpdate = useMemo(
        () => _.debounce(handleUpdate, 300, { leading: true }),
        []
    )

    useEffect(() => {
        onUpdate(prevValues.current, props)
        prevValues.current = props.formValues
    }, [
        props.input,
        props.measure,
        props.formValues,
        props.inputLookup,
        props.patientInfo,
        props.authentication
    ])

    if (_.isEmpty(measure.requiredMeasures)) {
        const message = `Auto-calculated definition for measure ${props.nameText ??
            input.name} does not exist`
        console.error(message)
        return <AutocalcErrorFallback error={{ message }} />
    }

    const terms = getTermNames(measure, props.inputLookup)
    return (
        <FormGroup>
            <p className="form-control-static">
                {getDisplayValue(input.value, measure, props)}
                <InfoIndicator
                    className="tooltip-list tooltip-blue"
                    tooltip={<AutocalcTooltip terms={terms} />}
                />
            </p>
        </FormGroup>
    )
}

export default connect((state, { abbrevName }) => ({
    formValues: getFormValues(C.POC_MEASURE_FORM)(state) || EMPTY_OBJECT,
    patientInfo: getResourceData(INFO_NAME)(state),
    inputLookup: inputLookupSelector(state),
    authentication: authTokensSelector(state)
}))(AutoCalculated)
