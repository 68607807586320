export { TASK_STATUS } from "../toc/constants"

export const NAME = "batch_print"
export const POC_PRINT_MODAL_FORM = "POC_PRINT_MODAL_FORM"
export const BATCH_PRINT_MODAL_FORM = "BATCH_PRINT_MODAL_FORM"
export const SERVICES = "SERVICES"
export const ALL_ASSESSMENTS = "ALL_ASSESSMENTS"

export const PATIENT_LIST = "PATIENT_LIST"
export const CAMPAIGN_PATIENT_LIST = "CAMPAIGN_PATIENT_LIST"

export const SHOW_BATCH_PRINT = "SHOW_BATCH_PRINT"
export const HIDE_BATCH_PRINT = "HIDE_BATCH_PRINT"
export const SHOW_POC_PRINT = "SHOW_POC_PRINT"
export const HIDE_POC_PRINT = "HIDE_POC_PRINT"
export const SUBMIT_BATCH_PRINT = "SUBMIT_BATCH_PRINT"
export const SUBMIT_POC_PRINT = "SUBMIT_POC_PRINT"
export const SUBMIT_PRINT_SUCCESS = "SUBMIT_PRINT_SUCCESS"
export const SUBMIT_PRINT_ERROR = "SUBMIT_PRINT_ERROR"
export const UPDATE_BATCH_PRINT_PERCENTAGE = "UPDATE_BATCH_PRINT_PERCENTAGE"

export const initialState = {
    show_poc: false,
    show_bp: false,
    printing: false,
    downloadPercentage: 0
}

export const CHECKBOX_CLASSES = {
    true: "fa-check-square-o",
    false: "fa-square-o"
}
export const RADIO_CLASSES = { true: "fa-check-circle-o", false: "fa-circle-o" }

export const TOP_LEVEL_PRINT_FIELDS = {
    POC_FORM: "pocForm",
    ATTENTION: "attention",
    CONDITIONS: "conditions",
    HISTORY: "history",
    COST: "cost",
    LETTER: "letter",
    LINKAGES: "linkages",
    FQHC: "fqhc"
}

export const PRINT_FIELDS = {
    ...TOP_LEVEL_PRINT_FIELDS,
    ONLY_REQUIRED: "onlyRequired",
    ONLY_INCENTIVIZED: "onlyIncentivized",
    DUE_SERVICES: "dueServices",
    ASSESSMENTS: "assessments",
    HISTORY_MEASURES: "historyMeasures",
    SINGLE_PDF: "singlePdf",
    ONLY_GAPS: "onlyGaps",
    WITH_GRAPHS: "withGraphs",
    LINKAGE_HISTORY: "withLinkageHistory",
    COND_ABBREVIATED: "condAbbreviated",
    ONLY_UNDIAGNOSED: "onlyUndiagnosed",
    COST_PERIOD: "costPeriod"
}

export const PRINT_TRANSLATION = {
    [PRINT_FIELDS.POC_FORM]: "Point of Care Measures",
    [PRINT_FIELDS.ATTENTION]: "Priority Attention",
    [PRINT_FIELDS.CONDITIONS]: "Conditions",
    [PRINT_FIELDS.HISTORY]: "Measure History Graphs",
    [PRINT_FIELDS.COST]: "Costs",
    [PRINT_FIELDS.LETTER]: "Reminder Letter",
    [PRINT_FIELDS.LINKAGES]: "Community Linkages",
    [PRINT_FIELDS.ONLY_GAPS]: "Abbreviated",
    [PRINT_FIELDS.COND_ABBREVIATED]: "Abbreviated",
    [PRINT_FIELDS.ONLY_INCENTIVIZED]: "Only Incentivized",
    [PRINT_FIELDS.LINKAGE_HISTORY]: "Include history",
    [PRINT_FIELDS.WITH_GRAPHS]: "Include graphs",
    [PRINT_FIELDS.FQHC]: "FQHC Plan of Care"
    // other fields use labels in non-standard ways currently, and don't need an official translation
}

export const CONDITIONS_OPTIONS = [
    { value: false, label: "All Conditions" },
    {
        value: true,
        label: "Undiagnosed Conditions"
    }
]

export const initialValues = {
    [PRINT_FIELDS.ASSESSMENTS]: [],
    [PRINT_FIELDS.HISTORY_MEASURES]: [],
    [PRINT_FIELDS.LINKAGE_HISTORY]: false,
    [PRINT_FIELDS.ONLY_UNDIAGNOSED]: false,
    [PRINT_FIELDS.ONLY_REQUIRED]: false,
    [PRINT_FIELDS.ONLY_INCENTIVIZED]: false,
    [PRINT_FIELDS.ONLY_GAPS]: false,
    // [PRINT_FIELDS.WITH_GRAPHS]: false, // XXX maybe later
    [PRINT_FIELDS.POC_FORM]: false,
    [PRINT_FIELDS.ATTENTION]: false,
    [PRINT_FIELDS.CONDITIONS]: false,
    [PRINT_FIELDS.LINKAGES]: false,
    [PRINT_FIELDS.LETTER]: false,
    [PRINT_FIELDS.HISTORY]: false,
    [PRINT_FIELDS.COST]: false,
    [PRINT_FIELDS.FQHC]: false
}
