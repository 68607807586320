import React, { Component } from "react"
import classNames from "classnames"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"

import { isNilOrBlank } from "../../../../../utils"
import * as C from "../../../../constants"
import { inputLookupSelector } from "../../../../selectors"
import {
    getAllDetails,
    getMeasureInputName,
    getValuesAndMeta
} from "../../../../helpers"

export class AutoCalculatedDate extends Component {
    state = { error: false }

    componentDidUpdate(prevProps) {
        const name = getMeasureInputName(this.props.measure)
        const dateInput = this.props.input
        const prevValue = prevProps ? prevProps.values[name] : null
        const nextValue = this.props.values[name]

        if (!this.props.measure.autoCalc) {
            return
        }

        const details = getAllDetails(
            prevProps.values,
            this.props.values,
            this.props.measure,
            this.props.inputLookup
        )
        const { date, error } = getValuesAndMeta(details)

        if (error !== this.state.error) {
            this.setState({ error })
        }

        const valuesAreDifferent = prevValue !== nextValue
        const datesAreDifferent =
            (!!date || !!dateInput.value) && date !== dateInput.value // have to check because dateInput.value will be "" if it's empty, while date will be null

        if (isNilOrBlank(nextValue)) {
            // if there's no calculated value (due to not having the relevant measures filled out yet, or due to the formula actively declining to return a value), we don't show a date at all.
            if (valuesAreDifferent) {
                // still might need to change from undefined to null or vice versa, though
                dateInput.onChange(nextValue)
            }
            return
        }

        if (valuesAreDifferent || datesAreDifferent) {
            dateInput.onChange(date || undefined)
        }
    }

    render() {
        return (
            <p className="form-control-static">
                <span className={classNames(this.state.error && "error_label")}>
                    {this.props.input.value || "Auto Calculated"}
                </span>
            </p>
        )
    }
}

export default connect(state => ({
    values: getFormValues(C.POC_MEASURE_FORM)(state),
    inputLookup: inputLookupSelector(state)
}))(AutoCalculatedDate)
