import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import SiteTitle from "../modules/core/SiteTitle"
import Breadcrumbs from "../modules/core/Breadcrumbs"
import { uncrush, urlParamsObject } from "../modules/utils"
import { moduleEnabledSelector } from "../modules/moduleUtils"
import { MODULE_IDS } from "../modules/constants"
import care_management from "../modules/care_management"
import patient_list from "../modules/patient_list"

const CareManagementPage = ({
    costModuleEnabled,
    filters,
    measureId,
    setCmMeasure,
    setCmPatientFilters,
    location,
    ...props
}) => {
    if (!props.match.params.abbrev) {
        return <Redirect to="/patients" />
    }

    useEffect(() => {
        setCmMeasure(props.match.params.abbrev)

        const defaultFilters = {
            ...care_management.constants.DEFAULT_FILTERS,
            [patient_list.constants.FILTERS.MEASURES]: props.match.params.abbrev
        }
        if (!location.search) {
            setCmPatientFilters(defaultFilters)
            return
        }

        const params = urlParamsObject(location.search)
        if (!params.filters) {
            setCmPatientFilters(defaultFilters)
            return
        }

        const filters = uncrush(params.filters)

        if (params.noDefaults) {
            setCmPatientFilters(filters)
        } else {
            setCmPatientFilters({
                ...defaultFilters,
                ...filters
            })
        }
    }, [props.match.params.abbrev, location.search])

    return (
        <>
            <SiteTitle title="Care Management Patients" />
            <Breadcrumbs
                items={[["Dashboard", "/dashboard"], ["Patient List"]]}
            />
            <care_management.components.default {...props} />
            <care_management.components.ProductGraph />
            {costModuleEnabled && <care_management.components.CostChart />}
        </>
    )
}

const costModuleEnabled = moduleEnabledSelector(MODULE_IDS.COST_UTILIZATION)
const chartModuleEnabled = moduleEnabledSelector(MODULE_IDS.SPENDING_CHART)
export default connect(
    state => ({
        ...state[care_management.constants.NAME],
        costModuleEnabled: costModuleEnabled(state) && chartModuleEnabled(state)
    }),
    care_management.actions
)(CareManagementPage)
