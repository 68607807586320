import React, { useEffect, useMemo } from "react"
import { Field } from "redux-form"
import _ from "lodash"
import { SelectField } from "../../../../../core/CustomFields"
import {
    getCompositeSubmeasureInputName,
    getDropListOptions,
    getInputName
} from "../../../../helpers"
import * as C from "../../../../constants"
import validatorLookup from "./validators"
import CompositeSubMeasure from "./CompositeSubMeasure"

export const CompositeMeasures = props => {
    const { abbrevName, subMeasures, formValues, disabled, change } = props

    const name = useMemo(() => getInputName(abbrevName), [abbrevName])

    const dropListOptions = useMemo(
        () => getDropListOptions(props.dropListOptions, "id"),
        [props.dropListOptions]
    )

    const submeasureProps = useMemo(
        () =>
            subMeasures?.map(subMeasure => ({
                ...subMeasure,
                subMeasure,
                placeholder: subMeasure.name,
                nameText: subMeasure.name,
                name: getCompositeSubmeasureInputName(abbrevName)(subMeasure)
            })),
        [subMeasures, abbrevName]
    )

    useEffect(
        () => {
            const newValue = submeasureProps
                ?.map(submeasure => formValues[submeasure.name])
                ?.filter(item => !_.isNil(item))
                ?.join(" / ")

            change(C.POC_MEASURE_FORM, name, newValue)
        },
        [disabled, formValues],
        name
    )

    const validators = useMemo(() => {
        const validatorsGetter = _.find(validatorLookup, (value, key) =>
            name.match(key)
        )

        if (!validatorsGetter) {
            console.warn("No composite-measure validator found for", name)
            return []
        }
        return validatorsGetter({ abbrevName, subMeasures })
    }, [name, abbrevName, subMeasures])

    return (
        <div className="measure-composite">
            <Field
                {...props}
                name={name}
                validate={validators}
                style={{ display: "none" }}
                component={SelectField}
                options={dropListOptions}
            />
            {submeasureProps?.map(smProps => (
                <CompositeSubMeasure
                    key={smProps.id}
                    {...smProps}
                    disabled={smProps.disabled}
                    className="submeasure-composite"
                />
            ))}
        </div>
    )
}
