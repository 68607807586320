import React from "react"
import { connect } from "react-redux"
import { isPristine, reduxForm, submit } from "redux-form"
import { Button, Modal } from "react-bootstrap"
import _ from "lodash"

import { formToucher } from "../../../core/FormToucher"
import LER from "../../../core/LoadingErrorHOC"
import fetcher from "../../../core/fetcher"
import FormModal from "../../../core/FormModal"
import { ApiTypes } from "../../../api"
import { NAME as AUTHENTICATION } from "../../../authentication/constants"

import { getAccessLevelSelector } from "../../helperSelectors"
import {
    canEditUserInfoSelector,
    editUserInitialValuesSelector,
    userModalConnectSelector
} from "../../modalSelectors"
import * as C from "../../constants"
import * as actions from "../../actions"
import { validateEditUser } from "./validateUser"
import { getUserTitle } from "./helpers"
import { UserModalTabs } from "./UserModalTabs"

const EditUserModalForm = reduxForm({
    form: C.EDIT_USER_FORM,
    enableReinitialize: true,
    validate: validateEditUser
})(formToucher(UserModalTabs, C.USER_FIELDS))

const EditUserModal = props => (
    <FormModal
        dialogClassName="small-modal admin-modal"
        show={props.show}
        onHide={props.hideEditUserModal}
    >
        <Modal.Header closeButton>
            <Modal.Title>
                Edit User {!props.loading && getUserTitle(props.selectedUser)}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body as="form" autoComplete="off">
            <LER loading={props.loading} error={props.error}>
                {/* unmount the form when the modal is loading, or else it won't revalidate properly */}
                <EditUserModalForm {...props} />
            </LER>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="secondary"
                className="pull-left"
                onClick={props.hideEditUserModal}
            >
                Cancel
            </Button>
            <Button
                type="submit"
                variant="primary"
                className="pull-right"
                disabled={
                    props.loading ||
                    props.saving ||
                    props.pristine ||
                    !_.isEmpty(props.invalidTabs)
                }
                onClick={() => props.submit(C.EDIT_USER_FORM)}
            >
                Save
            </Button>
        </Modal.Footer>
    </FormModal>
)

const EditUserModalFetcher = fetcher({
    name: C.SELECTED_USER,
    method: ApiTypes.GET,
    requestBodySelector: state => state[C.ADMIN_NAME].selectedUserUpdateCount,
    endpoint: state => `/api/users/${state[C.ADMIN_NAME].selectedUserId}`
})(EditUserModal)

// without this, on first load before the store is fully initialized the page will try to request user data for user 0, the dummy user which shouldn't be edited except via its own account or via direct db changes. This raises an error when we turn out to not have permission. Better to prevent the request entirely.
const EditUserModalWrapper = props =>
    props.show ? <EditUserModalFetcher {...props} /> : null

const connectSelector = userModalConnectSelector(C.EDIT_USER_FORM)

const mapStateToProps = state => {
    const { selectedUserId, saving } = state[C.ADMIN_NAME]

    const userConnect = connectSelector(state)
    const { selectedUser } = userConnect
    return {
        ...userConnect,
        selectedUserId, // selectedUser doesn't have a userId field of its own
        saving,
        show: state[C.ADMIN_NAME].editUserModalOpen,
        userExists: true,
        preventEditPractices:
            selectedUserId === state[AUTHENTICATION].userId ||
            selectedUser[C.USER_FIELDS.IS_SUPERUSER],
        editingSuperuser: selectedUser[C.USER_FIELDS.IS_SUPERUSER],
        canEditUserInfo: canEditUserInfoSelector(state),
        initialValues: editUserInitialValuesSelector(state),
        pristine: isPristine(C.EDIT_USER_FORM)(state),
        getAccessLevel: getAccessLevelSelector(state)
    }
}

export default connect(mapStateToProps, {
    ...actions,
    submit,
    onSubmit: actions.updateUser
})(EditUserModalWrapper)
