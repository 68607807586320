import React from "react"
import { Hoverlay } from "../../../../core/Hoverlay"

export const AutocalcErrorFallback = ({ error }) => (
    <Hoverlay className="tooltip-red" tooltip={error.message}>
        <span className="error_label">Autocalc error</span>
        <span className="fa indicator-overdue" />
    </Hoverlay>
)

export default AutocalcErrorFallback
