import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useResizeDetector } from "react-resize-detector"

// import logo from "../../../assets/images/logo_navbar.gif"
import logo from "../../../assets/images/logo.svg"

import * as C from "../constants"
import HoverNavDropDown from "./HoverNavDropDown"
import NavLink from "./NavLink"
import NavSearch from "./NavSearch"
import { AdminTools } from "./AdminTools"
import { AccountActions } from "./AccountActions"

const getOnClick = (child, dispatch) =>
    child.action ? () => dispatch?.({ type: child.action }) : undefined

const SubNavItem = ({ child, dispatch, location }) => (
    <NavLink
        className="dropdown-item"
        {...child}
        href={child.href}
        matchOn={child.href}
        onClick={getOnClick(child, dispatch)}
        location={location}
    />
)

const NavItem = ({ item, dispatch, location }) =>
    item.children ? (
        <HoverNavDropDown {...item} id={item.label} location={location}>
            {item.children.map(child => (
                <SubNavItem
                    key={child.href ?? child.action}
                    child={child}
                    dispatch={dispatch}
                    location={location}
                />
            ))}
        </HoverNavDropDown>
    ) : (
        <NavLink {...item} onClick={getOnClick(item)} location={location} />
    )

export const Navigation = ({
    allowGlobal,
    change,
    clearCache,
    dispatch,
    initialValues,
    location,
    loginId,
    logoutAction,
    navItems,
    roles,
    goToPatientFilters,
    storeNavDimensions,
    fuzzySearchFlags
}) => {
    const { height = 0, ref } = useResizeDetector({
        onResize: storeNavDimensions,
        refreshMode: "throttle",
        refreshRate: 5 // this is pretty fast, but it starts to look choppy if we go much higher. And I want to have *some* limit on refresh rate
    })

    const searchSubmit = values => {
        goToPatientFilters(values)
        change(
            // reset just the search field
            C.NAV_SEARCH_FORM,
            C.SEARCH_FIELDS.SEARCH,
            initialValues[C.SEARCH_FIELDS.SEARCH]
        )
    }

    return (
        <>
            <Navbar
                collapseOnSelect
                expand="lg"
                fixed="top"
                className="navigation-hot-fix navbar-default navbar-fixed-top"
                ref={ref}
            >
                <div className="navbar-header">
                    <Link to="/dashboard" className="navbar-brand">
                        <img src={logo} alt="logo" className="img-fluid" />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="navbar-toggler-icon" />
                    </Navbar.Toggle>
                </div>
                <Navbar.Collapse>
                    <NavSearch
                        initialValues={initialValues}
                        allowGlobal={allowGlobal}
                        onSubmit={searchSubmit}
                        fuzzySearchFlags={fuzzySearchFlags}
                    />
                    <Nav activeKey="1" as="ul">
                        {navItems.map(item => (
                            <NavItem
                                key={item.label}
                                item={item}
                                location={location}
                                dispatch={dispatch}
                            />
                        ))}
                        <AdminTools
                            clearCache={clearCache}
                            location={location}
                            roles={roles}
                        />
                        <AccountActions
                            location={location}
                            loginId={loginId}
                            logoutAction={logoutAction}
                        />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className="navbar-shadow-box" style={{ top: height }} />
        </>
    )
}

export default Navigation
