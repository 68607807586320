import React from "react"
import { Field } from "redux-form"
import classNames from "classnames"

import { getTranslation } from "../../core/helpers"
import * as C from "../constants"
import { PrintField } from "./PrintField"

export const ToggleField = ({ input, meta, className, ...props }) => (
    <span
        onClick={() => input.onChange(!input.value)}
        className={classNames("toggle-field", className)}
        {...props}
    >
        <span className={classNames("fa", C.CHECKBOX_CLASSES[!!input.value])} />
        {getTranslation(input, meta)[input.name]}
    </span>
)

export const PrintLinkages = () => (
    <PrintField name={C.PRINT_FIELDS.LINKAGES} className="print-linkages">
        <Field name={C.PRINT_FIELDS.LINKAGE_HISTORY} component={ToggleField} />
    </PrintField>
)
