import moment from "moment"
import _ from "lodash"
import * as C from "./constants"
import { getValues } from "../utils"

export const transformMeasures = measure => ({
    label: measure.name,
    value: measure.measureId,
    nextDueDt: moment().startOf("year") // we want all measures to show up as required, so we set the due date to the beginning of this year. (non-required = not due this year)
})

export const transformServices = item => ({
    label: item.measureName,
    value: item.measureId,
    nextDueDt: item.nextDueDt && moment.utc(item.nextDueDt)
})

export const shouldDisablePrint = formValues =>
    !formValues ||
    _.isEmpty(
        _.compact(_.at(formValues, _.values(C.TOP_LEVEL_PRINT_FIELDS)))
    ) ||
    (formValues[C.PRINT_FIELDS.LETTER] &&
        _.isEmpty(formValues[C.PRINT_FIELDS.DUE_SERVICES]))

export const getCheckAllProps = (change, formValues, field, allNodes) => () => {
    const setSelected = newSelected => () => change(field, newSelected)

    const allChecked = formValues[field]?.length === allNodes?.length

    return allChecked
        ? {
              onClick: setSelected([]),
              text: "Uncheck all",
              className: "fa-square-o"
          }
        : {
              onClick: setSelected(getValues(allNodes)),
              text: "Check all",
              className: "fa-check-square-o"
          }
}
