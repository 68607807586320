import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { AddMeasureModal } from "./AddMeasureModal"

export const AddMeasureModalContainer = props => {
    if (props.disabled) {
        return null
    }

    const [show, setShow] = useState(false)

    const handleSubmit = () => {
        props.addNewMeasures()
        props.updateAddMeasureFilter() // clear measure filter
        props.reset()
        setShow(false)
    }

    return (
        <>
            <Button
                type="primary"
                size="sm"
                onClick={() => setShow(true)}
                className="pull-right add-measure"
            >
                <i className="fa fa-plus-circle" /> Add Measure
            </Button>
            <AddMeasureModal
                {...props}
                show={show}
                onChange={props.updateAddMeasureFilter}
                onClose={() => setShow(false)}
                onSubmit={handleSubmit}
            />
        </>
    )
}
