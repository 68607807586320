import { connect } from "react-redux"
import _ from "lodash"
import { moduleColumnsSelector } from "../moduleUtils"
import { callOrTake, sameItems } from "../utils"
import { PATH } from "../constants"
import * as C from "./constants"
import { initializeTable, resetTable, updateTable } from "./actions"
import { encodeAbbrevToInputName } from "../measures/helpers"

export const columnBuilder = (translation, config) => (key, options) => ({
    key,
    label: translation[key] || key,
    selected: true,
    ...config,
    ...options
})

// for use as an arePropsEqual argument in React.memo.
// XXX This may be unsafe if you're doing something tricky with functions. I dunno. Should never come up.
export const arePropsBasicallyEqual = (prevProps, nextProps) => {
    const changedValues = _.entries(prevProps).filter(
        ([key, val]) =>
            !_.isEqual(nextProps[key], val) &&
            !_.isFunction(val) && // otherwise all the inline arrow functions will ping us
            key !== "children" // if children change, they can rerender themselves thanks
    )
    const keysUnchanged = sameItems(_.keys(prevProps), _.keys(nextProps))

    return _.isEmpty(changedValues) && keysUnchanged
}

export const connectTable = factoryProps => {
    const { name, columns, frozenColumns, ...props } = factoryProps

    const columnsSelector = moduleColumnsSelector(columns)
    const frozenColumnsSelector = moduleColumnsSelector(frozenColumns)

    return connect(
        state => ({
            ...props,
            tableName: name,
            columns: columnsSelector(state),
            frozenColumns: frozenColumnsSelector(state),
            ...state[C.TABLE_STORE][name] // overwrites column and sort fields
        }),
        {
            updateTable,
            initializeTable: () => initializeTable(factoryProps),
            resetTable: () => resetTable(factoryProps)
        }
    )
}

/**
 * given a list of column definitions, returns only the selected ones.
 */
export const getSelectedColumns = columns =>
    _.filter(
        columns,
        ({ forceSelected, selected }) => forceSelected || selected
    )

export const getSelectedColumnKeys = columns =>
    getSelectedColumns(columns).map(col => col.key)

/**
 * marks columns as selected or not, without throwing any away
 */
export const markColumnsSelected = (
    columns,
    shouldSelect = column => column.selected
) =>
    _.map(columns, column => ({
        ...column,
        selected: column.forceSelected || callOrTake(column, shouldSelect)
    }))

/**
 * Keeps and selects the columns matching the provided keys, and throws away the rest.
 * Keeps original order.
 */
export const filterColumnsByKeys = (columns, columnKeys) =>
    _.filter(
        columns,
        ({ key, forceSelected }) => forceSelected || columnKeys.includes(key)
    )

/**
 * Add the keys of any forceSelected columns in validColumns to the end of columnKeys, unless
 * they're already in columnKeys or frozenColumnKeys.
 * Any columns with forceSelected should already be selected, but if someone manually changed the
 * saved value in the DB or the store then the column might not appear as selected. This ensures
 * that it does.
 */
export const addForcedColumnsToKeys = (
    validColumns,
    columnKeys = [],
    frozenColumnKeys = []
) => {
    const forcedKeys = _.filter(
        validColumns,
        ({ forceSelected, key }) =>
            forceSelected &&
            !columnKeys.includes(key) &&
            !frozenColumnKeys.includes(key)
    ).map(({ key }) => key)

    return [...columnKeys, ...forcedKeys]
}

/**
 * Keeps and selects the columns matching the provided keys, and throws away the rest.
 * Matches order to columnKeys.
 * This ignores forceSelected, because we wouldn't know where in the order to put it.
 * You should manually add forceSelected columns to the key list beforehand, using addForcedColumnsToKeys
 */
export const selectColumnsByKeys = (columnsMap, columnKeys) =>
    markColumnsSelected(_.compact(_.at(columnsMap, columnKeys)), true)

/**
 * Given two lists of columns, return all columns that are in the first list but
 * not the second, marked as unselected.
 */
export const getUnselectedColumns = (columnDefinitions, selectedColumns) =>
    markColumnsSelected(
        _.differenceBy(columnDefinitions, selectedColumns, ({ key }) => key),
        false
    )

/**
 * Allocates columns to frozenColumns or regular columns, based on two lists of column keys.
 * Any columns whose keys aren't mentioned are placed at the end of the regular columns.
 */
export const placeColumnsByKeys = (
    columnDefinitions,
    columnKeys = [],
    frozenColumnKeys = []
) => {
    const columnDefMap = _.keyBy(columnDefinitions, ({ key }) => key)

    const columnKeysIncludingForced = addForcedColumnsToKeys(
        columnDefinitions,
        columnKeys,
        frozenColumnKeys
    )

    const frozenColumns = selectColumnsByKeys(columnDefMap, frozenColumnKeys)
    const selectedColumns = selectColumnsByKeys(
        columnDefMap,
        columnKeysIncludingForced
    )
    const unselectedColumns = getUnselectedColumns(columnDefinitions, [
        ...frozenColumns,
        ...selectedColumns
    ])

    return {
        frozenColumns,
        columns: [...selectedColumns, ...unselectedColumns]
    }
}

export const getDefaultColumns = (columns = [], tableDefaults = {}) => {
    const {
        columns: columnKeys,
        frozenColumns: frozenColumnKeys
    } = tableDefaults // these are actually lists of column keys

    if (_.isEmpty(columnKeys) && _.isEmpty(frozenColumnKeys)) {
        return {
            columns,
            frozenColumns: []
        }
    }
    return placeColumnsByKeys(columns, columnKeys, frozenColumnKeys)
}

// tiered/pivot table stuff

export const getNextTieredColumn = (
    dataKey,
    { columns = [], frozenColumns = [] }
) => {
    const allColumns = [...frozenColumns, ...columns]
    const collapsibleColumns = allColumns.filter(col => col.canCollapse)
    const sortedColumns = _.sortBy(collapsibleColumns, col => col.canPivot)
    const index = _.findIndex(sortedColumns, { key: dataKey })
    return sortedColumns[index + 1] // it's fine if this is undefined
}

// extract various field keys from a list of columns

const encodeKey = key => (_.isString(key) ? encodeAbbrevToInputName(key) : key)

export const getPivotNameKeys = columns =>
    columns
        .filter(col => col.canPivot)
        .map(col => col.key)
        .map(encodeKey)
export const getPivotIdKeys = columns =>
    columns
        .filter(col => col.canPivot)
        .map(col => col.idField)
        .map(encodeKey)
export const getOtherIdKeys = columns =>
    columns
        .filter(col => !col.canPivot && col.canCollapse)
        .map(col => col.idField)

export const getColumnKeys = columns => ({
    pivotNameKeys: getPivotNameKeys(columns),
    pivotIdKeys: getPivotIdKeys(columns),
    otherIdKeys: getOtherIdKeys(columns) // specifically other *collapsible* column ID keys
})

export const getCopyFields = ({ pivotIdKeys, pivotNameKeys }, depth) => [
    ..._.take(pivotIdKeys, depth),
    ..._.take(pivotNameKeys, depth)
]

export const addPath = (row, { otherIdKeys, pivotIdKeys }) => ({
    ...row,
    path: _.compact([
        ..._.at(row, pivotIdKeys),
        _.compact(_.at(row, otherIdKeys)).join("_")
    ]).map(encodeKey)
})

// various path manipulations

export const pathDepth = (paths = []) =>
    _.max([...paths].map(path => path.length)) || 0
export const stampDepth = (stamps = []) =>
    pathDepth([...stamps].map(stamp => stamp.split(".")))
export const rowPathDepth = (rows = []) =>
    pathDepth([...rows].map(row => row[PATH] || []))
// the [...] are to convert whatever iterable it is into an array

export const getRowClass = maxDepth => rowData => {
    const path = rowData[PATH] || []
    return {
        [`row-depth-${path.length}`]: true,
        [`row-level-${maxDepth - path.length}`]: maxDepth > 0
    }
}
