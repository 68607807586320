import { useEffect, useRef, useState } from "react"

export const useFocus = () => {
    const focusRef = useRef()
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        if (focused) {
            focusRef?.current?.focus?.()
        }
    }, [focused])

    return { focused, setFocus: () => setFocused(true), focusRef }
    // we could allow for unfocusing too fairly easily, but I don't think it's really necessary
}

export const useIncrease = (value, func) => {
    const ref = useRef(value)
    useEffect(() => {
        if (value > ref.current) {
            func()
        }
        ref.current = value
    }, [value])
}

const usePrevious = (value, initialValue) => {
    const ref = useRef(initialValue)
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const useEffectDebugger = (
    effectHook,
    dependencies,
    dependencyNames = [],
    identifier = ""
) => {
    const previousDeps = usePrevious(dependencies, [])

    const changedDeps = dependencies.reduce((accum, dependency, index) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency
                }
            }
        }

        return accum
    }, {})

    if (Object.keys(changedDeps).length) {
        console.log("[use-effect-debugger] ", identifier, changedDeps)
    }

    useEffect(effectHook, dependencies)
}
