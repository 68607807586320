import { delay } from "redux-saga"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import fileDownload from "js-file-download"
import moment from "moment"
import _ from "lodash"

import { getResourceData, modifyResource } from "../core/fetcher"
import { NAME as LETTER_NAME } from "../reminder_letter_details/constants"
import { PERIOD_MONTH_IDS } from "../pickers/constants"
import { ApiTypes, fetchAndCheck, tryFetch } from "../api"
import { getValues, joinPath } from "../utils"
import { TIMESTAMP_STRING_COMPACT } from "../dates"

import * as C from "./constants"
import {
    submitPrintError,
    submitPrintSuccess,
    updateBatchPrintPercentage
} from "./actions"
import { pocAssessmentsSelector } from "./selectors"
import { FetchError } from "../core/FetchError"
import { notifySimpleError } from "../notifications"

const getPrefix = payload => {
    if (payload[C.PRINT_FIELDS.POC_FORM]) {
        return payload[C.PRINT_FIELDS.ONLY_GAPS] ? "poc_abbrev" : "poc"
    } else if (payload[C.PRINT_FIELDS.ATTENTION]) {
        return "priority_attention"
    } else if (payload[C.PRINT_FIELDS.HISTORY]) {
        return "history"
    } else if (payload[C.PRINT_FIELDS.LETTER]) {
        return "letter"
    } else if (payload[C.PRINT_FIELDS.LINKAGES]) {
        return "linkages"
    } else if (payload[C.PRINT_FIELDS.COST]) {
        return "costs"
    } else if (payload[C.PRINT_FIELDS.CONDITIONS]) {
        return "conditions"
    } else if (payload[C.PRINT_FIELDS.FQHC]) {
        return "fqhc"
    } else {
        return null
    }
}

const getFileName = payload => {
    const timestamp = moment().format(TIMESTAMP_STRING_COMPACT)
    const number = [
        C.PRINT_FIELDS.POC_FORM,
        C.PRINT_FIELDS.ATTENTION,
        C.PRINT_FIELDS.HISTORY,
        C.PRINT_FIELDS.LINKAGES,
        C.PRINT_FIELDS.COST
    ].filter(key => payload[key]).length

    if (payload.patients.length !== 1) {
        const extension = payload[C.PRINT_FIELDS.SINGLE_PDF] ? "pdf" : "zip"
        return `report_${timestamp}.${extension}`
    }

    const patient = payload.patients.pop()
    const first = patient.first || patient.patFirstName // PoC print || batch print
    const last = patient.last || patient.patLastName // ditto
    const name_timestamp = `${first}_${last}_${timestamp}`

    const prefix = number > 1 ? "report" : getPrefix(payload)

    return prefix
        ? `${prefix}_${name_timestamp}.pdf`
        : `package_${timestamp}.pdf`
}

function* refreshReminderLetters() {
    const poc_id = yield select(state => state.poc_id)

    yield* tryFetch(
        {
            url: `/api/patients/${poc_id}/letters`
        },
        function*(body) {
            yield put(
                modifyResource({
                    name: LETTER_NAME,
                    dataTransform: () => body
                })
            )
        },
        null
        // no failure handler needed, we'll just have slightly older reminder letter data until the next reload
    )
}

function* checkBatchPrintStatus(location) {
    return yield* fetchAndCheck({
        url: joinPath(location.replace("/phs", ""), "status")
    }) // error will be caught in the containing function
}

function* submitPrint(allAssessments, { payload }) {
    const currentAssessments = payload[C.PRINT_FIELDS.ASSESSMENTS] || []
    const SECONDS = 2
    let progress = 0

    const hideMeasures = _.without(
        getValues(allAssessments),
        ...currentAssessments
    )
    const request = getRequest({ ...payload, hideMeasures })

    try {
        const location = yield* fetchAndCheck(request, {
            reader: res => res.headers?.get("location"),
            needsBody: false
        })

        while (progress < 100) {
            yield put(updateBatchPrintPercentage(progress))
            yield call(delay, SECONDS * 1000)
            const taskStatus = yield* checkBatchPrintStatus(location)
            progress = taskStatus.progress

            if (taskStatus.status === C.TASK_STATUS.FAILED) {
                throw FetchError.newFromTask(request, taskStatus, "Batch Print")
            }
        }

        // we don't use the api/downloadFile wrapper function here because that uses notifications. Batch print puts its progress messages in the modal instead.
        const blob = yield* fetchAndCheck(
            {
                url: location.replace("/phs", "")
            },
            { reader: res => res.blob() }
        )
        fileDownload(blob, getFileName(payload))
        yield put(submitPrintSuccess())
        if (payload[C.PRINT_FIELDS.LETTER]) {
            yield refreshReminderLetters()
        }
    } catch (error) {
        console.error(error.toString())
        yield notifySimpleError("Batch print failed.")(error)
        yield put(submitPrintError(error))
    }
}

export function* submitBatchPrint(action) {
    const allAssessments = yield select(state =>
        getResourceData(C.ALL_ASSESSMENTS)(state)
    )
    yield* submitPrint(allAssessments || [], action)
}

export function* submitPocPrint(action) {
    const allAssessments = yield select(state => pocAssessmentsSelector(state))
    yield* submitPrint(allAssessments || [], action)
}

export const getRequest = payload => {
    let body = {
        patientIds: getValues(payload.patients), // this will be overwritten if patientIds is already defined in payload, which is fine
        ...PERIOD_MONTH_IDS[payload[C.PRINT_FIELDS.COST_PERIOD]],
        ...payload
    }
    delete body.patients
    delete body[C.PRINT_FIELDS.ASSESSMENTS]
    delete body[C.PRINT_FIELDS.COST_PERIOD]

    return {
        url: "/api/batch",
        method: ApiTypes.POST,
        body
    }
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeEvery(C.SUBMIT_BATCH_PRINT, submitBatchPrint)
        }),
        call(function*() {
            yield takeEvery(C.SUBMIT_POC_PRINT, submitPocPrint)
        })
    ])
}
