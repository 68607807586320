import { all, call, put, select, takeLatest } from "redux-saga/effects"
import { push, replace } from "connected-react-router"
import moment from "moment"

import { transformAndFilterFilters } from "../transformFilter"
import { downloadFile, warnAboutPdfColumns } from "../api"
import { encodeFiltersInUrl, urlWithParams } from "../utils"
import { getSortedColumns } from "../selectorUtils"
import { TIMESTAMP_STRING_COMPACT } from "../dates"

import * as C from "./constants"

const getDetails = state => {
    const tableName =
        state.applied_filters[C.FILTERS.TYPE] === C.FILTER_TYPES.PATIENT_SUMMARY
            ? C.FILTER_TYPES.PATIENT_SUMMARY
            : C.FILTER_TYPES.PATIENT_MEASURES

    const filter = transformAndFilterFilters(state.applied_filters)
    const columns = getSortedColumns(tableName)(state)

    return { ...filter, ...columns }
}

function* requestReport(extension) {
    const details = yield select(getDetails)
    const name = (
        C.FILTER_TYPES_INVERTED[details.type] || "patient"
    ).toLowerCase()
    const endpoint = urlWithParams(`/api/patients/${extension}`, details)
    const filename = `${name}_${moment().format(
        TIMESTAMP_STRING_COMPACT
    )}.${extension}`

    yield* warnAboutPdfColumns(extension, details)
    yield* downloadFile(
        `Patient List ${extension.toUpperCase()}`,
        endpoint,
        filename
    )
}

export function* exportPatientList() {
    yield requestReport("csv")
}

export function* printPatientList() {
    yield requestReport("pdf")
}

export function* goToPatientFilters({ filters }) {
    const url = encodeFiltersInUrl("/patients", filters)

    if (location.pathname === "/patients") {
        yield put(replace(url))
    } else {
        yield put(push(url))
    }
}

export function* entrySaga() {
    yield all([
        call(function*() {
            yield takeLatest(C.GO_TO_PATIENT_FILTERS, goToPatientFilters)
        }),
        call(function*() {
            yield takeLatest(C.EXPORT_PATIENT_LIST, exportPatientList)
        }),
        call(function*() {
            yield takeLatest(C.PRINT_PATIENT_LIST, printPatientList)
        })
    ])
}
