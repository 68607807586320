import React, { Component, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { InputButton } from "../../core/CustomFields"
import FormModal from "../../core/FormModal"

const CommunityLinkageComment = props => {
    const [open, setOpen] = useState(false)
    return (
        <div>
            <InputButton
                {...props}
                placeholder="Comment..."
                onClick={() => setOpen(true)}
            />
            <CommentModal
                {...props}
                closeModal={() => setOpen(false)}
                open={open}
                key={props.value} // re-initialize if defaultValue changes
                defaultValue={props.value || ""}
            />
        </div>
    )
}
export default CommunityLinkageComment

class CommentModal extends Component {
    state = { value: this.props.value }

    saveAndClose = () => {
        this.props.onChange(this.state.value)
        this.props.closeModal()
    }

    updateText = e => {
        this.setState({ value: e.target.value })
    }

    render() {
        return (
            <FormModal
                show={this.props.open}
                onHide={this.props.closeModal}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Add Comment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <textarea
                                id="commentInput"
                                rows="5"
                                className="form-control"
                                style={{ resize: "none" }}
                                maxLength={5000}
                                onChange={this.updateText}
                                value={this.state.value}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        className="pull-left"
                        onClick={this.props.closeModal}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.saveAndClose}>
                        Update
                    </Button>
                </Modal.Footer>
            </FormModal>
        )
    }
}
