import { VIEWS } from "../pickers/constants"
import { PATH } from "../constants"
import { objToValueLabel } from "../utils"

export const CATEGORIES = {
    INPATIENT: "inpatient",
    OUTPATIENT: "outpatient",
    PROFESSIONAL: "professional",
    PHARMACY: "pharmacy",
    OTHER: "other"
}
export const CATEGORY_TRANSLATION = {
    [CATEGORIES.INPATIENT]: "Inpatient",
    [CATEGORIES.OUTPATIENT]: "Outpatient",
    [CATEGORIES.PROFESSIONAL]: "Professional",
    [CATEGORIES.PHARMACY]: "Pharmacy",
    [CATEGORIES.OTHER]: "Other"
}
export const CATEGORY_OPTIONS = [
    CATEGORIES.INPATIENT,
    CATEGORIES.OUTPATIENT,
    CATEGORIES.PROFESSIONAL,
    CATEGORIES.PHARMACY,
    CATEGORIES.OTHER
]
export const CATEGORY_COLOR_NAMES = {
    [CATEGORY_TRANSLATION[CATEGORIES.INPATIENT]]: "blue",
    [CATEGORY_TRANSLATION[CATEGORIES.OUTPATIENT]]: "orange",
    [CATEGORY_TRANSLATION[CATEGORIES.PROFESSIONAL]]: "green",
    [CATEGORY_TRANSLATION[CATEGORIES.PHARMACY]]: "red",
    [CATEGORY_TRANSLATION[CATEGORIES.OTHER]]: "purple"
}
export const CATEGORY_COLOR_CODES = {
    [CATEGORY_TRANSLATION[CATEGORIES.INPATIENT]]: "#4b8fdd",
    [CATEGORY_TRANSLATION[CATEGORIES.OUTPATIENT]]: "#ff995c",
    [CATEGORY_TRANSLATION[CATEGORIES.PROFESSIONAL]]: "#00c05d",
    [CATEGORY_TRANSLATION[CATEGORIES.PHARMACY]]: "#cc0033",
    [CATEGORY_TRANSLATION[CATEGORIES.OTHER]]: "#a659a6"
}

export const TABLE_KEYS = {
    DISPLAY_NAME: "displayName",
    CATEGORY_NAME: "categoryName",
    SUBCATEGORY_NAME: "subcategoryName",
    PAYER_NAME: "payerName",
    PRACTICE_NAME: "practiceUnitName",
    PROVIDER_NAME: "providerName",
    PRODUCT_NAME: "productClassName",
    CATEGORY_ID: "categoryId",
    SUBCATEGORY_ID: "subcategoryId",
    SERVICE_ID: "serviceId",
    PAYER_ID: "payerId",
    PRACTICE_ID: "practiceUnitId",
    PROVIDER_ID: "providerId",
    PRODUCT_ID: "productClassId",
    TOTAL_COST: "costTotal",
    TOTAL_ALLOCATION: "allocationTotal",
    TOTAL_REMAINING: "remainingTotal",
    PM_COST: "costPm",
    PMPM_COST: "costPmpm",
    PMPM_ALLOCATION: "allocationPmpm",
    PMPM_NETWORK: "networkCostPmpm",
    PATIENT_COUNT: "patientCount",
    EPISODE_COUNT: "episodeCount",
    EPISODES_PER_1000: "episodesPerThousand",
    TARGET: "target",
    PERFORMANCE: "performance",
    PATH
}
export const TABLE_TRANSLATION = {
    [TABLE_KEYS.DISPLAY_NAME]: "Category/Subcategory",
    [TABLE_KEYS.CATEGORY_NAME]: "Category",
    [TABLE_KEYS.SUBCATEGORY_NAME]: "Subcategory",
    [TABLE_KEYS.PAYER_NAME]: "Payer",
    [TABLE_KEYS.PRODUCT_NAME]: "Product",
    [TABLE_KEYS.PRACTICE_NAME]: "Practice",
    [TABLE_KEYS.PROVIDER_NAME]: "Provider",
    [TABLE_KEYS.TOTAL_COST]: "Total Cost",
    [TABLE_KEYS.TOTAL_ALLOCATION]: "Total Allocation",
    [TABLE_KEYS.TOTAL_REMAINING]: "Total Remaining",
    [TABLE_KEYS.PMPM_COST]: "Cost PMPM",
    [TABLE_KEYS.PMPM_ALLOCATION]: "Allocation PMPM",
    [TABLE_KEYS.PMPM_NETWORK]: "Network Cost PMPM",
    [TABLE_KEYS.PATIENT_COUNT]: "Member Months",
    [TABLE_KEYS.EPISODE_COUNT]: "Episodes",
    [TABLE_KEYS.EPISODES_PER_1000]: "Episodes per 1000",
    [TABLE_KEYS.TARGET]: "Target",
    [TABLE_KEYS.PERFORMANCE]: "Performance"
}
export const AGGREGATION_MEASURES = {
    [VIEWS.NETWORK]: [TABLE_KEYS.CATEGORY_ID, TABLE_KEYS.CATEGORY_NAME],
    [VIEWS.PRACTICE]: [TABLE_KEYS.PRACTICE_NAME],
    [VIEWS.PROVIDER]: [TABLE_KEYS.PROVIDER_NAME]
}
export const FILTERS = {
    PERIOD: "period",
    START_DATE: "startMonthId",
    END_DATE: "endMonthId",
    PAYERS: "payers",
    PRACTICE_UNITS: "practices",
    UNITS: "units", // this is only used when pulling from FILTER_OPTIONS
    REGIONS: "regions",
    FACILITY: "facilityIds",
    PROVIDERS: "providers",
    PRODUCTS: "productClasses",
    TAGS: "tags",
    CATEGORIES: "categories",
    SUBCATEGORIES: "subcategories",
    SERVICES: "services"
}
export const FILTER_TRANSLATION = {
    [FILTERS.PERIOD]: "Period",
    [FILTERS.START_DATE]: "Start Month",
    [FILTERS.END_DATE]: "End Month",
    [FILTERS.PAYERS]: "Payers",
    [FILTERS.PRACTICE_UNITS]: "Practice Units",
    [FILTERS.REGIONS]: "Practice Tags",
    [FILTERS.PROVIDERS]: "Primary Care Provider(s)",
    [FILTERS.PRODUCTS]: "Product Class",
    [FILTERS.TAGS]: "Tags",
    [FILTERS.CATEGORIES]: "Categories",
    [FILTERS.SUBCATEGORIES]: "Subcategories"
}

export const COST_VIEWS = { RELATIVE: "RELATIVE", ABSOLUTE: "ABSOLUTE" }
export const COST_VIEW_TRANSLATION = {
    [COST_VIEWS.RELATIVE]: "Relative",
    [COST_VIEWS.ABSOLUTE]: "Absolute"
}
export const COST_VIEW_TRANSLATION_PLAIN = {
    [COST_VIEWS.RELATIVE]: "Per Member",
    [COST_VIEWS.ABSOLUTE]: "Total"
}
export const COST_VIEW_OPTIONS = objToValueLabel(COST_VIEW_TRANSLATION)
export const COST_VIEW_OPTIONS_PLAIN = objToValueLabel(
    COST_VIEW_TRANSLATION_PLAIN
)
