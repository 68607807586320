import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import { conditionalParens, itemsToObject } from "../utils"
import * as AC from "../admin/constants"
import * as PC from "../permission_managment/constants"
import * as C from "./constants"
import {
    getExternalTooltip,
    getNewRow,
    getTagSourceText,
    getTopPrivilege,
    getTopTagPrivileges,
    getTopUserPrivileges
} from "./helpers"

const getLocks = users =>
    itemsToObject(users, C.TABLE_KEYS.USER_ID, user => {
        const nonManualPrivileges = _.filter(
            user.privileges,
            privilege =>
                privilege[C.TABLE_KEYS.SOURCE] !== AC.TAG_SOURCES.MANUAL
        )
        const topPrivilege = _.head(nonManualPrivileges) || {}
        const sourceText = getTagSourceText(topPrivilege)

        return {
            privilege: topPrivilege[C.TABLE_KEYS.PRIVILEGE],
            tooltip: sourceText.tooltip || topPrivilege[C.TABLE_KEYS.SOURCE]
        }
    })

// the selectors

export const editTagInitialValuesSelector = createSelector(
    getResourceData(C.USER_TAGS_TABLE, data => data.tags),
    state => state[C.NAME],
    state => state.authentication,
    (tags = [], { currentTag = {} }, authentication) =>
        currentTag[C.TABLE_KEYS.TAG_ID] === C.NEW_TAG_ID
            ? {
                  [C.FORM_FIELDS.TAG_NAME]: "",
                  ...getNewRow(authentication)
              }
            : tags.find(
                  tag =>
                      tag[C.TABLE_KEYS.TAG_ID] ===
                      currentTag[C.TABLE_KEYS.TAG_ID]
              ) || {}
)

export const userTagsSelector = createSelector(
    getResourceData(C.USER_TAGS_TABLE, data => data.tags),
    tags => getTopTagPrivileges(_.filter(tags, C.TABLE_KEYS.PRIVILEGE))
)

export const userTagPrivilegeLockSelector = createSelector(
    getResourceData(C.TAG_DETAILS, data => data.users),
    (users = []) => {
        const topUserPrivileges = getTopUserPrivileges(users)
        const locks = getLocks(users)

        return itemsToObject(topUserPrivileges, C.TABLE_KEYS.USER_ID, user => {
            const lock = locks[user[C.TABLE_KEYS.USER_ID]] || {}

            // 'no privilege' is locked if you have any forced privilege at all
            const noneLock = lock.tooltip

            // read is only locked if you're forced into EDIT privilege
            const readLock =
                lock.privilege === PC.ACCESS_PRIVILEGES.EDIT
                    ? lock.tooltip
                    : undefined

            // edit is locked if you're forced into EDIT privilege, or if you're an external user
            const editLock =
                readLock ||
                (user[C.TABLE_KEYS.ACCESS_LEVEL] === AC.EXTERNAL_VALUE &&
                    getExternalTooltip())

            return {
                privilege: user[C.TABLE_KEYS.PRIVILEGE],
                [undefined]: noneLock,
                [PC.ACCESS_PRIVILEGES.READ]: readLock,
                [PC.ACCESS_PRIVILEGES.EDIT]: editLock
            }
        })
    }
)

export const tagUsersSelector = createSelector(
    getResourceData(C.TAG_DETAILS, data => data.users),
    userTagPrivilegeLockSelector,
    (users = [], privilegeLocks = {}) =>
        _.sortBy(
            getTopUserPrivileges(users).map(user => {
                const { privilege, ...disabled } =
                    privilegeLocks[user[C.TABLE_KEYS.USER_ID]] || {}
                return {
                    id: user[C.TABLE_KEYS.USER_ID],
                    label: `${user[C.TABLE_KEYS.USER]} ${conditionalParens(
                        user[C.TABLE_KEYS.PRACTICE] || ""
                    )}`,
                    privilege: getTopPrivilege(
                        privilege,
                        user[C.TABLE_KEYS.PRIVILEGE]
                    ),
                    disabled
                }
            }),
            C.TABLE_KEYS.USER
        )
)

export const tagPracticesSelector = createSelector(
    getResourceData(C.TAG_DETAILS, data => data.practices),
    (practices = []) =>
        practices.map(practice => ({
            id: practice[C.TABLE_KEYS.PRACTICE_ID],
            label: practice[C.TABLE_KEYS.PRACTICE_NAME],
            privilege: PC.ACCESS_PRIVILEGES[practice[C.TABLE_KEYS.PRIVILEGE]]
        }))
)

export const tagUserInitialValuesSelector = createSelector(
    tagUsersSelector,
    tagPracticesSelector,
    (users, practices) => {
        return {
            [C.FORM_FIELDS.USER_PRIVILEGES]: users,
            [C.FORM_FIELDS.PRACTICE_PRIVILEGES]: practices
        }
    }
)

export const userCanEditTagSelector = createSelector(
    state => state[C.NAME],
    getResourceData(C.USER_TAGS_TABLE, data => data.tags),
    ({ currentTag }, tags) =>
        currentTag?.[C.TABLE_KEYS.TAG_ID] === C.NEW_TAG_ID ||
        tags?.some(
            tag =>
                tag[C.TABLE_KEYS.TAG_ID] ===
                    currentTag?.[C.TABLE_KEYS.TAG_ID] &&
                tag[C.TABLE_KEYS.PRIVILEGE] === PC.ACCESS_PRIVILEGES.EDIT
        )
)
