import { createReducer } from "../utils"
import * as PC from "../patient_list/constants"
import * as C from "./constants"

export const reducer = createReducer(
    { measureAbbrevName: null, filters: PC.initialValues },
    {
        [C.SET_CM_MEASURE]: (state, { measureAbbrevName }) => ({
            ...state,
            measureAbbrevName
        }),
        [C.SET_CM_PATIENT_FILTERS]: (state, { filters }) => ({
            ...state,
            filters
        })
    }
)

export default reducer
