import React, { useCallback, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { change, Fields, getFormValues } from "redux-form"
import classNames from "classnames"
import _ from "lodash"
import { authTokensSelector } from "../../../../authentication/selectors"
import { Saving } from "../../../../core/Loading"
import {
    EMPTY_OBJECT,
    findByValue,
    isNilOrBlank,
    isNumeric,
    itemsToObject,
    joinTruthy
} from "../../../../utils"
import {
    getAutocalc,
    getInputDateName,
    getSurveySubmeasureInputName
} from "../../../helpers"
import { submeasureAbbrevMapSelector } from "../../../selectors"
import * as C from "../../../constants"
import { SimpleMeasureInput } from "./MeasureInput"

const NO_RESULT = "Answer all questions to see the result"
const INVALID_VALUE = "Invalid value"
const LOADING = <Saving alt="Loading..." />

const getEnumerationResult = (resultSubmeasure, value) => {
    const trueValue = isNumeric(value) ? +value : value

    const result =
        findByValue(resultSubmeasure.dropListOptions, trueValue) ?? value
    const max = _.last(resultSubmeasure.dropListOptions)?.value

    const displayValue = isNumeric(value)
        ? joinTruthy([value, max], " / ")
        : null

    const display = joinTruthy([result?.name, displayValue], " – ")

    return { id: result?.id ?? value, value, display }
}

const sumQuestionValues = questionValues =>
    _.sum(_.values(questionValues).map(Number))

export const SurveyFields = props => {
    const [termValues, setTermValues] = useState([])
    const [resultValue, setResultValue] = useState(undefined) // undefined means not yet loaded, null means no value
    const [resultText, setResultText] = useState(NO_RESULT)

    const getSubmeasureName = useMemo(
        () => getSurveySubmeasureInputName(props.parentAbbrev),
        [props.parentAbbrev]
    )

    const [resultSubmeasures, questionSubmeasures] = useMemo(
        () => _.partition(props.subMeasures, sm => sm.autoCalc),
        [props.subMeasures]
    )

    const resultSubmeasure = _.head(resultSubmeasures) //should only have one item

    const updateResult = useMemo(() => {
        if (!resultSubmeasure) {
            return null
        }
        const resultName = getSubmeasureName(resultSubmeasure)
        return props[resultName]?.input.onChange ?? null
    }, [props, getSubmeasureName, resultSubmeasure])

    const questionValues = useMemo(() => {
        const questionNames = questionSubmeasures.map(getSubmeasureName)
        return itemsToObject(
            questionNames,
            _.identity,
            name => props[name]?.input?.value
        )
    }, [props, questionSubmeasures, getSubmeasureName])

    const observationDate = useMemo(
        () => props.formValues[getInputDateName(props.parentAbbrev)],
        [props.formValues, props.parentAbbrev]
    )

    const shouldShowResults = useMemo(
        () =>
            _.every(_.values(questionValues), result => !isNilOrBlank(result)),
        [termValues] // updating on termValues so we don't double up on the expensive getAutocalc update later
    )

    // track values for autocalc terms
    useEffect(() => {
        if (!resultSubmeasure) {
            return
        }

        const autocalcNames = _.map(resultSubmeasure.requiredMeasures, abbrev =>
            getSubmeasureName(props.abbrevLookup[abbrev])
        )
        const newTermValues = _.at(questionValues, autocalcNames)

        if (!_.isEqual(termValues, newTermValues)) {
            setTermValues(newTermValues)
        }
    }, [
        props.abbrevLookup,
        resultSubmeasure,
        getSubmeasureName,
        questionValues
    ])

    // fetch auto-calculated survey result
    useEffect(() => {
        if (!resultSubmeasure) {
            return
        }

        if (!shouldShowResults) {
            setResultValue(undefined)
            return
        }

        const promise = getAutocalc(
            props.patientId,
            resultSubmeasure.abbrevName,
            termValues,
            props.authentication,
            observationDate
        )

        Promise.resolve(promise).then(({ value }) => {
            setResultValue(value)
        })
    }, [
        props.patientId,
        props.authentication,
        observationDate,
        shouldShowResults,
        termValues,
        resultSubmeasure,
        setResultValue
    ])

    // calculate final result
    const getResult = useCallback(
        (resultValue, questionValues) => {
            if (!resultSubmeasure) {
                return {}
            }

            if (resultSubmeasure.dataType === C.ENUMERATION) {
                const value = resultValue ?? sumQuestionValues(questionValues)
                return getEnumerationResult(resultSubmeasure, value)
            } else if (resultSubmeasure.dataType === C.CHECKBOX) {
                const value = resultValue ?? _.every(questionValues)
                return {
                    id: !!value,
                    value,
                    display: (
                        <input
                            type="checkbox"
                            checked={value}
                            disabled={true}
                            readOnly
                        />
                    )
                }
            } else {
                const value = resultValue ?? sumQuestionValues(questionValues)
                return { id: value, value, display: value }
            }
        },
        [props.patientId, props.authentication, resultSubmeasure]
    )

    // display final result
    useEffect(() => {
        if (!resultSubmeasure) {
            return
        }
        if (!shouldShowResults) {
            if (resultValue === undefined) return // doesn't need an update
            updateResult(undefined)
            setResultText(NO_RESULT)
            return
        }

        if (resultValue === undefined) {
            // undefined means it's loading. null means the backend returned no value
            setResultText(LOADING)
            return
        }

        const result = getResult(resultValue, questionValues)

        if (!result.id) {
            console.error(
                'Invalid result value for survey "',
                props.desc + '":',
                result.value
            )
            updateResult(INVALID_VALUE)
            setResultText(INVALID_VALUE)
            return
        }

        updateResult(result.value)
        setResultText(result.display)
    }, [
        props.desc,
        shouldShowResults,
        questionValues,
        resultValue,
        getResult,
        setResultText,
        updateResult
    ])

    return (
        <>
            <p className="survey-title">{props.desc}</p>
            {questionSubmeasures.map(subMeasure => (
                <div key={subMeasure.id} className="survey-question">
                    <div className="question">{subMeasure.name}</div>

                    <SimpleMeasureInput
                        measure={subMeasure}
                        nameGetter={getSurveySubmeasureInputName(
                            props.abbrevName
                        )}
                        valueField="value"
                    />
                </div>
            ))}
            {resultSubmeasure && (
                <div className="survey-question">
                    <div className="question">{resultSubmeasure.name}</div>
                    <div>{resultText}</div>
                </div>
            )}
        </>
    )
}

export const Survey = props => {
    const { subMeasures, change, disabled, abbrevName } = props

    const [open, setOpen] = useState(false)

    return (
        <div
            className={classNames("survey-measure", {
                disabled
            })}
        >
            <span
                className="survey-toggle fa fa-pencil"
                onClick={() => disabled || setOpen(!open)}
            />
            <SimpleMeasureInput measure={props} />
            <div className="survey" style={open ? {} : { display: "none" }}>
                <Fields
                    {...props}
                    reduxFormChange={change}
                    parentAbbrev={abbrevName}
                    subMeasures={subMeasures}
                    names={subMeasures.map(
                        getSurveySubmeasureInputName(abbrevName)
                    )}
                    component={SurveyFields}
                />
            </div>
        </div>
    )
}

export default connect(
    (state, { subMeasures, abbrevName }) => ({
        formValues: getFormValues(C.POC_MEASURE_FORM)(state) || EMPTY_OBJECT,
        abbrevLookup: submeasureAbbrevMapSelector(subMeasures),
        authentication: authTokensSelector(state),
        patientId: state.poc_id
    }),
    { change }
)(Survey)
