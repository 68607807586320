import {
    DollarsCents,
    LongNumber,
    Percent,
    PivotCell
} from "../core/CellRenderers"
import * as PLC from "../patient_list/constants"
import { columnBuilder } from "../table"
import { formatLongNumber } from "../numbers"
import { MODULE_IDS } from "../constants"
import * as C from "./constants"
import QsrMeasureListLink from "./components/QsrMeasureListLink"
import MeasurePivotValue from "./components/MeasurePivotValue"
import ProductGroupCell from "./components/ProductGroupCell"

const column = columnBuilder(C.TABLE_TRANSLATION)

export const QSR_COLUMNS = [
    column(C.TABLE_KEYS.MEASURE_NAME, {
        cellRenderer: MeasurePivotValue,
        idField: C.TABLE_KEYS.MEASURE_ABBREV,
        filterField: PLC.FILTERS.MEASURES,
        canCollapse: true,
        canPivot: true,
        forceSelected: true,
        width: 400,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PAYER_NAME, {
        cellRenderer: PivotCell,
        idField: C.TABLE_KEYS.PAYER_ID,
        filterField: PLC.FILTERS.PAYERS,
        canCollapse: true,
        canPivot: true,
        forceSelected: true,
        width: 170,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.INCENTIVE_PROGRAM_NAME, {
        cellRenderer: PivotCell,
        idField: C.TABLE_KEYS.INCENTIVE_PROGRAM_ID,
        filterField: PLC.FILTERS.INCENTIVE_PROGRAMS,
        canCollapse: true,
        canPivot: true,
        forceSelected: true,
        width: 170,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PRODUCT_CLASS_NAME, {
        cellRenderer: PivotCell,
        idField: C.TABLE_KEYS.PRODUCT_ID,
        filterField: PLC.FILTERS.PRODUCTS,
        canCollapse: true,
        canPivot: true,
        forceSelected: true,
        width: 120,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PRODUCT_GROUP_NAME, {
        cellRenderer: ProductGroupCell,
        idField: C.TABLE_KEYS.PRODUCT_GROUP_ID,
        filterField: PLC.FILTERS.PRODUCT_GROUPS,
        canCollapse: true,
        canPivot: true,
        selected: false,
        width: 120,
        flexGrow: 0.5
    }),
    column(C.TABLE_KEYS.PRACTICE_NAME, {
        cellRenderer: PivotCell,
        idField: C.TABLE_KEYS.PRACTICE_ID,
        filterField: PLC.FILTERS.PRACTICE_UNITS,
        canCollapse: true,
        canPivot: true,
        forceSelected: true,
        width: 220,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PROVIDER_NAME, {
        cellRenderer: PivotCell,
        idField: C.TABLE_KEYS.PROVIDER_ID,
        filterField: PLC.FILTERS.PROVIDERS,
        canCollapse: true,
        canPivot: true,
        forceSelected: true,
        width: 200,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.ELIGIBLE, {
        cellRenderer: LongNumber,
        width: 120
    }),
    column(C.TABLE_KEYS.TARGET, {
        cellRenderer: LongNumber,
        width: 110
    }),
    column(C.TABLE_KEYS.MET, {
        cellFormatter: formatLongNumber,
        cellRenderer: QsrMeasureListLink,
        columnData: {
            extraFilters: {
                [PLC.FILTERS.COMPLIANT]: "M",
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_MEASURES
            }
        },
        width: 60
    }),
    column(C.TABLE_KEYS.NEEDED_FOR_GOAL, {
        dataGetter: ({ rowData }) =>
            Math.max(
                0,
                rowData[C.TABLE_KEYS.TARGET] - rowData[C.TABLE_KEYS.MET]
            ),
        cellFormatter: formatLongNumber,
        cellRenderer: QsrMeasureListLink,
        columnData: {
            extraFilters: {
                [PLC.FILTERS.COMPLIANT]: "X",
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_MEASURES
            }
        },
        width: 140,
        className: "orange-highlight",
        headerClassName: "orange-highlight"
    }),
    column(C.TABLE_KEYS.GOAL_RATE, {
        cellRenderer: Percent,
        width: 100
    }),
    column(C.TABLE_KEYS.RATE_TO_DATE, {
        cellRenderer: Percent,
        width: 110
    }),
    column(C.TABLE_KEYS.HVPA_RATE, {
        cellRenderer: Percent,
        width: 120
    }),
    column(C.TABLE_KEYS.PAYOUT_AVAILABLE, {
        cellRenderer: DollarsCents,
        width: 90,
        module: MODULE_IDS.INCENTIVE_TRACKING
    }),
    column(C.TABLE_KEYS.PAYOUT_EARNED, {
        cellRenderer: DollarsCents,
        width: 80,
        module: MODULE_IDS.INCENTIVE_TRACKING
    }),
    column(C.TABLE_KEYS.PAYOUT_REMAINING, {
        cellRenderer: DollarsCents,
        width: 100,
        module: MODULE_IDS.INCENTIVE_TRACKING
    }),
    column(C.TABLE_KEYS.INCENTIVE_DIFFERENCE, {
        width: 175,
        cellFormatter: formatLongNumber,
        cellRenderer: QsrMeasureListLink,
        columnData: {
            extraFilters: {
                [PLC.FILTERS.INCENTIVE_STATUS]: ["MX"],
                [PLC.FILTERS.TYPE]: PLC.FILTER_TYPES.PATIENT_MEASURES
            }
        },
        selected: false,
        module: MODULE_IDS.INCENTIVE_TRACKING
    })
]

export const COVERAGE_TABLE_COLUMNS = [
    {
        selected: true,
        label: "Last Load Date",
        key: C.TABLE_KEYS.LAST_LOAD_DATE,
        width: 150,
        flexGrow: 1
    },
    {
        selected: true,
        label: "Service Through Date",
        key: C.TABLE_KEYS.SERVICE_THROUGH_DATE,
        width: 170,
        flexGrow: 1
    },
    {
        selected: true,
        label: "Type",
        key: C.TABLE_KEYS.COVERAGE_TYPE,
        width: 150,
        flexGrow: 1
    },
    {
        selected: true,
        label: "Source",
        key: C.TABLE_KEYS.COVERAGE_NAME,
        width: 150,
        flexGrow: 1
    },
    {
        selected: true,
        label: "Description",
        key: C.TABLE_KEYS.COVERAGE_DESCRIPTION,
        width: 150,
        flexGrow: 1
    }
]
