// noinspection JSUnusedGlobalSymbols

import moment from "moment"
import _ from "lodash"
import * as emailValidator from "email-validator"
import TRANSLATIONS from "../translation"
import { DATE_STRING, EARLIEST_DATE, parseDate } from "../dates"
import { callIfPossible, joinTruthy } from "../utils"

// HELPERS //

// WARNING: if you want to use a validator with a field with a custom label (i.e. you've passed the 'label' prop to the field), the label will just get slapped in front of the error message. That's usually fine, but if you want finer control you'll need to use a custom validator or pass in a translation map at the form level.

/* BIGGER WARNING: If you try to use a validator that takes an argument (e.g. minLength), then either the argument should be a constant, or the validator+argument should be set in a useMemo.
 *
 * If you don't, changing the field value in certain circumstances will recreate the validator function, causing the form validation to rerun, causing the component to rerender, which recreates the validator function again. The infinite loop then crashes your page.
 *
 * so e.g. this is bad:
 *      <Field {...} validate={minLength(props.list.length)} />
 * but this is fine:
 *      <Field {...} validate={minLength(5)} />
 * and this is fine:
 *      validator = useMemo(() => minLength(props.list.length), [props.list])
 *      ...
 *      <Field {...} validate={validator} />
 *
 * This is bad because the array gets reinitialized each time:
 *      <Field {...} validate={matchesAny([5, 6, 7])} />
 * But this is ok:
 *      <Field {...} validate={matchesAny(5, 6, 7)} />
 * so write your validators appropriately
 *
 * If you want a validator that depends on other fields' values, the second argument ("allValues") passed to each validator contains all of those.
 */

export const localizeValidator = validator => (
    value,
    allValues,
    props,
    name
) => {
    if (!props || !props.form) {
        // if there's no form provided to get a translation for, don't worry about it
        return validator(value, allValues, props, name)
    }
    const label =
        _.get(TRANSLATIONS[props.form], name) || // need to use _.get because name could be in "a.b" form
        _.get(props.translation, name) ||
        ""
    // if there's no translation available in the global TRANSLATIONS, we're either supplying it directly to the form (via the `translation` prop) or adding it later

    const error = validator(value, allValues, props, name)
    return error && joinTruthy([label, error], " ")
}

// use this one if you want to pass arguments to the validator before it tests a value
// e.g. maxLength(10)
export const localizeValidatorWithArgs = validator =>
    _.memoize((...args) => localizeValidator(validator(...args))) // using _.memoize instead of memoizeOne because we do actually have to keep track of all of the arguments.

// for recording errors manually as part of a full-form validation function
export const errorRecorder = (errors, values, props) => (validators, name) => {
    for (const validator of validators) {
        const message = validator(values[name], values, props, name)
        if (message) {
            errors[name] = message
            return
        }
    }
}

const getFieldLabel = (props, fieldName) =>
    TRANSLATIONS[props.form]?.[fieldName]

// ACTUAL VALIDATORS //

export const required = localizeValidator(value =>
    !_.isEmpty(value) || _.isFinite(value) || _.isBoolean(value)
        ? undefined
        : "is required"
)
export const conditionalRequired = localizeValidator(required => value =>
    !required || !_.isEmpty(value) || _.isFinite(value) || _.isBoolean(value)
        ? undefined
        : "is required"
)
export const mustBeANumber = localizeValidator(value =>
    value && isNaN(Number(value)) ? "must be a number" : undefined
)
export const minVal = localizeValidatorWithArgs((min = -2147483648) => value =>
    value && Number(value) < Number(min) ? `must be at least ${min}` : undefined
)
export const maxVal = localizeValidatorWithArgs((max = 2147483647) => value =>
    value && Number(value) > Number(max) ? `must be at most ${max}` : undefined
)

export const maxLength = localizeValidatorWithArgs(
    (max = 2147483647) => value =>
        value && value.length > max
            ? `must be ${max} characters or fewer`
            : undefined
)
export const minLength = localizeValidatorWithArgs(
    (min = -2147483648) => value =>
        value && value.length < min
            ? `must be ${min} characters or more`
            : undefined
)
export const fixedDigits = localizeValidatorWithArgs(length => value =>
    value && value.length !== length
        ? `must be exactly ${length} digits`
        : undefined
)
export const alphanumeric = localizeValidator(value =>
    /^\w+$/.test(value)
        ? undefined
        : "must contain only letters, numbers, and underscores"
)

export const lessThanField = localizeValidatorWithArgs(
    fieldName => (value, allValues, props) =>
        !value || !allValues[fieldName] || value <= allValues[fieldName]
            ? undefined
            : `must not be greater than ${getFieldLabel(props, fieldName)}`
)
export const moreThanField = localizeValidatorWithArgs(
    fieldName => (value, allValues, props) =>
        !value || !allValues[fieldName] || value >= allValues[fieldName]
            ? undefined
            : `must not be smaller than ${getFieldLabel(props, fieldName)}`
)

export const reasonableVariance = localizeValidatorWithArgs(
    (factor, lastValue, adjective = "significantly") => value =>
        !!value &&
        !!factor &&
        !!lastValue &&
        !_.inRange(value, lastValue / factor, lastValue * factor) &&
        `is ${adjective} ${
            value < lastValue ? "lower" : "higher"
        } than its last recorded value. Verify your numbers.`
)

export const validEmail = localizeValidator(value =>
    !value || emailValidator.validate(value)
        ? undefined
        : "is not a valid email address"
)

export const validPhoneNumber = localizeValidator(
    value =>
        mustBeANumber(value) ||
        (!value || [7, 10, 11].includes(value.length)
            ? undefined
            : "is not a valid phone number")
) // XXX wait what about extensions? Might not some phone numbers include those

export const validFuzzySearch = localizeValidator(value =>
    !value || /^[-a-zA-Z0-9 ',;"]+$/i.test(value)
        ? undefined
        : "Please enter only patient names and/or contract numbers."
)

export const notInList = localizeValidatorWithArgs(items => value =>
    _.includes(items, value) ? "is already in use" : undefined
)

// DATES //

export const validDateInFormat = localizeValidatorWithArgs(
    dateFormat => value =>
        !value || moment(value, dateFormat, true).isValid()
            ? undefined
            : `must be ${dateFormat}`
)

export const validDate = validDateInFormat(DATE_STRING) // TODO this is probably redundant in most cases

const compareDates = (
    startDate, // start and end date can be either moment dates or date strings in DATE_FORMAT
    endDate,
    {
        dateFormat = DATE_STRING,
        startDateFormat = dateFormat,
        endDateFormat = dateFormat,
        errorMessage = "is in wrong span",
        exactTime = false
    } = {}
) => {
    if (!startDate || !endDate) {
        return undefined
    }

    // sometimes a date will be a function, if say we need an up-to-date value for the current time
    const startTime = parseDate(callIfPossible(startDate), startDateFormat)
    const endTime = parseDate(callIfPossible(endDate), endDateFormat)

    if (!startTime || !endTime) {
        return `must be ${dateFormat}`
    }

    if (!exactTime) {
        startTime.startOf("day")
        endTime.startOf("day")
    }

    return startTime.isSameOrBefore(endTime) ? undefined : errorMessage
}

export const beforeDate = localizeValidatorWithArgs((date, options) => value =>
    compareDates(value, date, {
        errorMessage: "must not be in the future",
        ...options
    })
)

export const afterDate = localizeValidatorWithArgs((date, options) => value =>
    compareDates(date, value, {
        errorMessage: "must not be in the past",
        ...options
    })
)

export const after1900 = afterDate(EARLIEST_DATE, {
    errorMessage: `must be after ${EARLIEST_DATE}`
})

export const todayOrBefore = beforeDate(() => moment()) // always want an up-to-date today, instead of one set when the page loaded
export const todayOrAfter = afterDate(() => moment())

export const beforeDateField = localizeValidatorWithArgs(
    (endField, options) => (value, formValues, props) =>
        compareDates(value, _.get(formValues, endField), {
            errorMessage: `must not be after ${getFieldLabel(props, endField) ||
                "end date"}`,
            ...options
        })
)

export const afterDateField = localizeValidatorWithArgs(
    (startField, options) => (value, formValues, props) =>
        compareDates(_.get(formValues, startField), value, {
            errorMessage: `must not be before ${getFieldLabel(
                props,
                startField
            ) || "start date"}`,
            ...options
        })
)
