import React from "react"
import { FormGroup } from "react-bootstrap"
import classNames from "classnames"

export const CheckboxField = ({ input, style, className }) => (
    <FormGroup style={style} className={classNames("checkbox", className)}>
        {input.value && (
            <label>
                <input
                    type="checkbox"
                    {...input}
                    checked={true}
                    disabled={true} // This input is actually supposed to represent a unary value; it should never be able to be manually unchecked.
                    readOnly
                />
                Complete
            </label>
        )}
    </FormGroup>
)

export const NumericField = ({
    input,
    unitLabel,
    placeholder,
    subMeasure,
    style,
    className,
    disabled,
    meta: { error }
}) => (
    <FormGroup
        style={style}
        className={classNames(className, {
            filter_field_error: error,
            active_filter_field_input: !error && input.value !== ""
        })}
    >
        <div className="input-group">
            <input
                {...input}
                disabled={disabled}
                style={subMeasure ? { borderRadius: "4px" } : {}}
                className="form-control"
                maxLength={200}
                placeholder={placeholder}
            />
            {!subMeasure && unitLabel && (
                <div className="input-group-append">
                    <span className="input-group-text">{unitLabel}</span>
                </div>
            )}
        </div>
    </FormGroup>
)
