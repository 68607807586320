import _ from "lodash"
import { joinTruthy } from "../utils"

const createErrorMessage = (status, error) => {
    switch (status) {
        // TODO more cases
        case 408:
        case 504:
            return "The request timed out."
        default:
            return "The server did not send an intelligible response."
    }
}

export class FetchError extends Error {
    // TODO this class desperately needs testing
    name = "FetchError"

    // Don't actually use the constructor to create a FetchError. Instead, use FetchError.new().
    // Unless you want to initialize everything yourself, I guess.
    constructor(request, response) {
        super()
        this.request = request
        this.response = response
        this.body = undefined
        this.status = undefined
        this.message = undefined
        this.debugMessage = undefined
        this.subErrors = undefined

        if (!response) {
            this.response = {}
            this.message = "The server did not send a response."
        }
    }

    // Use this generator method to create new FetchErrors. This is because we need to read the response body, which is an async operation.
    static new = function*(request, response) {
        const fetchError = new FetchError(request, response)
        yield fetchError.initMessage()
        return fetchError
    }

    static newFromTask = (request, taskResponse, taskName) => {
        const fetchError = new FetchError(request, taskResponse)
        fetchError.message = taskResponse.message
        fetchError.debugMessage = taskResponse.debugMessage
        fetchError.status = `${taskName} task ${taskResponse.taskId} failed at ${taskResponse.progress}%` // XXX this message could be better perhaps
        throw fetchError
    }

    /** Can't initialize a message in the constructor, because reading the
     *  response body with .json() is an asynchronous operation. Instead, do
     * `yield fetchError.initMessage()` right after the error is instantiated.
     */
    initMessage = function*() {
        if (this.message) {
            // message has already been instantiated
            return
        }

        const { url, status, statusText } = this.response
        this.status = `${this.request.method} ${url} ${status} (${statusText})`

        try {
            this.body = yield this.response.json()
        } catch (error) {
            this.message = createErrorMessage(status, error)
            return
        }
        this.message = this.body.message
        this.debugMessage = this.body.debugMessage
        this.subErrors = this.body.subErrors
    }

    getFullMessage = () => {
        if (this.message === this.debugMessage) {
            return this.message
        }
        if (".:-".includes(_.last(this.message.trimRight()))) {
            // don't need to insert a separator if there already is one
            return joinTruthy([this.message, this.debugMessage])
        }
        return joinTruthy([this.message, this.debugMessage], " - ")
    }

    getSubErrorsText = () =>
        _.isEmpty(this.subErrors)
            ? ""
            : this.subErrors.map(error => `\n - ${error.message}`).join("")

    toString = () =>
        joinTruthy(
            [
                this.name,
                this.status,
                this.getFullMessage(),
                this.getSubErrorsText()
            ],
            ": "
        )
}
