import { Button } from "react-bootstrap"
import React from "react"

export const PrintPocButton = ({ onClick, title }) => (
    <Button
        variant="primary"
        size="sm"
        className="pull-right poc-print"
        onClick={onClick}
    >
        <span className="fa fa-print" /> {title}
    </Button>
)
