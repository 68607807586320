import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import { measuresMapSelector } from "../measures/selectors"
import * as QC from "../qsr/constants"
import * as C from "./constants"

export const QsrDashboardSelector = createSelector(
    getResourceData(C.NAME),
    measuresMapSelector,
    data =>
        _.map(data.qsrs, (qsr, measuresMap) => ({
            ...qsr,
            link: _.get(measuresMap, [
                qsr[QC.TABLE_KEYS.MEASURE_ID],
                QC.TABLE_KEYS.LINK
            ])
        }))
)
