import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, submit } from "redux-form"
import { Button, Modal } from "react-bootstrap"
import _ from "lodash"
import FormModal from "../../../core/FormModal"
import { getAccessLevelSelector } from "../../helperSelectors"
import {
    addUserInitialValuesSelector,
    userModalConnectSelector
} from "../../modalSelectors"
import * as C from "../../constants"
import * as actions from "../../actions"
import { getUserTitle } from "./helpers"
import { validateAddUser } from "./validateUser"
import { UserModalTabs } from "./UserModalTabs"

const PAGE_COUNT = 4

class AddUserModal extends Component {
    state = {
        page: 1,
        furthestPage: 1
    }

    nextPage = () => {
        if (this.state.page < PAGE_COUNT) {
            const page = this.state.page + 1
            this.setState({
                page,
                furthestPage: this.getFurthestPage(page)
            })
        }
    }

    previousPage = () => {
        if (this.state.page > 1) {
            this.setState({ page: this.state.page - 1 })
        }
    }

    goToPage = rawPage => {
        const page = Number(rawPage)
        this.setState({
            page,
            furthestPage: this.getFurthestPage(page)
        })
    }

    onSubmit = dispatch => () => {
        dispatch(submit(C.ADD_USER_FORM))
    }

    onPrev = () =>
        this.props.selectedUser[C.USER_FIELDS.IS_EXTERNAL]
            ? this.goToPage(1)
            : this.previousPage()

    onNext = clickEvent => {
        if (this.state.page >= PAGE_COUNT) {
            this.props.handleSubmit(clickEvent)
        } else if (this.props.selectedUser[C.USER_FIELDS.IS_EXTERNAL]) {
            this.goToPage(4)
        } else {
            this.nextPage()
        }
    }

    nextButtonDisabled = () =>
        _.some(this.props.invalidTabs, tab => tab <= this.state.page)

    getFurthestPage = page => Math.max(page, this.state.furthestPage)

    render() {
        const { page } = this.state
        const {
            show,
            loading,
            saving,
            selectedUser,
            hideAddUserModal
        } = this.props

        return (
            <FormModal
                dialogClassName="small-modal admin-modal"
                show={show}
                onHide={hideAddUserModal}
            >
                <form autoComplete="off">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Add User {getUserTitle(selectedUser)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserModalTabs
                            {...this.props}
                            activeKey={page}
                            onSelect={this.goToPage}
                            disableCondition={eventKey =>
                                this.state.furthestPage < eventKey
                            }
                            newUser
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            className="pull-left"
                            onClick={hideAddUserModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={page <= 1 || loading || saving}
                            variant="primary"
                            className="pull-right"
                            onClick={this.onPrev}
                        >
                            Prev
                        </Button>
                        <Button
                            variant="primary"
                            className="pull-right"
                            onClick={this.onNext}
                            disabled={this.nextButtonDisabled()}
                        >
                            {page === PAGE_COUNT ? "Save" : "Next"}
                        </Button>
                    </Modal.Footer>
                </form>
            </FormModal>
        )
    }
}

const AddUserModalForm = reduxForm({
    form: C.ADD_USER_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: validateAddUser
})(AddUserModal)

// unmount the form when the modal is closed, or else it won't revalidate properly
const AddUserModalWrapper = props =>
    props.show ? <AddUserModalForm {...props} /> : null

const connectSelector = userModalConnectSelector(C.ADD_USER_FORM)

export default connect(
    state => ({
        ...connectSelector(state),
        show: state[C.ADMIN_NAME].addUserModalOpen,
        initialValues: addUserInitialValuesSelector(state),
        getAccessLevel: getAccessLevelSelector(state)
    }),
    {
        ...actions,
        onSubmit: actions.addUser
    }
)(AddUserModalWrapper)
