import React, { useCallback, useState } from "react"
import { HistoryOverlay } from "../../../../../core/HistoryOverlay"
import HistoryList from "./HistoryList"

const PatientHistory = props => {
    const [show, setShow] = useState(false)
    const handleHide = useCallback(
        e => {
            const dropdown = document.querySelector(".select__menu-portal")
            if (
                dropdown?.contains(e.target) ||
                e.target.className.includes("select__option") // XXX that logic is a bit inelegant...
            ) {
                // just clicked on the select dropdown for a multiselect in this popover, so don't close the popover
                return
            }

            setShow(false)
        },
        [setShow]
    )

    return !props.offerHistory || !props.hasHistory ? (
        <div />
    ) : (
        <HistoryOverlay
            onClick={() => setShow(true)}
            onHide={handleHide}
            show={show}
            id={props.id}
        >
            <div
                className="poc-measure-history scrollable-table-container"
                id="poc_measure_history_popover"
            >
                <HistoryList {...props} />
            </div>
        </HistoryOverlay>
    )
}
export default PatientHistory
