import { Button, Modal } from "react-bootstrap"
import React from "react"
import FormModal from "./FormModal"

export const SimpleModal = ({
    children,
    className,
    closeModal,
    show,
    title
}) => (
    <FormModal show={show} onHide={closeModal} className={className} size="xl">
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Close
            </Button>
        </Modal.Footer>
    </FormModal>
)
