import React from "react"
import spinner from "../../../assets/images/loading.gif"
import { COMPLIANCE } from "../../constants"
import { EMPTY_OBJECT } from "../../utils"
import { Hoverlay } from "../../core/Hoverlay"
import * as PLC from "../../patient_list/constants"
import BasicIcon from "./BasicIcon"

// TODO could probably unify this with YesNoIndicator somehow
// TODO get colors from CSS instead of hardcoding

export const COMPLIANCE_ATTRIBUTES = {
    [COMPLIANCE.COMPLIANT]: {
        className: "indicator-yes",
        color: "#00a651",
        title: "Compliant"
    },
    [COMPLIANCE.NOT_COMPLIANT]: {
        className: "indicator-no",
        color: "#cc0033",
        title: "Not Compliant"
    },
    [COMPLIANCE.PENDING]: {
        className: "indicator-pending",
        color: "#edb100",
        title: "Compliance Pending"
    }
}

export const getComplianceAttributes = (cellData, rowData = EMPTY_OBJECT) => {
    const customAttributes =
        cellData === COMPLIANCE.COMPLIANT // only use custom icon for compliance
            ? {
                  className: rowData[PLC.TABLE_KEYS.COMPLIANT_ICON]
                      ? "fa-" + rowData[PLC.TABLE_KEYS.COMPLIANT_ICON]
                      : undefined,
                  color: rowData[PLC.TABLE_KEYS.COMPLIANT_COLOR] ?? undefined,
                  title: rowData[PLC.TABLE_KEYS.COMPLIANT_TEXT] ?? undefined
              }
            : EMPTY_OBJECT
    return _.merge({}, COMPLIANCE_ATTRIBUTES[cellData], customAttributes)
}

const ComplianceIndicator = ({ className, color, title }) => (
    <Hoverlay tooltip={title} tooltipColor={color}>
        <BasicIcon className={className} color={color} />
    </Hoverlay>
)

const MeasureCompliance = ({ loading, cellData, rowData }) =>
    loading ? (
        <img src={spinner} alt="Saving..." />
    ) : (
        <ComplianceIndicator {...getComplianceAttributes(cellData, rowData)} />
    )
export default MeasureCompliance
