import React from "react"
import _ from "lodash"
import {
    DualStatusCell,
    FormattedDate,
    ListCell,
    LongCell,
    MultilineList,
    Coverage,
    formatYesNo,
    Payer,
    DollarsCentsZeroBlank,
    RiskRank,
    TelephoneCell,
    TimestampDate
} from "../core/CellRenderers"
import patient_cm_elig from "../patient_cm_elig"
import { MeasureCompliance } from "../indicators"
import { columnBuilder } from "../table"
import { joinTruthy } from "../utils"
import { ALIGNMENTS, MODULE_IDS } from "../constants"
import * as C from "./constants"
import cellRenderer, {
    Incentivized,
    IncentivizedRate,
    MeasureComplianceRate,
    MeasureName,
    PatientName,
    IpCell,
    ErCell,
    SnCell,
    ObCell,
    ConditionComplianceRate,
    MeasureHistoryCell,
    LastServiceDate,
    IncentivePayersCell,
    AddressCell,
    getPatientAddress,
    ContractNumbers
} from "./components/CellRenderer"
import {
    getRedocNumFields,
    getRedocRafFields,
    redocGetter,
    redocRemainingGetter
} from "../awv/helpers"
import { RedocCell, RedocRemainingCell } from "../awv/components/CellRenderers"

const TIMESTAMP_WIDTH = 150

const column = columnBuilder(C.PATIENT_TRANSLATION, { cellRenderer })

const COLUMNS = [
    column(C.TABLE_KEYS.PATIENT_NAME, {
        width: 200,
        flexGrow: 1,
        cellRenderer: PatientName
    }),
    column(C.TABLE_KEYS.PATIENT_ID, {
        selected: false,
        width: 100
    }),
    column(C.TABLE_KEYS.PATIENT_DOB, {
        hover: "Date of Birth",
        width: 100,
        cellRenderer: FormattedDate
    }),
    column(C.TABLE_KEYS.PY_AGE, {
        selected: false,
        hover: "Performance Year Age",
        width: 60
    }),
    column(C.TABLE_KEYS.PATIENT_DOD, {
        selected: false,
        hover: "Date of Death",
        width: 100,
        cellRenderer: FormattedDate
    }),
    column(C.TABLE_KEYS.PATIENT_GENDER, {
        width: 100,
        flexGrow: 1,
        cellRenderer: ({ cellData }) => (
            <ListCell cellData={cellData} connector={", "} />
        )
    }),
    column(C.TABLE_KEYS.PATIENT_RACE, {
        width: 150,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.PATIENT_ETHNICITY, {
        width: 155,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.PATIENT_ADDRESS, {
        selected: false,
        width: 300,
        flexGrow: 1,
        dataGetter: getPatientAddress,
        cellRenderer: AddressCell
    }),
    column(C.TABLE_KEYS.PATIENT_PHONE, {
        width: 130,
        cellRenderer: TelephoneCell
    }),
    column(C.TABLE_KEYS.PATIENT_PCP, {
        hover: "Primary Care Provider",
        width: 170,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.PRACTICE_NAME, {
        width: 200,
        flexGrow: 1,
        cellRenderer: LongCell
    }),
    column(C.TABLE_KEYS.PATIENT_PAYER, {
        width: 220,
        flexGrow: 1,
        idField: C.TABLE_KEYS.PAYER_ID,
        cellRenderer: Payer
    }),
    column(C.TABLE_KEYS.PATIENT_PRODUCT_CLASS, {
        width: 165,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PATIENT_PRODUCT_GROUP, {
        width: 170,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.PAYER_IDS, {
        width: 150, // wide enough for 5 payers
        flexGrow: 1,
        cellRenderer: Coverage
    }),
    column(C.TABLE_KEYS.CONTRACT_NUMBERS, {
        width: 200,
        flexGrow: 1,
        cellRenderer: ContractNumbers,
        selected: false
    }),
    column(C.TABLE_KEYS.PRODUCT_CLASSES, {
        cellRenderer: ListCell,
        width: 170,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.PRODUCT_GROUPS, {
        cellRenderer: ListCell,
        width: 170,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.TAG_IDS, {
        selected: false,
        width: 200,
        flexGrow: 1,
        cellRenderer: ({ rowData }) => (
            <MultilineList cellData={rowData[C.TABLE_KEYS.TAG_NAMES]} />
        )
    }),
    column(C.TABLE_KEYS.INCENTIVIZED_COUNT, {
        width: 100,
        cellRenderer: IncentivizedRate
    }),
    column(C.TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE_FRACTION, {
        width: 120,
        cellRenderer: MeasureComplianceRate
    }),
    column(C.TABLE_KEYS.PATIENT_LAST_SERVICE_DATE, {
        getLabel: ({ tableData }) =>
            joinTruthy([
                tableData.useMeasureServiceDate && "Measure",
                C.PATIENT_TRANSLATION[C.TABLE_KEYS.PATIENT_LAST_SERVICE_DATE]
            ]),
        getWidth: ({ tableData }) =>
            tableData.useMeasureServiceDate ? 170 : 140,
        cellRenderer: LastServiceDate
    }),
    column(C.TABLE_KEYS.CM_INCENTIVE, {
        selected: false,
        label: "CMI",
        width: 60,
        cellRenderer: patient_cm_elig.components.default
    }),
    column(C.TABLE_KEYS.PATIENT_IP_COUNT, {
        hover: "Inpatient",
        width: 50,
        cellRenderer: IpCell
    }),
    column(C.TABLE_KEYS.PATIENT_ER_COUNT, {
        hover: "Emergency Department",
        width: 50,
        cellRenderer: ErCell
    }),
    column(C.TABLE_KEYS.PATIENT_SN_COUNT, {
        hover: "Skilled Nursing Facility",
        width: 60,
        cellRenderer: SnCell
    }),
    column(C.TABLE_KEYS.PATIENT_OB_COUNT, {
        hover: "Observations",
        width: 60,
        cellRenderer: ObCell
    }),
    column(C.TABLE_KEYS.PATIENT_RISK_NUMBER, {
        width: 100,
        cellRenderer: RiskRank,
        columnData: {
            patientKey: C.TABLE_KEYS.PATIENT_ID,
            rankKey: C.TABLE_KEYS.PATIENT_RISK_RANK
        }
    }),
    column(C.TABLE_KEYS.PATIENT_LAST_APPOINTMENT_DATE, {
        selected: false,
        hover: "Last Appointment Date",
        width: TIMESTAMP_WIDTH,
        cellRenderer: TimestampDate
    }),
    column(C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_DATE, {
        selected: false,
        hover: "Next Appointment Date",
        width: TIMESTAMP_WIDTH,
        cellRenderer: TimestampDate
    }),
    column(C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_PROVIDER, {
        width: 170,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_TYPE, {
        width: 160,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_STATUS, {
        width: 130,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_NOTE, {
        width: 140,
        flexGrow: 1,
        selected: false
    }),
    column(C.TABLE_KEYS.COST_YTD, {
        width: 150,
        cellRenderer: DollarsCentsZeroBlank,
        module: MODULE_IDS.COST_UTILIZATION
    }),
    column(C.TABLE_KEYS.COST_12_MONTH, {
        width: 170,
        cellRenderer: DollarsCentsZeroBlank,
        module: MODULE_IDS.COST_UTILIZATION
    }),
    column(C.TABLE_KEYS.CARE_MANAGER_NAMES, {
        selected: false,
        width: 200,
        flexGrow: 1,
        cellRenderer: ListCell
    }),
    column(C.TABLE_KEYS.SPECIALIST_NAMES, {
        selected: false,
        width: 200,
        flexGrow: 1,
        cellRenderer: ListCell
    }),
    column(C.TABLE_KEYS.HCC_CONDITIONS, {
        selected: false,
        disableSort: true, // it doesn't really make sense to sort something like this, with so many items in arbitrary order
        width: 400,
        flexGrow: 1,
        cellRenderer: ({ rowData }) => (
            <MultilineList
                cellData={rowData[C.TABLE_KEYS.HCC_CONDITION_NAMES]}
            />
        )
    }),
    column(C.TABLE_KEYS.GEN_CONDITIONS, {
        selected: false,
        disableSort: true, // ditto
        width: 400,
        flexGrow: 1,
        cellRenderer: ({ rowData }) => (
            <MultilineList
                cellData={rowData[C.TABLE_KEYS.GEN_CONDITION_NAMES]}
            />
        )
    }),
    column(C.TABLE_KEYS.HCC_CONDITIONS_MET, {
        selected: false,
        hover: "HCC Conditions Compliance",
        width: 140,
        cellRenderer: ConditionComplianceRate
    }),
    column(C.TABLE_KEYS.PATIENT_HCC, {
        width: 100,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),
    column(C.TABLE_KEYS.HCC_REDOC_NUM, {
        hover: "HCC Re-Documentation number",
        module: MODULE_IDS.RISK,
        columnData: { fieldsGetter: getRedocNumFields },
        cellRenderer: RedocCell,
        dataGetter: redocGetter,
        width: 110,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),
    column(C.TABLE_KEYS.HCC_REDOC_RAF, {
        hover: "HCC Re-Documentation RAF",
        module: MODULE_IDS.RISK,
        columnData: { fieldsGetter: getRedocRafFields },
        cellRenderer: RedocCell,
        dataGetter: redocGetter,
        width: 130,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),
    column(C.TABLE_KEYS.HCC_REDOC_NUM_REMAINING, {
        hover: "HCC Re-Documentation number remaining",
        module: MODULE_IDS.RISK,
        columnData: { fieldsGetter: getRedocNumFields },
        cellRenderer: RedocRemainingCell,
        dataGetter: redocRemainingGetter,
        width: 180,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),
    column(C.TABLE_KEYS.HCC_REDOC_RAF_REMAINING, {
        hover: "HCC Re-Documentation RAF remaining",
        module: MODULE_IDS.RISK,
        columnData: { fieldsGetter: getRedocRafFields },
        cellRenderer: RedocRemainingCell,
        dataGetter: redocRemainingGetter,
        width: 195,
        align: ALIGNMENTS.CENTER,
        selected: false
    }),

    column(C.TABLE_KEYS.MEASURE_NAME, {
        width: 250,
        flexGrow: 1,
        cellRenderer: MeasureName
    }),
    column(C.TABLE_KEYS.MEASURE_INCENTIVIZED, {
        width: 100,
        flexGrow: 1,
        cellRenderer: Incentivized
    }),
    column(C.TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE, {
        width: 110,
        flexGrow: 0,
        cellRenderer: MeasureCompliance,
        align: ALIGNMENTS.CENTER
    }),
    column(C.TABLE_KEYS.INCENTIVE_STATUS, {
        width: 160,
        cellRenderer: DualStatusCell,
        idField: C.TABLE_KEYS.PAYER_ID,
        noWrapper: true
    }),
    column(C.TABLE_KEYS.INCENTIVE_PAYERS, {
        width: 140,
        dataGetter: ({ cellData }) =>
            // for sorting
            cellData[C.TABLE_KEYS.PAYER_NAMES]?.length +
            (cellData[C.TABLE_KEYS.PAYER_NAMES]?.[0] ?? ""),
        cellRenderer: IncentivePayersCell
    }),
    column(C.TABLE_KEYS.MEASURE_LAST_SERVICE_DATE, {
        width: 140,
        cellRenderer: FormattedDate
    }),
    column(C.TABLE_KEYS.MEASURE_LAST_VALUE, {
        width: 160,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.MRN, {
        width: 160,
        flexGrow: 1
    }),
    column(C.TABLE_KEYS.SUPP_ELIG, {
        selected: false,
        width: 175,
        cellFormatter: formatYesNo
    }),
    column(C.TABLE_KEYS.SUPP_IDS, {
        selected: false,
        disableSort: true,
        width: 140,
        flexGrow: 1,
        cellRenderer: ({ cellData }) => (
            <MultilineList cellData={cellData?.split(", ")} />
        )
    }),
    column(C.TABLE_KEYS.MEASURE_HISTORY, {
        module: MODULE_IDS.POC_MEASURES,
        cellRenderer: MeasureHistoryCell,
        className: "popup-history",
        width: 130,
        align: ALIGNMENTS.CENTER,
        disableSort: true
    }),
    column(C.TABLE_KEYS.INCENTIVE_PROGRAM_NAMES, {
        cellRenderer: ListCell,
        width: 135,
        disableSort: true
    })
]
const COLUMN_MAP = _.keyBy(COLUMNS, column => column.key)

export const PATIENTS_TABLE_COLUMNS = _.at(COLUMN_MAP, [
    C.TABLE_KEYS.PATIENT_NAME,
    C.TABLE_KEYS.PATIENT_ID,
    C.TABLE_KEYS.PATIENT_DOB,
    C.TABLE_KEYS.PY_AGE,
    C.TABLE_KEYS.PATIENT_DOD,
    C.TABLE_KEYS.PATIENT_GENDER,
    C.TABLE_KEYS.PATIENT_RACE,
    C.TABLE_KEYS.PATIENT_ETHNICITY,
    C.TABLE_KEYS.PATIENT_ADDRESS,
    C.TABLE_KEYS.PATIENT_PHONE,
    C.TABLE_KEYS.PATIENT_PCP,
    C.TABLE_KEYS.PRACTICE_NAME,
    C.TABLE_KEYS.PATIENT_PAYER,
    C.TABLE_KEYS.PATIENT_PRODUCT_CLASS,
    C.TABLE_KEYS.PATIENT_PRODUCT_GROUP,
    C.TABLE_KEYS.PAYER_IDS,
    C.TABLE_KEYS.CONTRACT_NUMBERS,
    C.TABLE_KEYS.PRODUCT_CLASSES,
    C.TABLE_KEYS.PRODUCT_GROUPS,
    C.TABLE_KEYS.TAG_IDS,
    C.TABLE_KEYS.INCENTIVIZED_COUNT,
    C.TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE_FRACTION,
    C.TABLE_KEYS.PATIENT_LAST_SERVICE_DATE,
    C.TABLE_KEYS.CM_INCENTIVE,
    C.TABLE_KEYS.PATIENT_IP_COUNT,
    C.TABLE_KEYS.PATIENT_ER_COUNT,
    C.TABLE_KEYS.PATIENT_SN_COUNT,
    C.TABLE_KEYS.PATIENT_OB_COUNT,
    C.TABLE_KEYS.PATIENT_RISK_NUMBER,
    C.TABLE_KEYS.COST_YTD,
    C.TABLE_KEYS.COST_12_MONTH,
    C.TABLE_KEYS.PATIENT_LAST_APPOINTMENT_DATE,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_DATE,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_PROVIDER,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_TYPE,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_STATUS,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_NOTE,
    C.TABLE_KEYS.CARE_MANAGER_NAMES,
    C.TABLE_KEYS.SPECIALIST_NAMES,
    C.TABLE_KEYS.HCC_CONDITIONS,
    C.TABLE_KEYS.GEN_CONDITIONS,
    C.TABLE_KEYS.HCC_CONDITIONS_MET,
    C.TABLE_KEYS.PATIENT_HCC,
    C.TABLE_KEYS.HCC_REDOC_NUM,
    C.TABLE_KEYS.HCC_REDOC_RAF,
    C.TABLE_KEYS.HCC_REDOC_NUM_REMAINING,
    C.TABLE_KEYS.HCC_REDOC_RAF_REMAINING,
    C.TABLE_KEYS.MRN
])

export const MEASURES_TABLE_COLUMNS = _.at(COLUMN_MAP, [
    C.TABLE_KEYS.PATIENT_NAME,
    C.TABLE_KEYS.PATIENT_ID,
    C.TABLE_KEYS.PATIENT_DOB,
    C.TABLE_KEYS.PY_AGE,
    C.TABLE_KEYS.PATIENT_DOD,
    C.TABLE_KEYS.PATIENT_GENDER,
    C.TABLE_KEYS.PATIENT_RACE,
    C.TABLE_KEYS.PATIENT_ETHNICITY,
    C.TABLE_KEYS.PATIENT_ADDRESS,
    C.TABLE_KEYS.PATIENT_PHONE,
    C.TABLE_KEYS.PATIENT_PCP,
    C.TABLE_KEYS.PRACTICE_NAME,
    C.TABLE_KEYS.PATIENT_PAYER,
    C.TABLE_KEYS.PATIENT_PRODUCT_CLASS,
    C.TABLE_KEYS.PATIENT_PRODUCT_GROUP,
    C.TABLE_KEYS.PAYER_IDS,
    C.TABLE_KEYS.CONTRACT_NUMBERS,
    C.TABLE_KEYS.PRODUCT_CLASSES,
    C.TABLE_KEYS.PRODUCT_GROUPS,
    C.TABLE_KEYS.MEASURE_NAME,
    C.TABLE_KEYS.MEASURE_INCENTIVIZED,
    C.TABLE_KEYS.INCENTIVE_PROGRAM_NAMES,
    C.TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE,
    C.TABLE_KEYS.INCENTIVE_STATUS,
    C.TABLE_KEYS.INCENTIVE_PAYERS,
    C.TABLE_KEYS.TAG_IDS,
    C.TABLE_KEYS.MEASURE_LAST_SERVICE_DATE,
    C.TABLE_KEYS.MEASURE_LAST_VALUE,
    C.TABLE_KEYS.PATIENT_LAST_APPOINTMENT_DATE,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_DATE,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_PROVIDER,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_TYPE,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_STATUS,
    C.TABLE_KEYS.PATIENT_NEXT_APPOINTMENT_NOTE,
    C.TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE_FRACTION,
    C.TABLE_KEYS.CM_INCENTIVE,
    C.TABLE_KEYS.PATIENT_RISK_NUMBER,
    C.TABLE_KEYS.HCC_CONDITIONS,
    C.TABLE_KEYS.GEN_CONDITIONS,
    C.TABLE_KEYS.HCC_CONDITIONS_MET,
    C.TABLE_KEYS.PATIENT_HCC,
    C.TABLE_KEYS.HCC_REDOC_NUM,
    C.TABLE_KEYS.HCC_REDOC_RAF,
    C.TABLE_KEYS.HCC_REDOC_NUM_REMAINING,
    C.TABLE_KEYS.HCC_REDOC_RAF_REMAINING,
    C.TABLE_KEYS.MRN,
    C.TABLE_KEYS.SUPP_ELIG,
    C.TABLE_KEYS.SUPP_IDS,
    C.TABLE_KEYS.MEASURE_HISTORY
])

export const COLUMNS_BY_TYPE = {
    [C.FILTER_TYPES.PATIENT_SUMMARY]: PATIENTS_TABLE_COLUMNS,
    [C.FILTER_TYPES.PATIENT_MEASURES]: MEASURES_TABLE_COLUMNS
}
