import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import { EMPTY_OBJECT, itemsToObject } from "../utils"
import * as C from "./constants"

const sortAndGroupProductPayers = (firstSort, secondSort) => productPayers => {
    if (_.isEmpty(productPayers)) {
        return []
    }
    const sorted = productPayers.sort((a, b) => {
        const c = (a[firstSort] || "").localeCompare(b[firstSort])
        return c || (a[secondSort] || "").localeCompare(b[secondSort])
    })

    return _.groupBy(sorted, byPayer => byPayer[firstSort])
}

export const productPayerSelector = state =>
    getResourceData(C.PATIENT_LIST_CHART, data => data.productPayers)(state) ||
    []

export const productSelector = createSelector(
    [productPayerSelector],
    sortAndGroupProductPayers(C.PRODUCT_CLASS_NAME, C.PAYER_NAME)
)

export const payerSelector = createSelector(
    [productPayerSelector],
    sortAndGroupProductPayers(C.PAYER_NAME, C.PRODUCT_CLASS_NAME)
)

export const colorMapSelector = createSelector(
    getResourceData(C.PATIENT_LIST_CHART),
    ({ payerColors = [], productColors = [] } = EMPTY_OBJECT) =>
        itemsToObject(
            [...payerColors, ...productColors],
            ({ label }) => label,
            ({ color }) => color
        )
)

export const toggleSelector = (state, { toggle }) => toggle
export const hiddenSelector = (state, props) => (props ? props.hidden : [])

export const rawDataSelector = createSelector(
    [productSelector, payerSelector, toggleSelector],
    (product, payer, toggle) => (toggle ? product : payer)
)

export const headerListSelector = createSelector(
    [productSelector, payerSelector, toggleSelector],
    (product, payer, toggle) => (toggle ? payer : product)
)

// Create a mapping from a header value to formatted version with total
export const chartNamesSelector = createSelector(
    [headerListSelector],
    headers =>
        _.mapValues(
            headers,
            (list, header) =>
                `${header} (${_.sumBy(list, item => item.sumNumPatients)})`
        )
)

export const chartHeaderSelector = createSelector([headerListSelector], list =>
    _.keys(list).sort()
)

const getHeaderCounts = (items, primaryKey) =>
    itemsToObject(
        items,
        item => item[primaryKey],
        item => item.sumNumPatients
    )

export const barChartDataSelector = createSelector(
    [rawDataSelector, toggleSelector, hiddenSelector],
    (rawData, toggle, hidden) => {
        const primaryKey = toggle ? C.PAYER_NAME : C.PRODUCT_CLASS_NAME

        const json = _.entries(rawData)
            .map(([name, items]) => {
                const headerCounts = getHeaderCounts(items, primaryKey)
                const totalVisible = _.sum(
                    _.values(_.omit(headerCounts, hidden))
                )
                return {
                    key: `${name} (${totalVisible})`,
                    ...headerCounts
                }
            })
            .sort((a, b) => a.key - b.key)

        return { json, unload: _.keys(rawData) }
    }
)

export const pieChartDataSelector = createSelector(
    [rawDataSelector, toggleSelector],
    (rawData, toggle) => {
        const primaryKey = toggle ? C.PAYER_NAME : C.PRODUCT_CLASS_NAME

        return {
            json: _.values(rawData).map(items =>
                getHeaderCounts(items, primaryKey)
            )
        }
    }
)
