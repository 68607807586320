import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { encodeFiltersInUrl } from "../../utils"

export const OutreachLink = ({ children, filters, measureAbbrevName }) => {
    const url = useMemo(
        () =>
            encodeFiltersInUrl(
                "/care-management/" + measureAbbrevName,
                filters
            ),
        [filters, measureAbbrevName]
    )
    return <Link to={url}>{children}</Link>
}
