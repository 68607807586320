import React, { useMemo } from "react"
import { connect } from "react-redux"
import { line } from "billboard.js"
import Chart from "../../core/Chart"
import { formatPercent } from "../../numbers"
import { chartDataSelector } from "../selectors"

const CONFIG = {
    axis: {
        x: {
            type: "timeseries",
            tick: {
                format: "%b",
                culling: false
            },
            clipPath: false
        },
        y: {
            min: 0,
            max: 100,
            padding: { top: 5, bottom: 5 }
        }
    },
    padding: { right: 5, unit: "px" },
    tooltip: { format: { value: formatPercent } }
}

const QsrChart = ({ data }) => {
    const chartData = useMemo(
        () => ({
            ...data,
            type: line(),
            xFormat: "%m"
        }),
        [data]
    )

    return (
        <Chart
            config={CONFIG}
            data={chartData}
            className="qsr-chart"
            id="chart"
        />
    )
}

export default connect((state, { showPrior }) => ({
    data: chartDataSelector(state, showPrior)
}))(QsrChart)
