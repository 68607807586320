import React from "react"
import FormModal from "../../../core/FormModal"
import { DemographicsForm } from "./DemographicsForm"

export const EditDemographicsModal = ({
    loading,
    options,
    showEdit,
    showEditPatientInfo,
    hideEditPatientInfo,
    updatePatientInfo,
    handleSubmit
}) => {
    return (
        <React.Fragment>
            <span
                className="fa fa-edit"
                onClick={showEditPatientInfo}
                style={{ cursor: "pointer" }}
            />
            <FormModal size="lg" show={showEdit} onHide={hideEditPatientInfo}>
                <DemographicsForm
                    handleSubmit={handleSubmit(updatePatientInfo)}
                    handleHide={hideEditPatientInfo}
                    handleShow={showEditPatientInfo}
                    loading={loading}
                    options={options}
                />
            </FormModal>
        </React.Fragment>
    )
}
