import React, { useEffect } from "react"
import { tableFactory } from "../../table"
import PatientList from "../../patient_list/components/PatientList"
import { MEASURES_TABLE_COLUMNS } from "../../patient_list/columns"
import * as PLC from "../../patient_list/constants"
import * as C from "../constants"

const PatientMeasures = tableFactory({
    name: C.PATIENTS,
    columns: MEASURES_TABLE_COLUMNS,
    ...PLC.MEASURES_DEFAULT_SORT
})

export const CareManagementPatients = props => {
    useEffect(() => window.scrollTo(0, 0), [])
    return (
        <PatientList
            {...props}
            {...PatientMeasures}
            href={"/care-management/" + props.measureAbbrevName}
            modalTitle="Set Care Management Filters"
            limitMeasures
        />
    )
}

export default CareManagementPatients
