import { connect } from "react-redux"
import { getFormSyncErrors, getFormValues } from "redux-form"

import fetcher, { getMetaData, getResourceData } from "../../core/fetcher"
import { fixedScopeSelector } from "../../patient_list/selectors"
import * as patientListActions from "../../patient_list/actions"
import * as PLC from "../../patient_list/constants"

import { transformAndFilterFilters } from "../../transformFilter"
import { rowCountSelector } from "../../selectorUtils"
import { urlWithParams } from "../../utils"
import auth from "../../authentication"
import { ApiTypes } from "../../api"

import * as C from "../constants"
import * as cmActions from "../actions"
import {
    cmPickerOptionsSelector,
    cmPickerValueSelector,
    filterOptionsSelector,
    isDefaultSelector,
    measureNameSelector,
    patientListSelector
} from "../selectors"
import CareManagement from "./CareManagement"

export { default as ProductGraph } from "./ProductGraph"
export { default as CostChart } from "./CostChart"

export const getEndpoint = state => {
    const filter = transformAndFilterFilters(state[C.NAME].filters)
    return urlWithParams(`/api/patients`, filter)
}

const patientCountEndpoint = state => {
    const filter = transformAndFilterFilters(state[C.NAME].filters)
    return urlWithParams(`/api/patients/length`, filter)
}

export const CareManagementWrapper = fetcher({
    name: C.PATIENTS,
    endpoint: getEndpoint,
    defaultOrderBys: PLC.MEASURES_DEFAULT_SORT,
    paginationKey: "patients"
})(
    connect(
        state => ({
            ...state[C.NAME],
            ...getResourceData(C.NAME)(state),
            pickerValue: cmPickerValueSelector(state),
            pickerOptions: cmPickerOptionsSelector(state),
            pickerLoading: getMetaData(C.NAME)(state)?.loading,
            measureName: measureNameSelector(state),
            list: patientListSelector(state),
            rowCount: rowCountSelector(C.CM_PATIENT_COUNT)(state),
            ...state[PLC.GENERATE_PATIENT_LIST_FORM],
            options: filterOptionsSelector(state),
            results: getFormValues(PLC.GENERATE_PATIENT_LIST_FORM)(state),
            errors: getFormSyncErrors(PLC.GENERATE_PATIENT_LIST_FORM)(state),
            fixedScope: fixedScopeSelector(state),
            superUser: auth.selectors.isSuperuser(state),
            patientCountEndpoint,
            patientCountName: C.CM_PATIENT_COUNT,
            anchorDate: state.server_status.anchorDate,
            isDefault: isDefaultSelector(state)
        }),
        {
            ...patientListActions,
            ...cmActions,
            exportPatientList: cmActions.exportCmPatientList,
            printPatientList: cmActions.printCmPatientList
        }
    )(CareManagement)
)

export default fetcher({
    name: C.NAME,
    method: ApiTypes.GET,
    endpoint: state =>
        urlWithParams(
            `/api/outreach/${state[C.NAME].measureAbbrevName}`,
            _.pick(state[C.NAME].filters, [
                PLC.FILTERS.PRACTICE_UNITS,
                PLC.FILTERS.REGIONS
            ])
        )
})(CareManagementWrapper)
