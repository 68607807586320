import React from "react"
import { Button, Modal } from "react-bootstrap"
import { GuideLink } from "../../../indicators"
import Loading from "../../../core/Loading"
import FormModal from "../../../core/FormModal"
import * as C from "../../constants"
import EditCampaign from "./EditCampaign"

export const EditCampaignModal = props =>
    !props.canEdit ? null : (
        <FormModal
            dialogClassName="large-modal"
            show={!!props.campaignId}
            onHide={props.hideEdit}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Campaign
                    <GuideLink page="create-a-campaign" className="white" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!props.campaignId ? <Loading /> : <EditCampaign {...props} />}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className="btn-modal-close"
                    onClick={props.hideEdit}
                    style={{ marginRight: "10px" }}
                >
                    Close
                </Button>
                {props.invalid ? (
                    <Button variant="primary" disabled>
                        Invalid Settings
                    </Button>
                ) : (
                    <Button
                        variant="primary"
                        className="btn-modal-save"
                        onClick={() => {
                            props.submit(C.CAMPAIGN_FORM)
                        }}
                    >
                        Save Changes
                    </Button>
                )}
            </Modal.Footer>
        </FormModal>
    )
