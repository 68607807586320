import { connect } from "react-redux"
import { useEffect, useState } from "react"

import fetcher, { getResourceData } from "../../core/fetcher"
import * as SC from "../../site_settings/constants"
import * as QC from "../../qsr/constants"
import { SETTINGS } from "../../constants"
import { urlWithParams } from "../../utils"
import { ApiTypes } from "../../api"
import * as C from "../constants"
import * as actions from "../actions"
import RiskDashboard from "./RiskDashboard"

const RiskDashboardFetcher = fetcher({
    name: C.NAME,
    method: ApiTypes.GET,
    endpoint: state => {
        const moduleState = state[C.NAME]
        return urlWithParams(
            `/api/risk/dashboard/${moduleState.aggregation}`,
            state[C.NAME].filters
        )
    }
})(RiskDashboard)

export const RiskDashboardWrapper = ({ defaultProduct, ...props }) => {
    const [ready, setReady] = useState(false)
    useEffect(() => {
        props.setFilters({
            ...(defaultProduct && { [QC.FILTERS.PRODUCTS]: [defaultProduct] }),
            ...props.filters
        })
        setReady(true)
    }, [defaultProduct])

    return ready && <RiskDashboardFetcher {...props} />
}

const defaultProductSelector = getResourceData(
    SETTINGS,
    data => data[SC.RISK_DASHBOARD_PRODUCT]
)

export default connect(
    state => ({
        ...state[C.NAME],
        defaultProduct: defaultProductSelector(state),
        filters: state[C.NAME].filters
    }),
    actions
)(RiskDashboardWrapper)
