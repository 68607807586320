import React from "react"
import { Field } from "redux-form"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { SelectField, LabelledDatePicker } from "../../../core/CustomFields"
import { required } from "../../../core/validators"
import FormModal from "../../../core/FormModal"
import * as C from "../../constants"

export const DeactivatePatientModal = ({
    canDeactivate,
    reason,
    reasons,
    invalid,
    showDeactivate,
    showDeactivatePatient,
    hideDeactivatePatient,
    handleSubmit
}) =>
    canDeactivate && (
        <React.Fragment>
            <Button
                className="deactivate-patient"
                variant="outline-danger"
                size="sm"
                onClick={showDeactivatePatient}
            >
                Deactivate
            </Button>
            <FormModal show={showDeactivate} onHide={hideDeactivatePatient}>
                <Modal.Header closeButton>
                    <Modal.Title>Deactivate Patient</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <Row>
                            <Col sm={6}>
                                <Field
                                    name={C.KEYS.REASON}
                                    component={SelectField}
                                    options={reasons}
                                    required
                                    validate={required}
                                />
                            </Col>
                            <Col sm={6}>
                                <Field
                                    name={C.KEYS.EFF_DATE}
                                    component={LabelledDatePicker}
                                    label={
                                        reason === C.DECEASED_REASON
                                            ? "Date of Death"
                                            : undefined
                                    }
                                    required
                                    validate={required}
                                />
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={invalid}
                    >
                        Deactivate
                    </Button>
                </Modal.Footer>
            </FormModal>
        </React.Fragment>
    )
