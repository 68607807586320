import { createSelector } from "reselect"
import _ from "lodash"
import { getResourceData } from "../core/fetcher"
import { EMPTY_LIST, itemsToObject } from "../utils"
import { denumerateMonth, numerateMonth, SERVER_DATE_STRING } from "../dates"
import { FILTER_OPTIONS } from "../constants"
import * as C from "./constants"
import { branchesToLeaves } from "./helpers"
import { optionsMapSelector } from "../selectorUtils"

export const categoriesSelector = optionsMapSelector(C.FILTERS.CATEGORIES)
export const subcategoriesSelector = optionsMapSelector(C.FILTERS.SUBCATEGORIES)
export const servicesSelector = optionsMapSelector(C.FILTERS.SERVICES)
export const payersSelector = optionsMapSelector(C.FILTERS.PAYERS)
export const productsSelector = optionsMapSelector(C.FILTERS.PRODUCTS)

export const lookupSelector = createSelector(
    categoriesSelector,
    subcategoriesSelector,
    servicesSelector,
    (categories, subcategories, services) => [
        { undefined: "Total" },
        categories,
        subcategories,
        services
    ]
)
export const pathLookupSelector = createSelector(
    lookupSelector,
    lookup => path => lookup[path.length][_.last(path)]
)

// when you use this, call it once with maxDepth, then pass the result around. Otherwise it'll reinitialize and you won't get any caching.
export const fundStampsSelector = maxDepth =>
    createSelector(
        getResourceData(FILTER_OPTIONS, data => data.fundStamps || EMPTY_LIST),
        fundStamps =>
            _.uniqWith(
                fundStamps
                    .filter(({ categoryId }) => categoryId !== 0) // if the id is 0 then it'll get compacted out and we'll end up with an invalid id
                    .map(
                        ({
                            categoryId,
                            subcategoryId,
                            serviceId,
                            serviceDetailId
                        }) =>
                            _.compact(
                                [
                                    categoryId,
                                    subcategoryId,
                                    serviceId,
                                    serviceDetailId
                                ].slice(0, maxDepth)
                            )
                    ),
                _.isEqual
            ).sort() // ensures that short fundstamps don't overwrite long ones
    )

export const fundStampLookupSelector = createSelector(
    pathLookupSelector,
    fundStampsSelector(4), // XXX we could probably safely reduce this to 2
    (pathLookup, fundStamps) => ({
        "": pathLookup([]),
        ..._.fromPairs(
            fundStamps.flatMap(fundStamp =>
                _.compact(fundStamp).map((id, index, ids) => {
                    const path = ids.slice(0, index + 1)
                    const label = pathLookup(path)

                    return [path.join("."), label]
                })
            )
        )
    })
)

export const categoryTreeSelector = createSelector(
    fundStampsSelector(2),
    fundStamps => {
        const categoryTree = {}

        // noinspection JSCheckFunctionSignatures
        fundStamps.forEach(path =>
            _.setWith(categoryTree, _.compact(path), {}, Object)
        )

        return categoryTree
    }
)

export const categoryLeavesSelector = createSelector(
    categoryTreeSelector,
    tree => branchesToLeaves(tree, _.keys(tree))
)

export const categoryNodesSelector = createSelector(
    pathLookupSelector,
    categoryTreeSelector,
    (pathLookup, categoryTree) => {
        const traverseTree = (tree, path) =>
            Object.entries(tree).map(([bId, branch]) => {
                const newPath = [...path, bId]
                const optionalChildren = _.isEmpty(branch)
                    ? {}
                    : { children: traverseTree(branch, newPath) }
                const fundStamp = newPath.join(".")

                return {
                    label: pathLookup(newPath),
                    value: fundStamp,
                    ...optionalChildren
                }
            })

        return traverseTree(categoryTree, [])
    }
)

const getSingleMonths = (...path) => (months, categoryIds) =>
    _.sortBy(
        Object.entries(months),
        ([month]) => month
    ).map(([month, cats]) => [
        denumerateMonth(month).format(SERVER_DATE_STRING),
        ...categoryIds.map(c => _.get(cats, [c, ...path], null))
    ])

const getDoubleMonths = (...props) => ({
    [C.COST_VIEWS.ABSOLUTE]: getSingleMonths(C.TABLE_KEYS.TOTAL_COST)(...props),
    [C.COST_VIEWS.RELATIVE]: getSingleMonths(C.TABLE_KEYS.PM_COST)(...props)
})

const getMonthsBetween = (firstMonth, lastMonth) => {
    const months = []
    const now = firstMonth.clone()
    while (now.isSameOrBefore(lastMonth)) {
        months.push(numerateMonth(now))
        now.add(1, "months")
    }
    return months
}

const getFillerMonths = months => {
    const monthsChronological = _.sortBy(Object.keys(months))
    const firstMonth = denumerateMonth(_.head(monthsChronological))
    const lastMonth = denumerateMonth(_.last(monthsChronological))
    const fillerMonths = getMonthsBetween(firstMonth, lastMonth)
    return itemsToObject(
        fillerMonths,
        month => month,
        () => ({})
    )
}

const chartCombiner = monthsGetter => (
    { months = {}, ...otherData } = {},
    { loading, error }, // cost chart metadata
    { loading: categoriesLoading }, // filter options metadata
    categoryLookup
) => {
    // get all categoryIds that appear in the data (not counting entries with a value of 0)
    const categoryIds = _.union(
        ...Object.values(months).map(month => Object.keys(_.pickBy(month)))
    )
        .filter(cid => !!cid && cid !== "0")
        .sort()

    // map categoryIds to category names
    const categories = categoryIds.map(c => categoryLookup[c] || c) // category doesn't have a name? We'll have to use its ID instead, or else the chart can crash.

    // add entries for empty months, to keep spacing correct
    const allMonths = { ...getFillerMonths(months), ...months }

    // convert months to a format bb.js can read
    const monthsProcessed = monthsGetter(allMonths, categoryIds)

    return {
        months: monthsProcessed,
        ...otherData,
        categories,
        error,
        loading: !!(loading || categoriesLoading)
    }
}

export const singleChartCombiner = chartCombiner(getSingleMonths()) // no path, just get directly what's in the month category
export const doubleChartCombiner = chartCombiner(getDoubleMonths)

export const costFiltersSelector = name =>
    createSelector(
        state => state[name].filters,
        filters => ({
            ...filters,
            [C.FILTERS.START_DATE]: numerateMonth(
                filters[C.FILTERS.START_DATE]
            ),
            [C.FILTERS.END_DATE]: numerateMonth(filters[C.FILTERS.END_DATE])
        })
    )
