import React, { useMemo } from "react"
import * as C from "../constants"
import RFCheckBoxTree from "../../core/RFCheckBoxTree"
import { getCheckAllProps } from "../helpers"
import { PrintField } from "./PrintField"
import { CheckAllButton } from "./CheckAllButton"
import { ToggleField } from "./PrintLinkages"
import { Field } from "redux-form"

export const PrintMeasureHistory = ({
    change,
    formValues,
    historyMeasures
}) => {
    const buttonProps = useMemo(
        getCheckAllProps(
            change,
            formValues,
            C.PRINT_FIELDS.HISTORY_MEASURES,
            historyMeasures
        ),
        [formValues[C.PRINT_FIELDS.HISTORY_MEASURES], historyMeasures]
    )

    return (
        <PrintField name={C.PRINT_FIELDS.HISTORY} className="print-history">
            {/*<Field name={C.PRINT_FIELDS.WITH_GRAPHS} component={ToggleField} />*/}
            {/* XXX someday we'll add tables to the measure history report, and at that point we might want the graphs to be optional*/}
            <div className="print-checkboxes print-history-measures">
                <h5>Measure histories to include:</h5>
                <CheckAllButton {...buttonProps} />
                <RFCheckBoxTree
                    nodes={historyMeasures}
                    name={C.PRINT_FIELDS.HISTORY_MEASURES}
                />
            </div>
        </PrintField>
    )
}
