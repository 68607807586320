import { connect } from "react-redux"
import { getFormValues, reduxForm } from "redux-form"
import _ from "lodash"

import { getResourceData } from "../../../core/fetcher"
import { PERIODS } from "../../../pickers/constants"
import fetcher from "../../../core/fetcher"
import { getValues } from "../../../utils"
import { moduleEnabledSelector } from "../../../moduleUtils"
import { ApiTypes } from "../../../api"
import { FILTER_OPTIONS, MODULE_IDS } from "../../../constants"
import * as C from "../../constants"
import * as reduxActions from "../../actions"
import {
    allHistoryMeasuresSelector,
    campaignPatientSelector,
    patientSelector
} from "../../selectors"
import { transformMeasures } from "../../helpers"

import { BatchPrintModal } from "./BatchPrintModal"
import { authTokensSelector } from "../../../authentication/selectors"

const BatchPrintForm = reduxForm({
    form: C.BATCH_PRINT_MODAL_FORM,
    enableReinitialize: true
})(BatchPrintModal)

export default fetcher({
    name: C.ALL_ASSESSMENTS,
    endpoint: `/api/measures/assessments`,
    method: ApiTypes.GET,
    acceptResponse: data => (data.measures || []).map(transformMeasures)
})(
    connect(state => {
        const dueServices = getResourceData(FILTER_OPTIONS, data =>
            _.map(data.measures, ({ label, id }) => ({ label, value: id }))
        )(state)
        const historyMeasures = allHistoryMeasuresSelector(state)

        return {
            campaign_patients: campaignPatientSelector(state),
            downloadPercentage: state[C.NAME].downloadPercentage,
            printing: state[C.NAME].printing,
            patients: patientSelector(state),
            show_bp: state[C.NAME].show_bp,
            authentication: authTokensSelector(state),
            canAccessCosts: moduleEnabledSelector(MODULE_IDS.COST_UTILIZATION)(
                state
            ),

            formValues: getFormValues(C.BATCH_PRINT_MODAL_FORM)(state) || {},
            initialValues: {
                ...C.initialValues,
                [C.PRINT_FIELDS.ASSESSMENTS]: [],
                [C.PRINT_FIELDS.SINGLE_PDF]: true,
                [C.PRINT_FIELDS.DUE_SERVICES]: getValues(dueServices),
                [C.PRINT_FIELDS.HISTORY_MEASURES]: getValues(historyMeasures),
                [C.PRINT_FIELDS.COST_PERIOD]: PERIODS.CURRENT_YEAR
            },
            assessments: getResourceData(C.ALL_ASSESSMENTS)(state),
            historyMeasures,
            dueServices: dueServices
        }
    }, reduxActions)(BatchPrintForm)
)
