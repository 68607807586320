import _ from "lodash"
import moment from "moment"
import ordinal from "ordinal"
import { DATE_STRING, dateFormat } from "../dates"
import { isNilOrBlank, urlWithParams } from "../utils"
import { ApiTypes, getDefaultHeaders } from "../api"
import * as C from "./constants"

// if a string is used as a key for lookup in a lodash function, and it has full stops in it, then the periods will be interpreted as a search path.
// so e.g. _.get({a:{b:1}, "a.b":2}, "a.b") can return 1 (though it doesn't always).
// to deal with this, we can replace the full stops with "+"
export const encodeAbbrevToInputName = name => name?.replaceAll(".", "+")
export const decodeInputNameToAbbrev = name => name?.replaceAll("+", ".")

// inpout names for measure inputs
export const getInputName = abbrev => encodeAbbrevToInputName(abbrev)
export const getMeasureInputName = props =>
    encodeAbbrevToInputName(props.abbrevName)
export const getInputDateName = abbrev => `${getInputName(abbrev)}/date`

export const getSubmeasureInputName = parent =>
    parent.dataType === C.COMPOSITE
        ? getCompositeSubmeasureInputName(parent.abbrevName)
        : getSurveySubmeasureInputName(parent.abbrevName)
export const getSurveySubmeasureInputName = parentAbbrev => submeasure =>
    `${getInputName(parentAbbrev)}/sub/${encodeAbbrevToInputName(
        submeasure.abbrevName
    )}`
export const getCompositeSubmeasureInputName = parentAbbrev => submeasure =>
    `${getInputName(parentAbbrev)}/comp/${encodeAbbrevToInputName(
        submeasure.abbrevName
    )}`

export const getTreeCategoryKey = index => `node-${index}`
export const getTreeSubcategoryKey = (index, subIndex) =>
    `${getTreeCategoryKey(index)}-${subIndex}`

export const getValuesAndMeta = (measures, extraValues = []) => {
    const allFallbacks =
        !_.isEmpty(measures) && _.every(measures, o => o.value.isFallback)

    const hasChanged = _.some(
        measures,
        measure => measure.date.changed || measure.value.changed
    )

    const validDates = measures
        ?.filter(o => o.value.next && !o.value.isFallback) // only considering dates from measures that have had values manually entered, because it shouldn't complain because some measure term had a value last year
        ?.map(o => o.date.next)
        ?.filter(_.negate(_.isEmpty))

    const datesAllMatch = _.uniq(validDates).length <= 1

    const calculatedDate = allFallbacks
        ? undefined
        : _.maxBy(
              validDates,
              dateStr => moment.utc(dateStr, DATE_STRING) // note that dateStr can't be null because everything left in validOptionalDates must have reqAllMatchNoFallbacks value.next property
          )

    // we have to limit ourselves to the measure terms with the right date. If a measure term was removed, nothing in the remaining terms would have `changed === true`.
    // and we don't filter out the nulls because the terms have a fixed order
    const validValues = _.map(measures, o =>
        o.value.next && o.date.next === calculatedDate ? o.value.next : null
    )

    // put it all together

    const okayToDisplay = _.some(validValues) && !allFallbacks

    return {
        update: okayToDisplay && hasChanged,
        clear: !okayToDisplay,
        error: !datesAllMatch,
        values: [...validValues, ...extraValues],
        date: calculatedDate
    }
}

export const formatPercentile = value => {
    if (isNilOrBlank(value)) return ""
    const percentileNumber = Number(value)
    if (_.isNaN(percentileNumber)) return ""

    return `${ordinal(percentileNumber)} Percentile`
}

const detailBuilder = (prevValues, nextValues, inputLookup) => abbrev => {
    const name = getInputName(abbrev)
    const dateName = getInputDateName(abbrev)
    const prevValue = prevValues[name]
    const nextValue = nextValues[name]
    const prevDate = prevValues[dateName]
    const nextDate = nextValues[dateName]

    // is there an older value for today?

    const useFallback = isNilOrBlank(nextValue) && isNilOrBlank(nextDate)
    const measure = inputLookup[name] || {}
    const fallbackValue = useFallback ? measure.numericVal : undefined
    const fallbackDate = useFallback ? dateFormat(measure.serviceDt) : undefined
    // those need to fall back to undefined specifically, so that they'll appear unchanged if no value has been set for them yet

    return {
        abbrev,
        name,
        dateName,
        value: {
            prev: prevValue,
            next: nextValue || fallbackValue, // will be null if there's a date entered but no value, or null if there are no prior values
            isFallback: useFallback,
            changed: prevValue !== nextValue
        },
        date: {
            prev: prevDate,
            next: nextDate || fallbackDate,
            isFallback: useFallback,
            changed: prevDate !== nextDate
        }
    }
}

export function getAllDetails(
    prevValues = {},
    nextValues = {},
    measure = {},
    inputLookup = {}
) {
    const getCurrentDetails = detailBuilder(prevValues, nextValues, inputLookup)

    return measure.requiredMeasures?.map(getCurrentDetails)
}

export const getDropListOptions = (dropListOptions, valueField) =>
    (dropListOptions || []).map(item => ({
        label: item.name,
        value: item[valueField]
    }))

export const getAutocalc = async (
    patientId,
    measureAbbrevName,
    rawTermValues,
    authentication,
    observationDate
) => {
    const termValues = rawTermValues.map(term =>
        _.isArray(term) ? term.join(";") : term
    ) // DB function expects multiselect terms as a semicolon-separated "list" string

    const endpoint = urlWithParams("/api/measures/autocalc", {
        patientId,
        measureAbbrevName,
        termValues,
        observationDate
    })

    const response = await fetch(endpoint, {
        method: ApiTypes.GET,
        headers: getDefaultHeaders(authentication)
    })

    return response.ok
        ? (await response.json()) ?? undefined
        : { error: "Measure autocalculation failed." }
}
