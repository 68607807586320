import React from "react"
import CardTitle from "../../core/CardTitle"
import { FILTERS } from "../../patient_list/constants"
import { DEFAULT_FILTERS } from "../../care_management/constants"
import { getStartOfYear } from "../../dates"
import { COMPLIANCE } from "../constants"
import { Progress } from "./Progress"
import { OutreachLink } from "./OutreachLink"
import { PatientsRow } from "./PatientsRow"

const OutreachTile = ({
    measureName,
    measureAbbrevName,
    goalRate,
    anchorDate,
    numPat_1BillCd = 0,
    numPat_2plusBillCd = 0,
    numPatNeededForGoal,
    numPatElig,
    numBillCdNeededForGoal,
    formFilters,
    description
}) => {
    const numberMet =
        numBillCdNeededForGoal === 2 ? numPat_2plusBillCd : numPat_1BillCd
    const numberOfPatientsNeeded = numPatNeededForGoal - numberMet

    const baseFilters = {
        ...DEFAULT_FILTERS,
        ...formFilters,
        [FILTERS.MEASURES]: measureAbbrevName // for care management, measures are single-select
    }
    const compliantFilters = {
        ...baseFilters,
        [FILTERS.COMPLIANT]: COMPLIANCE.COMPLIANT,
        [FILTERS.LAST_SERVICE_FROM]: getStartOfYear(anchorDate)
    }
    const partialMeasureFilters = {
        ...compliantFilters,
        [FILTERS.COMPLIANT]: COMPLIANCE.NOT_COMPLIANT,
        [FILTERS.LAST_SERVICE_FROM]: getStartOfYear(anchorDate)
    }

    return (
        <div>
            <CardTitle as="h2">{measureName}</CardTitle>
            <Progress
                numberMet={numberMet}
                numPatNeededForGoal={numPatNeededForGoal}
                measureName={measureName}
                numBillCdNeededForGoal={numBillCdNeededForGoal}
            />
            <hr />
            {numBillCdNeededForGoal === 2 && (
                <>
                    {Number(numPat_1BillCd) === 0 ? (
                        <section>
                            <OutreachLink
                                filters={partialMeasureFilters}
                                measureAbbrevName={measureAbbrevName}
                            >
                                No patients
                            </OutreachLink>{" "}
                            have just one visit
                        </section>
                    ) : (
                        <section>
                            These{" "}
                            <OutreachLink
                                filters={partialMeasureFilters}
                                measureAbbrevName={measureAbbrevName}
                            >
                                {numPat_1BillCd} patients
                            </OutreachLink>{" "}
                            already have one visit
                        </section>
                    )}
                    <hr />
                </>
            )}

            {numberOfPatientsNeeded <= 0 ? (
                <section>You have reached your goal!</section>
            ) : (
                <section>
                    You need{" "}
                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {numberOfPatientsNeeded}{" "}
                    </span>
                    patients to reach your goal!
                </section>
            )}
            <hr />
            <section>
                <table>
                    <tbody>
                        <PatientsRow
                            measureAbbrevName={measureAbbrevName}
                            message="Eligible patients"
                            number={numPatElig}
                            filters={baseFilters}
                        />
                        <tr>
                            <td>Target goal ({goalRate}%)</td>
                            <td>{numPatNeededForGoal}</td>
                        </tr>

                        {numBillCdNeededForGoal === 2 && (
                            <PatientsRow
                                measureAbbrevName={measureAbbrevName}
                                message="Patients with one billing code"
                                number={numPat_1BillCd}
                                filters={partialMeasureFilters}
                            />
                        )}
                        {numBillCdNeededForGoal === 2 && (
                            <PatientsRow
                                measureAbbrevName={measureAbbrevName}
                                message="Patients with two+ billing codes"
                                number={numPat_2plusBillCd}
                                filters={compliantFilters}
                            />
                        )}
                        {numBillCdNeededForGoal === 1 && (
                            <PatientsRow
                                measureAbbrevName={measureAbbrevName}
                                message="Patients with one+ billing codes"
                                number={numPat_1BillCd + numPat_2plusBillCd}
                                filters={compliantFilters}
                            />
                        )}
                    </tbody>
                </table>
            </section>
            {description && (
                <>
                    <hr />
                    <section>{description}</section>
                </>
            )}
        </div>
    )
}

export default OutreachTile
