import React from "react"
import { OutreachLink } from "./OutreachLink"

export const PatientsRow = ({
    filters,
    measureAbbrevName,
    message,
    number
}) => (
    <tr>
        <td>
            <OutreachLink
                filters={filters}
                measureAbbrevName={measureAbbrevName}
            >
                {message}
            </OutreachLink>
        </td>
        <td>{number ?? 0}</td>
    </tr>
)
