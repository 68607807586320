import React, { useMemo } from "react"
import { Button } from "react-bootstrap"
import _ from "lodash"
import classNames from "classnames"
import Loading from "../../core/Loading"
import { filterBySearch } from "../../utils"
import { PermissionItem } from "./PermissionItem"
import * as C from "../constants"
import SearchBar from "../../core/SearchBar"

function getPrivilegeClassName(privilege) {
    return classNames("fa", C.PERMISSION_BUTTONS[privilege]?.className)
}

export const PermissionColumn = ({
    filter,
    header,
    loading,
    selected,
    set,
    setAll,
    visibleWithPrivilege,
    otherPrivileges
}) => {
    const list = useMemo(
        () =>
            filterBySearch(
                selected[visibleWithPrivilege] || [],
                item => item.label,
                filter
            ),
        [selected, visibleWithPrivilege, filter]
    )
    const buttons = useMemo(
        () =>
            _.compact(
                otherPrivileges.map(
                    ({ disabled, privilege }) =>
                        !disabled && C.PERMISSION_BUTTONS[privilege]
                )
            ),
        [otherPrivileges]
    )
    const rows = useMemo(
        () =>
            list.map(item => (
                <PermissionItem
                    key={item.id}
                    item={item}
                    buttons={buttons}
                    onClick={set}
                />
            )),
        [set, list, buttons]
    )

    return (
        <div className="permission_column">
            <div className="permission_column-header">
                {header}
                <div className="permission_column-header-buttons">
                    {_.compact(
                        otherPrivileges.map(
                            ({ disabled, privilege, changeAllLabel }) =>
                                disabled || (
                                    <Button
                                        key={privilege ?? "N/A"} // since the privilege can be undefined, which isn't a valid key
                                        size="sm"
                                        variant="outline-primary"
                                        onClick={setAll(
                                            visibleWithPrivilege,
                                            privilege
                                        )}
                                    >
                                        <span
                                            className={getPrivilegeClassName(
                                                privilege
                                            )}
                                        />
                                        {changeAllLabel || "Change All"}
                                    </Button>
                                )
                        )
                    )}
                </div>
            </div>
            <div
                className="overflow_container"
                style={{
                    marginBottom: _.isEmpty(rows) ? -10 : 0
                }}
            >
                {loading ? <Loading /> : rows}
            </div>
        </div>
    )
}

const PermissionColumnHeader = ({ privilege }) => (
    <h4>
        <span className={getPrivilegeClassName(privilege)} />
        {C.PERMISSION_BUTTONS[privilege]?.title}
    </h4>
)

export const SourceColumn = props => (
    <PermissionColumn
        {...props}
        header={
            <SearchBar
                value={props.filter}
                onChange={props.onFilterChange}
                cosmeticButton
            />
        }
        visibleWithPrivilege={undefined}
        otherPrivileges={[
            {
                privilege: C.ACCESS_PRIVILEGES.READ,
                changeAllLabel: "All Access",
                disabled: props.disableView
            },
            {
                privilege: C.ACCESS_PRIVILEGES.EDIT,
                changeAllLabel: "All Edit"
            }
        ]}
    />
)

export const ViewColumn = props => (
    <PermissionColumn
        {...props}
        header={<PermissionColumnHeader privilege={C.ACCESS_PRIVILEGES.READ} />}
        visibleWithPrivilege={C.ACCESS_PRIVILEGES.READ}
        otherPrivileges={[
            { privilege: undefined, changeAllLabel: "Remove All" },
            {
                privilege: C.ACCESS_PRIVILEGES.EDIT,
                changeAllLabel: "Upgrade All"
            }
        ]}
    />
)
export const EditColumn = props => (
    <PermissionColumn
        {...props}
        header={<PermissionColumnHeader privilege={C.ACCESS_PRIVILEGES.EDIT} />}
        visibleWithPrivilege={C.ACCESS_PRIVILEGES.EDIT}
        otherPrivileges={[
            { privilege: undefined, changeAllLabel: "Remove All" },
            {
                privilege: C.ACCESS_PRIVILEGES.READ,
                changeAllLabel: "Downgrade All",
                disabled: props.disableView
            }
        ]}
    />
)
