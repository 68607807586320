import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import moment from "moment"
import _ from "lodash"

import PatientHistory from "../../measures/components/MeasureCategory/Measure/History"
import { measuresMapSelector } from "../../measures/selectors"
import { Hoverlay } from "../../core/Hoverlay"
import { MeasureInfoLink } from "../../core/MeasureInfoLink"
import { Coverage, ListCell } from "../../core/CellRenderers"
import { dateFormat, isBlankDate, SERVER_DATE_STRING } from "../../dates"
import { joinLines, joinTruthy } from "../../utils"
import {
    Deceased,
    GracePeriod,
    NonCompliant,
    NotAMember
} from "../../indicators"

import * as CC from "../../campaigns/constants"
import * as C from "../constants"

import OB from "../../../assets/images/ob_icon.png"
import SN from "../../../assets/images/sn_icon.png"

const MaybeVisible = ({ n, Icon }) =>
    parseInt(n, 10) === 0 ? (
        <span />
    ) : (
        <span className="pl-icon">
            {Icon} {n}
        </span>
    )

export const IpCell = ({ cellData }) => (
    <MaybeVisible
        n={cellData}
        Icon={<span className="fa indicator-hospital" />}
    />
)
export const ErCell = ({ cellData }) => (
    <MaybeVisible n={cellData} Icon={<span className="fa indicator-ed" />} />
)
export const ObCell = ({ cellData }) => (
    <MaybeVisible n={cellData} Icon={<img src={OB} alt="ob icon" />} />
)
export const SnCell = ({ cellData }) => (
    <MaybeVisible n={cellData} Icon={<img src={SN} alt="sn icon" />} />
)

export const Incentivized = ({ cellData }) =>
    cellData ? <span>Yes</span> : null

export const PatientName = ({ rowData, cellData }) => {
    const overdue =
        rowData[C.TABLE_KEYS.PATIENT_NEXT_DUE_DATE] <
            moment().format(SERVER_DATE_STRING) ||
        rowData[C.TABLE_KEYS.MEASURE_NEXT_DUE_DATE] <
            moment().format(SERVER_DATE_STRING)

    // TODO make Hoverlay able to take a function for children, to reproduce the behaviour below
    /* return (<Hoverlay tooltip={cellData}>
                {({ ref, ...triggerHandler }) => (
                    <div className="cell-inner-ellipsis" ref={ref}>
                        <Link
                            to={`/poc/${rowData[C.TABLE_KEYS.PATIENT_ID]}`}
                            // className="grid-cell-inner"
                            {...triggerHandler}
                        >
                            {cellData}
                        </Link>
                        {overdue && <NonCompliant />}
                        {rowData[CC.TABLE_KEYS.MEMBERSHIP] === false && ( // explicitly false, not just undefined
                            <NotAMember />
                        )}
                        {rowData[C.TABLE_KEYS.PATIENT_DOD] && (
                            <Deceased />
                        )}
                    </div>
                )}
            </Hoverlay>)*/

    // We do this weird thing so that the tooltip doesn't block any of the indicators, and it's only triggered by hovering on the link (because the indicators often have their own tooltips)
    // noinspection RequiredAttributes
    return (
        <OverlayTrigger
            placement="auto"
            trigger={["hover", "focus"]}
            popperConfig={{
                modifiers: [
                    {
                        name: "preventOverflow",
                        options: { boundary: "window" }
                    }
                ]
            }}
            overlay={<Tooltip>{cellData}</Tooltip>}
        >
            {({ ref, ...triggerHandler }) => (
                <div className="hoverlay-wrapper cell-inner-ellipsis" ref={ref}>
                    <Link
                        {...triggerHandler}
                        to={`/poc/${rowData[C.TABLE_KEYS.PATIENT_ID]}`}
                    >
                        {cellData}
                    </Link>
                    {overdue && <NonCompliant />}
                    {rowData[CC.TABLE_KEYS.MEMBERSHIP] === false && ( // explicitly false, not just undefined
                        <NotAMember />
                    )}
                    {rowData[C.TABLE_KEYS.GRACE_PERIOD] && <GracePeriod />}
                    {rowData[C.TABLE_KEYS.PATIENT_DOD] && <Deceased />}
                </div>
            )}
        </OverlayTrigger>
    )
}

export const IncentivizedRate = ({ cellData, rowData }) => {
    const denom = rowData[C.TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE_DENOMINATOR]
    return <div>{denom === 0 ? "" : `${cellData} / ${denom}`}</div>
}

export const MeasureComplianceRate = ({ rowData }) => {
    const numer = rowData[C.TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE_NUMERATOR]
    const denom = rowData[C.TABLE_KEYS.PATIENT_MEASURE_COMPLIANCE_DENOMINATOR]
    return <div>{denom === 0 ? "" : `${numer} / ${denom}`}</div>
}
export const ConditionComplianceRate = ({ rowData }) => {
    const numer = rowData[C.TABLE_KEYS.HCC_CONDITIONS_MET]?.length || 0
    const denom = rowData[C.TABLE_KEYS.HCC_CONDITIONS]?.length
    return <div>{!denom ? "" : `${numer} / ${denom}`}</div>
}

export const MeasureName = ({ cellData, rowData }) => (
    <Hoverlay tooltip={cellData} wrapperClassName="cell-inner-ellipsis">
        <span>{cellData}</span>
        <MeasureInfoLink link={rowData[C.TABLE_KEYS.MEASURE_LINK]} />
    </Hoverlay>
)

export const MeasureHistoryCell = connect((state, { rowData }) => ({
    patientId: rowData[C.TABLE_KEYS.PATIENT_ID],
    measureId: rowData[C.TABLE_KEYS.MEASURE_ID],
    hasHistory: !isBlankDate(rowData[C.TABLE_KEYS.MEASURE_LAST_SERVICE_DATE]),
    offerHistory: measuresMapSelector(state)?.[rowData[C.TABLE_KEYS.MEASURE_ID]]
        ?.offerHistory
}))(PatientHistory)

export const LastServiceDate = ({ rowData, tableData }) =>
    dateFormat(
        rowData[
            tableData.useMeasureServiceDate
                ? C.TABLE_KEYS.MEASURE_LAST_SERVICE_DATE
                : C.TABLE_KEYS.PATIENT_LAST_SERVICE_DATE
        ]
    )

export const IncentivePayersCell = ({ rowData }) => (
    <Coverage rowData={rowData[C.TABLE_KEYS.INCENTIVE_PAYERS]} />
)

const getPatientAddressRows = rowData => [
    rowData[C.TABLE_KEYS.PATIENT_ADDRESS_1],
    rowData[C.TABLE_KEYS.PATIENT_ADDRESS_2],
    joinTruthy([
        joinTruthy(
            [
                rowData[C.TABLE_KEYS.PATIENT_CITY],
                rowData[C.TABLE_KEYS.PATIENT_STATE]
            ],
            ", "
        ),
        rowData[C.TABLE_KEYS.PATIENT_ZIP]
    ])
]

export const getPatientAddress = ({ rowData }) =>
    joinTruthy(getPatientAddressRows(rowData), ", ")

export const AddressCell = ({ cellData, rowData }) => (
    <Hoverlay tooltip={joinLines(_.filter(getPatientAddressRows(rowData)))}>
        {cellData}
    </Hoverlay>
)

export const ContractNumbers = ({ cellData, ...props }) => (
    <ListCell
        cellData={cellData.map(contract => "[" + contract.join(", ") + "]")}
        className="tooltip-list"
        placement="auto-start"
        overlayProps={{ className: "contract_numbers-tooltip" }}
        tooltip={
            <ul>
                {_.map(cellData, (contract, i) => (
                    <li key={i}>{joinLines(contract)}</li>
                ))}
            </ul>
        }
        {...props}
    />
)

const DefaultCellRenderer = ({ cellData }) => <span>{cellData}</span> // prevents unwanted hovertext
export default DefaultCellRenderer
